import { Component } from '@angular/core';

@Component({
  selector: 'iv-sponsor-navbar',
  templateUrl: './sponsor-navbar.component.html',
  styles: [':host{display: block}']
})
export class SponsorNavbarComponent {

}

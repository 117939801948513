import { ContactStatus } from '@shared/enums/account/contact-status.enum';
import { AccountTypeModel } from '@shared/models/account/account-type.model';
import { ISocialNetwork } from '@shared/models/account/social-network.interface';
import { ILocation } from '@shared/models/location.interface';

export class AccountModel extends AccountTypeModel {

  public location: ILocation;

  public social: ISocialNetwork;

  public date_create: Date;

  public removed: boolean;

  public banned: boolean;

  public url: string;

  public name: string;

  public slug: string;

  public isContact: ContactStatus;

  public get isContacted(): boolean {
    return this.isContact === ContactStatus.Contacted;
  }

  public get isWaitConfirm(): boolean {
    return this.isContact === ContactStatus.WaitConfirm;
  }

  public get isWaitApply(): boolean {
    return this.isContact === ContactStatus.WaitApply;
  }

  public get isPendingContactRequest(): boolean {
    return this.isContact === ContactStatus.WaitConfirm
      || this.isContact === ContactStatus.WaitApply
      || this.isContact === ContactStatus.Rejected
      || this.isContact === ContactStatus.RejectedMe;
  }

  public get noContact(): boolean {
    return this.isContact === ContactStatus.NotInContact
      || this.isContact === ContactStatus.RejectedI;
  }

  public get contactRejected(): boolean {
    return this.isContact === ContactStatus.Rejected
      || this.isContact === ContactStatus.RejectedI
      || this.isContact === ContactStatus.RejectedMe;
  }

  public get contactRejectedMe(): boolean {
    return this.isContact === ContactStatus.RejectedMe;
  }

  public get hasFacebookConnected(): boolean {
    return this.hasSocialConnected('facebook');
  }

  public get hasLinkedinConnected(): boolean {
    return this.hasSocialConnected('linkedin');
  }

  public get publicLink(): string {
    if (this.removed || this.banned) {
      return '';
    }

    let profileType: string = '';

    if (this.isEntrepreneur) {
      profileType = 'entrepreneur';
    } else if (this.isPartner) {
      profileType = 'partner';
    } else if (this.isConnector) {
      profileType = 'connector';
    } else if (this.isSponsor) {
      profileType = 'sponsor';
    } else if (this.isTypeNone) {
      return '';
    }

    return profileType && `/pub/${profileType}/${this.slug}` || '';
  }

  constructor(account: AccountModel) {
    super(account);
    this.date_create = this.date_create ? new Date(this.date_create) : new Date();
  }

  public isActive(): boolean {
    return !this.removed && !this.banned;
  }

  private hasSocialConnected(provider: string): boolean {
    return !!this.social && !!this.social[provider] && !!this.social[provider].connected;
  }
}
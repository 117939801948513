import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateModule } from '@ngx-translate/core';
import { EventLogDirective } from '@shared/./directives/event-log.directive';
import { Angulartics2Module } from 'angulartics2';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { Ng2TelInputModule } from 'ng2-tel-input';
import {
  BsDatepickerModule,
  BsDropdownModule,
  CollapseModule,
  PaginationModule,
  RatingModule,
  TooltipModule,
} from 'ngx-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { NeedPremiumComponent } from '../network/fans/need-premium/need-premium.component';
import { ProfileConfirmPercentComponent } from '../profile/profile-confirm-percent/profile-confirm-percent.component';
import { CheckboxesListComponent } from './components/checkboxes-list/checkboxes-list.component';
import { EmptyListComponent } from './components/empty-list/empty-list.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { InputWithCurrencyComponent } from './components/input-with-currency/input-with-currency.component';
import { InvitesCounterLinkComponent } from './components/invites-counter-link/invites-counter-link.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MyMessagesCounterLinkComponent } from './components/my-messages-counter-link/my-messages-counter-link.component';
import { PaginationButtonsWrapperComponent } from './components/pagination/pagination-buttons-wrapper/pagination-buttons-wrapper.component';
import { ListPaginationButtonsWrapperComponent } from './components/list-pagination/list-pagination-buttons-wrapper/list-pagination-buttons-wrapper.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ListPaginationComponent } from './components/list-pagination/list-pagination.component';
import { PartnershipSliderComponent } from './components/partnership-slider/partnership-slider.component';
import { PaymentInfoComponent } from './components/payment-info/payment-info.component';
import { PaymentMethodComponent } from './components/payment-method/payment-method.component';
import { ProgressStatusComponent } from './components/progress-status/progress-status.component';
import { SearchContactsComponent } from './components/search-contacts/search-contacts.component';
import { SkillInputComponent } from './components/skill-input/skill-input.component';
import { SortContactsComponent } from './components/sort-contacts/sort-contacts.component';
import { UserEmailBlockComponent } from './components/user-email-block/user-email-block.component';
import { UserPhotoBlockComponent } from './components/user-photo-block/user-photo-block.component';
import { UserPhotoUploadButtonComponent } from './components/user-photo-block/user-photo-upload-button/user-photo-upload-button.component';
import { AddthisDirective } from './directives/addthis.directive';
import { CheckboxValuesDirective } from './directives/checkbox-values.directive';
import { ChecklistModelDirective } from './directives/checklist-model.directive';
import { GooglePlacesAutocompleteDirective } from './directives/google-places-autocomplete.directive';
import { InnerHtmlDirective } from './directives/inner-html.directive';
import { TextareaAutosizeDirective } from './directives/textarea-autosize.directive';
import { ModalsModule } from './modals/modals.module';
import { LangNamePipe } from './pipes/lang-name.pipe';
import { LocDatePipe } from './pipes/loc-date.pipe';
import { PaymentMethodPipe } from './pipes/payment-method.pipe';
import { PaymentStatePipe } from './pipes/payment-state.pipe';
import { RouteUrlPipe } from './pipes/route-url.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SplicePipe } from './pipes/splice.pipe';
import { UserTypePipe } from './pipes/user-type.pipe';
import { VideoPipe } from './pipes/video.pipe';
import { ScrollableFormControlDirective } from './directives/scrollable-form-control.directive';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { DefaultNavigatableDirective } from '@shared/directives/navigatable-form-control/default-navigatable.directive';
import { NgSelectNavigatableDirective } from '@shared/directives/navigatable-form-control/ng-select-navigatable.directive';
import { BsDatepickerNavigatableDirective } from '@shared/directives/navigatable-form-control/bs-datepicker-navigatable.directive';
import { GooglePlacesAutocompleteNavigatableDirective } from '@shared/directives/navigatable-form-control/google-places-autocomplete-navigatable.directive';
import { RadioButtonNavigatableDirective } from '@shared/directives/navigatable-form-control/radio-button-navigatable.directive';
import { CroppedPhotoUploaderNavigatableDirective } from '@shared/directives/navigatable-form-control/cropped-photo-uploader-navigatable.directive';
import { SkillInputNavigatableDirective } from '@shared/directives/navigatable-form-control/skill-input-navigatable.directive';
import { CheckboxNavigatableDirective } from '@shared/directives/navigatable-form-control/checkbox-navigatable.directive';
import { InputWithCurrencyNavigatableDirective } from '@shared/directives/navigatable-form-control/input-with-currency-navigatable.directive';
import { DefaultFocusableDirective } from '@shared/directives/focusable-form-control/default-focusable.directive';
import { NgSelectFocusableDirective } from '@shared/directives/focusable-form-control/ng-select-focusable.directive';
import { ChooseFileButtonComponent } from './components/choose-file-button/choose-file-button.component';
import { ChooseFileButtonNavigatableDirective } from '@shared/directives/navigatable-form-control/choose-file-button-navigatable.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    RatingModule,
    PaginationModule,
    ReactiveFormsModule,
    NgSelectModule,
    TooltipModule,
    CollapseModule,
    SwiperModule,
    Ng2TelInputModule,
    RecaptchaModule,
    NgxPageScrollModule,
    ModalsModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    SwiperModule,
    RouterModule,
    ModalsModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    Angulartics2Module,
    TranslateModule,
    CollapseModule,
    BsDropdownModule,
    PaginationModule,
    RatingModule,
    TooltipModule,
    BsDatepickerModule,
    InnerHtmlDirective,
    LocDatePipe,
    RouteUrlPipe,
    CheckboxValuesDirective,
    TextareaAutosizeDirective,
    GooglePlacesAutocompleteDirective,
    ChecklistModelDirective,
    PaymentMethodPipe,
    PaymentMethodComponent,
    NgSelectModule,
    PaymentInfoComponent,
    UserTypePipe,
    UserPhotoBlockComponent,
    UserEmailBlockComponent,
    UserPhotoUploadButtonComponent,
    PaginationButtonsWrapperComponent,
    ListPaginationButtonsWrapperComponent,
    MyMessagesCounterLinkComponent,
    InvitesCounterLinkComponent,
    InputNumberComponent,
    ProfileConfirmPercentComponent,
    EmptyListComponent,
    SortContactsComponent,
    SearchContactsComponent,
    PaymentStatePipe,
    SafeHtmlPipe,
    LangNamePipe,
    VideoPipe,
    Ng2TelInputModule,
    ProgressStatusComponent,
    PartnershipSliderComponent,
    LoadingSpinnerComponent,
    InputWithCurrencyComponent,
    SkillInputComponent,
    PaginationComponent,
    ListPaginationComponent,
    NeedPremiumComponent,
    AddthisDirective,
    CheckboxesListComponent,

    SplicePipe,
    EventLogDirective,

    ScrollableFormControlDirective,

    DefaultNavigatableDirective,
    NgSelectNavigatableDirective,
    BsDatepickerNavigatableDirective,
    GooglePlacesAutocompleteNavigatableDirective,
    RadioButtonNavigatableDirective,
    CroppedPhotoUploaderNavigatableDirective,
    SkillInputNavigatableDirective,
    CheckboxNavigatableDirective,
    InputWithCurrencyNavigatableDirective,
    ChooseFileButtonNavigatableDirective,

    DefaultFocusableDirective,
    NgSelectFocusableDirective,

    ChooseFileButtonComponent,
  ],
  declarations: [
    LocDatePipe,
    InnerHtmlDirective,
    RouteUrlPipe,
    CheckboxValuesDirective,
    GooglePlacesAutocompleteDirective,
    ChecklistModelDirective,
    PaymentMethodPipe,
    PaymentMethodComponent,
    PaymentInfoComponent,
    TextareaAutosizeDirective,
    UserTypePipe,
    UserPhotoBlockComponent,
    UserEmailBlockComponent,
    UserPhotoUploadButtonComponent,
    PaginationButtonsWrapperComponent,
    ListPaginationButtonsWrapperComponent,
    MyMessagesCounterLinkComponent,
    InvitesCounterLinkComponent,
    SplicePipe,
    InputNumberComponent,
    PaymentStatePipe,
    PartnershipSliderComponent,
    ProfileConfirmPercentComponent,
    EmptyListComponent,
    SortContactsComponent,
    SearchContactsComponent,
    SafeHtmlPipe,
    LoadingSpinnerComponent,
    InputWithCurrencyComponent,
    SkillInputComponent,
    PaginationComponent,
    ListPaginationComponent,
    NeedPremiumComponent,
    AddthisDirective,
    LangNamePipe,
    VideoPipe,
    ProgressStatusComponent,
    CheckboxesListComponent,

    EventLogDirective,

    ScrollableFormControlDirective,

    DefaultNavigatableDirective,
    NgSelectNavigatableDirective,
    BsDatepickerNavigatableDirective,
    GooglePlacesAutocompleteNavigatableDirective,
    RadioButtonNavigatableDirective,
    CroppedPhotoUploaderNavigatableDirective,
    SkillInputNavigatableDirective,
    CheckboxNavigatableDirective,
    InputWithCurrencyNavigatableDirective,
    ChooseFileButtonNavigatableDirective,

    DefaultFocusableDirective,
    NgSelectFocusableDirective,
    ChooseFileButtonComponent,
  ],

})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [DatePipe]
    };
  }
}

export enum ContactStatus {
  RemovedOrBanned = -2,
  Current = -1,
  NotInContact = 0,
  Contacted = 1,
  WaitConfirm = 2,
  WaitApply = 3,
  Rejected = 4,
  RejectedMe = 5,
  RejectedI = 6
}
import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderHeightService {

  private elements: ElementRef[] = [];

  public get height(): number {
    return this.elements.reduce((previousValue, currentElement) => {
      let height = 0;

      if (currentElement.nativeElement) {
        height = currentElement.nativeElement.offsetHeight;
      }

      return previousValue + height;
    }, 0);
  }

  public addElement(element: ElementRef): void {
    this.elements.push(element);
  }

  public removeElement(element: ElementRef): void {
    const index = this.elements.findIndex(el => el === element);

    if (!~index) {
      this.elements.splice(index, 1);
    }
  }

}
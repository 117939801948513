import { Directive, ElementRef, forwardRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[ivChecklistModel]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChecklistModelDirective),
      multi: true
    }
  ]
})
export class ChecklistModelDirective implements OnInit, ControlValueAccessor {

  @Input('ivChecklistModel')
  public model: number[] = [];

  @Input('ivChecklistValue')
  public value: number;

  private onChange = (_: any) => {
  };

  private onTouched = () => {
  };

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  @HostListener('change', ['$event'])
  public onHostChange(e: any) {
    this.onChange(e.target.checked ? this.add() : this.remove());
  }

  @HostListener('blur')
  public onHostTouch() {
    this.onTouched();
  }

  ngOnInit(): void {
    this.setChecked();
  }

  writeValue(values: number[]): void {
    this.model = values;
    this.setChecked();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  private setChecked(): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'checked', this.model.some(v => v === this.value));
  }

  private add(): number[] {
    this.model.push(this.value);

    return this.model;
  }

  private remove(): number[] {
    let index = this.model.findIndex(v => v === this.value);

    if (~index) {
      this.model.splice(index, 1);
    }

    return this.model;
  }

}

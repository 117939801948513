import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountType } from '@shared/enums/account/type.enum';
import { Gender } from '@shared/enums/account/user/gender.enum';
import { ICountry, IVerificationCustomer } from '@shared/models/interfaces';

@Component({
  selector: 'iv-customer-form',
  templateUrl: './customer-form.component.html',
  styles: [':host {display: block}']
})
export class CustomerFormComponent implements OnInit {

  @Input()
  public parent: FormGroup;

  @Input()
  public accountType: AccountType.Partner | AccountType.Entrepreneur = AccountType.Entrepreneur;

  @Input()
  public customer: IVerificationCustomer;

  @Input()
  public isEuCountry = false;

  @Output()
  public countryChanged: EventEmitter<ICountry> = new EventEmitter<ICountry>();

  public form: FormGroup;

  public Gender = Gender;

  public AccountType = AccountType;

  private $telInputObject: any;



  constructor(private fb: FormBuilder) {
    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.parent.addControl('customer', this.form);
    this.form.get('type')!.valueChanges.subscribe(type => this.toggleCompanyAndVatValidators(type));
    this.setForm();
  }


  public onCountryChange(country: ICountry): void {
    if (this.form.get('countryIso')!.value !== country.iso) {
      this.$telInputObject.intlTelInput('setCountry', country.iso);
      this.countryChanged.emit(country);
    }
  }

  public telInputObject($telInputObject: any): void {
    this.$telInputObject = $telInputObject;

    const telInputObjectOptions: any = {preferredCountries: []};

    if (this.customer) {
      telInputObjectOptions.onlyCountries = this.customer.allowCountries.map(country => country.iso);
      telInputObjectOptions.initialCountry = this.customer.countryIso;
    }

    this.$telInputObject
      .intlTelInput('destroy')
      .intlTelInput(telInputObjectOptions);

  }


  public onValidNumber(): void {
    this.form.get('phone')!.setErrors(null);
  }

  public onInvalidNumber(): void {
    this.form.get('phone')!.setErrors({invalid: true});
  }

  private createForm(): FormGroup {
    return this.fb.group({
      type: [this.accountType, Validators.required],
      gender: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      countryIso: ['', Validators.required],
      address: ['', Validators.required],
      zip_code: ['', Validators.required],
      city: ['', Validators.required],
      vat: ['', Validators.required],
      phone: ['', Validators.required],
      company: ['', Validators.required]
    });
  }

  private setForm(): void {
    this.form.setValue({
      type: this.accountType,
      gender: this.customer.gender || '',
      first_name: this.customer.first_name,
      last_name: this.customer.last_name,
      countryIso: this.customer.countryIso,
      city: this.customer.city,
      vat: this.customer.vat || '',
      phone: this.customer.phone || '',
      zip_code: this.customer.zip_code || '',
      address: '',
      company: this.customer.company || ''
    });
  }

  private toggleCompanyAndVatValidators(type: AccountType.Partner | AccountType.Entrepreneur): void {
    const companyControl = this.form.get('company')!;
    const vatControl = this.form.get('vat')!;

    if (type === AccountType.Partner) {
      companyControl.setValidators(Validators.required);
      vatControl.setValidators(Validators.required);
    } else {
      companyControl.clearValidators();
      vatControl.clearValidators();
    }

    companyControl.updateValueAndValidity();
    vatControl.updateValueAndValidity();
  }

}

import { Directive, ElementRef, forwardRef } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'input[type=radio][ivNavigatableFormControl][formControlName]',
  providers: [{provide: AbstractNavigatableDirective, useExisting: forwardRef(() => RadioButtonNavigatableDirective)}]
})
export class RadioButtonNavigatableDirective extends AbstractNavigatableDirective {

  private subscription: Subscription;

  constructor(private elementRef: ElementRef,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService) {
    super(formControlName, service, scrollableService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.formControlName.control.valueChanges.subscribe(() => this.next());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public nativeFocus(): void {
    this.elementRef.nativeElement.focus();
  }

}

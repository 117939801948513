import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class SupportService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http, 'support');
  }

  contactUs(data: any): Observable<any> {
    return this.post<any>('contact-us', data);
  }

  reportAbuse(data: any): Observable<any> {
    return this.post<any>('report-abuse', data);
  }

}

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AccountType } from '@shared/enums/account/type.enum';
import { FormHelper } from '@shared/helpers/form.helper';
import { Patterns } from '@shared/helpers/patterns';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { FlashService } from '@shared/services/flash.service';

import { BsModalRef } from 'ngx-bootstrap';
import { BaseModalComponent } from '../../../base.modal.component';

@Component({
  selector: 'iv-user-request',
  templateUrl: './user-request.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRequestComponent extends BaseModalComponent {

  public readonly VALID_PATTERN: RegExp = Patterns.TWO_WORDS_MIN;

  private readonly INVALID_FORM_MESSAGE: string = '_request._modal.error_validate';

  private readonly ACCOUNT_TYPE_TEXT = {
    [AccountType.Entrepreneur]: 'entrepreneur',
    [AccountType.Partner]: 'partner'
  };

  @Input()
  public request: any = null;

  @Input()
  public contact: any;

  @Input()
  public profile: AuthenticatedProfileTypes;

  @Input()
  public isButtonsVisible: boolean = true;

  @Input()
  public cancelOptions: {
    name: string;
    analitycsAction?: string;
  };

  @Input()
  public confirmOptions: {
    name: string;
    analitycsAction?: string;
  };

  @Input()
  public modalType: string = 'form';

  public whyMessage: string = '';

  public howMessage: string = '';

  public loader: boolean = false;

  @ViewChild(NgForm)
  private form: NgForm;

  public get isEntrepreneur(): boolean {
    return this.contact.isEntrepreneur;
  }

  public get accountType(): string {
    return this.ACCOUNT_TYPE_TEXT[this.contact.type] || 'ps';
  }

  public get title(): string {
    return `_request._modal._${this.accountType}._${this.modalType}.title`;
  }

  public get textPrev(): string {
    return `_request._modal._${this.accountType}._${this.modalType}.text_prev`;
  }

  public get contentPreTitle(): string {
    return `_request._modal._${this.accountType}._${this.modalType}.content_pre_title_1`;
  }

  public get contentTitle(): string {
    return `_request._modal._${this.accountType}._${this.modalType}.content_title_1`;
  }

  public get contentTitleData(): any {
    return this.isEntrepreneur ? {
      pre: this.request && this.request.project_title ? ' ' : '',
      project_title: this.request && this.request.project_title || ''
    } : {};
  }

  public get contentText(): string {
    return `_request._modal._${this.accountType}._${this.modalType}.content_text_1`;
  }

  public get textPost(): string {
    return `_request._modal._${this.accountType}._${this.modalType}.text_post`;
  }

  public get message(): string {
    return this.isEntrepreneur ? this.request.text_like_project : this.request.text_expectations;
  }

  public get userName(): string {
    return this.contact.first_name || this.contact.nickname || this.contact.name;
  }

  constructor(protected bsModalRef: BsModalRef,
              private flash: FlashService) {
    super(bsModalRef);
    this.setResult(false);
  }

  public isInvalid(controlName: string): boolean {
    let control = this.form.form.get(controlName);

    return !!control && control.invalid && control.dirty
  }

  public cancel(): void {
    this.setResult({isCancel: true});
    this.close();
  }

  public send(): void {
    if (this.form.valid) {
      this.loader = true;
      this.publishResult({whyMessage: this.whyMessage, howMessage: this.howMessage});
    } else {
      this.flash.danger(this.INVALID_FORM_MESSAGE);
      FormHelper.MarkFormAsDirtyAndTouched(this.form.form);
    }
  }

}

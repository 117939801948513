import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { PartnershipService } from '../../api/partnership.service';

@Component({
  selector: 'iv-partnership-slider',
  templateUrl: './partnership-slider.component.html',
  styles: [':host {display: block}']
})
export class PartnershipSliderComponent implements OnInit {

  @Input()
  public type: number;

  public slides: any[] = [];

  public config: SwiperConfigInterface = {
    slidesPerView: 8,
    breakpoints: {
      480: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 4,
      },
      990: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 6,
      }
    }
  };

  @ViewChild(SwiperDirective)
  private swiper: SwiperDirective;

  private page: number = 1;

  private size: number = 24;

  private loading: boolean = false;

  private endList: boolean = false;

  public get isBeginning(): boolean {
    if (!(this.swiper && this.swiper.swiper())) {
      return true;
    }

    return this.swiper && this.swiper.swiper() && this.swiper.swiper().isBeginning;
  }

  public get isEnd(): boolean {
    if (!(this.swiper && this.swiper.swiper())) {
      return true;
    }

    return this.swiper && this.swiper.swiper() && this.swiper.swiper().isEnd;
  }

  constructor(private partnershipService: PartnershipService,
              private cd: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.update();
  }

  public onIndexChange(index: number): void {
    if (index + 8 >= this.slides.length) {
      this.update(this.page + 1);
      this.cd.markForCheck();
    }
  }

  public next(): void {
    this.swiper.nextSlide();
  }

  public prev(): void {
    this.swiper.prevSlide();
  }

  private update(page = 1): void {
    if (this.loading || this.endList) {
      return;
    }
    this.loading = true;

    const params: any = {
      page: this.page,
      size: this.size
    };
    if (this.type) {
      params.type = this.type;
    }
    this.partnershipService.list(params).subscribe(data => {
      Array.prototype.push.apply(this.slides, data.items);
      this.page = page;
      if (this.slides.length >= data.count) {
        this.endList = true;
      }
      this.loading = false;
      this.swiper.update();
      this.cd.markForCheck();
    });
  }
}

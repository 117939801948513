import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { Subscription } from 'rxjs';
import { GooglePlacesAutocompleteDirective } from '../google-places-autocomplete.directive';
import { AbstractControl, FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';

@Directive({
  selector: '[ivGooglePlacesAutocomplete][ivNavigatableFormControl][formControlName]',
  providers: [{
    provide: AbstractNavigatableDirective,
    useExisting: forwardRef(() => GooglePlacesAutocompleteNavigatableDirective)
  }]
})
export class GooglePlacesAutocompleteNavigatableDirective extends AbstractNavigatableDirective {

  @Input('ivNavigatableFormControlPreventDefaultBehavior')
  private isPreventDefaultBehavior: boolean = true;

  @Input('ivNavigatableFormControlNavigateOnTab')
  private navigateOnTab: boolean = false;

  private tabListener: (() => void) | null = null;

  private subscription: Subscription;

  constructor(private googleAutocomplete: GooglePlacesAutocompleteDirective,
              private elementRef: ElementRef,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService,
              private renderer: Renderer2) {
    super(formControlName, service, scrollableService);
  }

  @HostListener('focus')
  public addTabListener(): void {
    if (this.navigateOnTab) {
      this.tabListener = this.renderer.listen('window', 'keydown.tab', (event: KeyboardEvent) => {
        if (event.srcElement === this.elementRef.nativeElement) {
          event.preventDefault();
          this.service.focusNext(this);
        }
      });
    }
  }

  @HostListener('blur')
  public removeTabListener(): void {
    this.tabListener && this.tabListener();
  }

  @HostListener('keyup.enter')
  public onEnter(): void {
    (this.isRequired() || !this.formControlName.value) && this.next();
  }

  @HostListener('keydown.enter', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    this.isPreventDefaultBehavior && event.preventDefault();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.googleAutocomplete.changeEvent
      .subscribe(() => this.next());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public nativeFocus(): void {
    this.elementRef.nativeElement.focus();
  }

  private isRequired(): boolean {
    const validators = this.getValidators();

    return validators && validators.required;
  }

  private getValidators(): any {
    return this.formControlName
      && this.formControlName.control.validator
      && this.formControlName.control.validator(<AbstractControl>{});
  }

}

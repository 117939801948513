import { Component } from '@angular/core';
import { UsersService } from '@shared/api/users.service';
import { BaseModalComponent } from '@shared/modals/base.modal.component';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

@Component({
  selector: 'iv-restore-password',
  templateUrl: './restore-password.component.html',
})
export class RestorePasswordComponent extends BaseModalComponent {

  public messageError: string = '';

  constructor(private usersService: UsersService,
              protected bsModalRef: BsModalRef) {
    super(bsModalRef);
    this.setResult('close');
  }

  public restore(data$: Observable<{ email: string }>): void {
    data$.pipe(
      concatMap(data => this.usersService.restorePassword(data)),
      take(1)
    ).subscribe(
      () => this.bsModalRef.hide(),
      err => {
        const data = err.error;
        if (Array.isArray(data.text)) {
          this.messageError = '';

          data.text.forEach((val: any) => {
            this.messageError += val + '\n';
          });
        } else {
          this.messageError = data.text;
        }
      });
  }
}

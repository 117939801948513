import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'video'
})
export class VideoPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {
  }

  public transform(text: string): any {
    text = text.trim();

    if (!text)
      return '';
    let src;
    if (text.substr(0, 7) == '<iframe') {
      let regex = new RegExp('<iframe.+?src="([^"]+)".*');
      let results = regex.exec(text);

      if (results === null)
        return '';
      src = results[1];

      let ln_youtube = '//www.youtube.com/embed/';
      let ln_vimeo = '//player.vimeo.com/video/';

      if (src.substr(0, ln_youtube.length) != ln_youtube && src.substr(0, ln_vimeo.length) != ln_vimeo) {
        return '';
      }
    } else {
      let parser = document.createElement('a');
      parser.href = text;
      src = '';

      let getParameterByName = function (querystring: string, name: string) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
          results = regex.exec(querystring);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      };

      let key;
      if (parser.hostname.indexOf('youtube') != -1) {
        src = '//www.youtube.com/embed/' + getParameterByName(parser.search, 'v') + '?rel=0';
      } else if (parser.hostname.indexOf('youtu.be') != -1) {
        key = parser.pathname.lastIndexOf('/') + 1;
        src = '//www.youtube.com/embed/' + parser.pathname.substring(key) + '?rel=0';
      } else if (parser.hostname.indexOf('vimeo') != -1) {
        key = parser.pathname.lastIndexOf('/') + 1;
        src = '//player.vimeo.com/video/' + parser.pathname.substring(key) + '?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;color=cfcaca';
      }

      if (!src)
        return '';
    }
    const content = `<div>
<iframe class="embed-responsive-item" 
        frameborder="0" 
        src="${src}"
        width=""
        height=""
         webkitallowfullscreen 
         mozallowfullscreen 
         allowfullscreen></iframe>
</div>`;

    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}

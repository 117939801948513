import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from '@shared/api/template.service';
import { UsersService } from '@shared/api/users.service';
import { ElementPositionRef } from '@shared/classes/elementPosition';
import { FlashService } from '@shared/services/flash.service';

@Component({
  selector: 'iv-page',
  templateUrl: './page.component.html'
})
export class PageComponent implements OnInit {

  public content: string = '';

  public isCharter: boolean = false;

  public isAbout: boolean = false;

  private isFaq: boolean = false;

  private headerEl: HTMLElement | null = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private flash: FlashService,
              private userService: UsersService,
              private templateSrv: TemplateService,
              private cd: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');

      if (slug === null) {
        return;
      }

      this.isCharter = slug === 'charter';
      this.isAbout = slug === 'about';
      this.isFaq = slug === 'faq';

      this.templateSrv.getByName(slug).subscribe(data => {
        this.content = data.content;
        this.cd.markForCheck();
      });
    });

    this.headerEl = document.getElementById('header');
  }

  public sendAgainEmailConfirmation(): void {
    this.userService.resendEmailConfirm()
      .subscribe(res => {
        this.flash.success(res.text);
        this.router.navigate(['/', 'profile', 'fill']);
      });
  }

  public onContentClick(e: Event): void {
    if (this.isFaq) {
      const srcElement = e.srcElement;

      if (srcElement) {
        const nextElement = srcElement.nextElementSibling;

        if (nextElement && nextElement.classList.contains('answer')) {
          if (nextElement['style'].display === 'none') {
            nextElement['style'].display = 'block';
          } else {
            nextElement['style'].display = 'none';
          }
        }
      }

      return;
    }


    if (!this.isAbout) {
      return;
    }

    const toElementWithId = e.srcElement!['dataset'].href;

    if (!toElementWithId) {
      return;
    }

    const targetElement = document.getElementById(toElementWithId);

    if (!targetElement) {
      return;
    }

    const elementPositionRef = new ElementPositionRef(targetElement);
    const offset = this.headerEl && this.headerEl.offsetHeight || 0;

    elementPositionRef.scrollToSelf(offset);
  }

}

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseApiService } from '../../api/base-api-service';
import { IVerificationCustomer } from '../../models/interfaces';

export abstract class AbstractOrderService extends BaseApiService {

  protected customerObservable$: Observable<IVerificationCustomer>;

  public getCustomer(url: string = '/customer'): Observable<IVerificationCustomer> {
    if (!this.customerObservable$) {
      this.customerObservable$ = this.get<IVerificationCustomer>(url).pipe(shareReplay());
    }

    return this.customerObservable$;
  }

  public payPaypal(data: any, id: string = ''): Observable<any> {
    const url = '/pay-paypal' + (id ? `/${id}` : '');
    return this.post<any>(url, data);
  }

  public payBitpay(data: any, id: string = ''): Observable<any> {
    const url = '/pay-bitpay' + (id ? `/${id}` : '');
    return this.post<any>(url, data);
  }
  //
  // public payPayline(data: any, id: string = ''): Observable<any> {
  //   const url = '/pay-payline' + (id ? `/${id}` : '');
  //   return this.post<any>(url, data);
  // }

  public payMollie(data: any, id: string = ''): Observable<any> {
    const url = '/pay-mollie' + (id ? `/${id}` : '');
    return this.post<any>(url, data);
  }

  public payFree(data: any, id: string = ''): Observable<any> {
    const url = '/pay-free' + (id ? `/${id}` : '');
    return this.post<any>(url, data);
  }
}

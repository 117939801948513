import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { Subscription } from 'rxjs';
import { CountersStore } from '../../api/counters.store';

@Component({
  selector: 'iv-invites-counter-link',
  templateUrl: './invites-counter-link.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvitesCounterLinkComponent implements OnInit, OnDestroy {

  @Input()
  public profile: AuthenticatedProfileTypes;

  public sendContactRequest: number;

  public receiveContactRequest: number;

  public requests: number;

  private subscription: Subscription;

  constructor(private countersStore: CountersStore,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscription = this.countersStore.counters$.subscribe(res => {
      this.receiveContactRequest = res.receiveContactRequest || 0;
      this.sendContactRequest = res.sendContactRequest || 0;
      this.requests = this.receiveContactRequest + this.sendContactRequest;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

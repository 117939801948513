import { ChangeDetectorRef, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CountersStore } from '@shared/api/counters.store';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { ActivatedRoute, Router } from '@angular/router';
import {PaymentCanceledSurveyComponent} from "./payment-canceled-survey/payment-canceled-survey.component";
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

@Component({
  selector: 'iv-profile-navbar',
  templateUrl: './profile-navbar.component.html',
  styles: [':host {display: block}', '.blue-dropdown .btn.dropdown-toggle {padding-top: 7px; padding-bottom: 5px;}']
})
export class ProfileNavbarComponent implements  OnInit{

  @Input()
  public profile: AuthenticatedProfileTypes;

  @Output()
  public logout: EventEmitter<void> = new EventEmitter<void>();

  public openedMenu = 0;

  public infoNotify = {
    notify: 0,
    messages: 0
  };

  public globalConfig = GlobalConfig;

  modalRef: BsModalRef;

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParamMap.get('payment_canceled') !== null) {
      this.openModal();
    }
  }

  public get selfLink(): string {
    if (this.profile.isUser) {
      return '/profile/fill';
    } else if (this.profile.isSponsor) {
      return '/sponsor';
    } else if (this.profile.isConnector) {
      return '/connector';
    } else {
      return '/profile/fill';
    }
  }

  public get selfLinkTitle(): string {
    if (this.profile.isUser) {
      return 'profile';
    } else {
      return 'my_org';
    }
  }

  constructor(private countersStore: CountersStore,
              private cd: ChangeDetectorRef,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private modalService: BsModalService) {

    this.countersStore.counters$.subscribe(res => {
      const objectKeys = Object.keys(res);

      if (objectKeys.some(key => key === 'receiveContactRequest')) {
        this.infoNotify.notify = res.receiveContactRequest || 0;
      }

      if (objectKeys.some(key => key === 'messages')) {
        this.infoNotify.messages = res.messages || 0;
      }

      this.cd.markForCheck();
    });

  }

  public toggleMenu(id: number): void {
    this.openedMenu = this.openedMenu === id ? 0 : id;
  }

  public isCollapsedMenu(id: number): boolean {
    return this.openedMenu !== id;
  }

  public collapseAll(): void {
    this.openedMenu = 0;
  }

  public onStartSearch({route, query}: { route: string, query: string }) {
    this.router.navigate([route], {queryParams: {query}});
  }

  public isActiveLink(url: string, exact = false): boolean {
    return this.router.isActive(url, exact);
  }

  public openModal() {
    this.modalRef = this.modalService.show(PaymentCanceledSurveyComponent,  {
      initialState: {
        title: 'Modal title',
        data: {}
      },
      class: 'modal-sm'
    });
  }

}

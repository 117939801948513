import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { ProfileService } from '@shared/services/profile.service';
import { ProfileStore } from '../profile/profile.store';
import { filter } from 'rxjs/operators';
import { HeaderHeightService } from '@shared/services/header-height.service';
import { Router } from '@angular/router';

@Component({
  selector: 'iv-main-layout',
  templateUrl: './main-layout.component.html',
  styles: [':host {display: block}']
})
export class MainLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('header')
  private headerRef: ElementRef;

  public profile: AuthenticatedProfileTypes;

  constructor(private profileStore: ProfileStore,
              private profileService: ProfileService,
              private headerHeightService: HeaderHeightService,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.profileStore.getAuthenticatedProfile().pipe(
      filter(profile => profile !== null),
    ).subscribe(profile => {
      this.profile = profile;

      if (this.isNavigateToUserDetail()) {
        this.router.navigate(['/', 'profile', 'fill']);
      }

      this.profileService.infoModalsProcess();
    });

  }

  public ngAfterViewInit(): void {
    this.headerHeightService.addElement(this.headerRef);
  }

  public ngOnDestroy(): void {
    this.headerHeightService.removeElement(this.headerRef);
  }

  public onLogout(): void {
    this.profileService.logout();
  }

  public isMobileApp() {
    return GlobalConfig.IS_MOBILE_APP;
  }

  private isNavigateToUserDetail(): boolean {
    return !this.profile.isTypeNone
      && this.profile.isUser
      && !this.profile.isCompleteProfile
      && !this.router.isActive('/profile/fill', false)
      && !this.router.isActive('/page', false)
      && !this.router.isActive('/pub', false)
      && !this.router.isActive('/settings', false);
  }
}

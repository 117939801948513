import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private readonly SCRIPT_DEFAULT_TYPE: string = 'text/javascript';

  private readonly ADD_THIS_SRC: string = '//s7.addthis.com/js/300/addthis_widget.js#pubid=';

  private loadedScripts: Map<string, boolean> = new Map<string, boolean>();

  constructor(@Inject(DOCUMENT)
              private document: Document) {
  }

  public loadAddThis(renderer: Renderer2): Observable<void> {
    const src = this.ADD_THIS_SRC + environment.addThisApiKey;

    if (this.loadedScripts.has(src)) {
      return of();
    }

    return this.loadScript(src, renderer);
  }

  private loadScript(src: string, renderer: Renderer2): Observable<void> {
    return Observable.create((observer: Observer<void>) => {
      const script = this.createScriptElement(src, renderer);

      script.onload = () => {
        this.loadedScripts.set(src, true);
        observer.complete();
      };
      script.onerror = () => observer.error('Script load error');

      renderer.appendChild(this.document.head, script);
    });
  }

  private createScriptElement(src: string, renderer: Renderer2): HTMLScriptElement {
    const script = renderer.createElement('script');
    script.type = this.SCRIPT_DEFAULT_TYPE;
    script.src = src;

    return script;
  }

}
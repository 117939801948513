import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { AuthenticatedUserProfile } from '@shared/models/authenticated-profile/authenticated-user-profile.model';
import { CookieService } from 'ngx-cookie';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { concatMap, filter, map, take } from 'rxjs/operators';
import { AuthModalsService } from '../../auth/auth-modals.service';
import { ProfileStore } from '../../profile/profile.store';
import { EntrepreneurHttpService } from '../api/entrepreneur-http.service';
import { IInfoPremiumResponse, ProfileHttpService } from '../api/profile-http.service';
import { UsersService } from '../api/users.service';
import { AccountType } from '../enums/account/type.enum';
import { ModalsService } from '../modals/modals.service';
import { FlashService } from './flash.service';
import { PremiumService } from '../../premium/premium.service';
import * as script from 'scriptjs';
declare var AppleID: any;

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private readonly appleScriptUrl: string = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

  private ready: Promise<boolean> = new Promise(resolve => {
    if (typeof window !== 'undefined') {
            
            script(this.appleScriptUrl, () => resolve(true));
    } else {
            resolve(false);
        }
    });

  private readonly NEW_PLATFORM_DATE: Date = new Date('2018-03-01T00:00:00.000Z');

  private readonly REFERRED_DISCOUNT_MONTHS: number = 2;

  public get profile(): AuthenticatedProfileTypes {
    return this.profileStore.profile;
  }

  constructor(private authModalsService: AuthModalsService,
              private modalsService: ModalsService,
              private router: Router,
              private flash: FlashService,
              private entrepreneurSrv: EntrepreneurHttpService,
              private deviceDetector: DeviceDetectorService,
              private cookieSrv: CookieService,
              private usersService: UsersService,
              private profileHttpService: ProfileHttpService,
              private profileStore: ProfileStore,
              private premiumService: PremiumService) {
      this.ready.then(isReady => {
        if (isReady) {
            this.init();
        }
    });
  }

  private init(): void {
    AppleID.auth.init({
        clientId: 'com.ideasvoice',
        scope: 'name email',
        redirectURI: `${window.location.origin}/apple/callback`,
        usePopup : false,
        state: 'what ever string to be remembered'
      });
    }

  public infoPremium(): Observable<IInfoPremiumResponse> {
    return this.profileStore.getProfilePremium()
      .pipe(
        concatMap(premium$ => premium$)
      );
  }

  public getPubTypeName(user: { type: number }): string {
    switch (user.type) {
      case AccountType.Entrepreneur:
        return 'partner';
      case AccountType.Partner:
        return 'partner';
      case AccountType.Connector:
        return 'connector';
      case AccountType.Sponsor:
        return 'sponsor';
      default:
        return 'unknown';
    }
  }

  /**************************************MODALS**************************************************/

  public noCompleteProfileModal(): boolean {

    if (!this.profile.isUser || this.profile.isCompleteProfile) {
      return false;
    }

    let title = '';
    let description = '';

    if (this.profile.isEntrepreneur) {
      title = '_no_complete_entrepreneur_modal.title';
      description = '_no_complete_entrepreneur_profile_modal.text';
    } else if (this.profile.isPartner) {
      title = '_no_complete_partner_modal.title';
      description = '_no_complete_partner_profile_modal.text';
    }

    this.modalsService.openConfirmTitle(
      title,
      description,
      {},
      {name: '_later', analitycsAction: 'New Profile Fill click Later'},
      {name: 'complete', analitycsAction: 'New Profile Fill click Continue'}
    )
      .subscribe(res => {
        if (res) {
          this.router.navigate(['/profile/fill']);
        }
      });

    return true;
  }

  public noCompleteProjectWishesModal(): boolean {
    return this.noCompleteWishesModal() || this.noCompleteProjectModal() || this.noCompleteProjectNeedsModal();
  }

  public noCompleteModal(): boolean {
    if (!this.profile.isUser) {
      return false;
    }
    if (this.noCompleteProfileModal()) {
      return true;
    }
    return this.noCompleteProjectWishesModal();
  }

  public noCompleteWishesModal(): boolean {
    if (!this.profile.isPartner || this.profile.isCompleteWishes) {
      return false;
    }

    this.modalsService.openConfirmTitle(
      '_no_complete_partner_modal.title',
      '_no_complete_partner_wishes_modal.text',
      {},
      {name: '_later', analitycsAction: 'New Profile Fill click Later'},
      {name: 'complete', analitycsAction: 'New Profile Fill click Continue'}
    )
      .subscribe(res => {
        if (res) {
          this.router.navigate(['/wishes']);
        }
      });
    return true;
  }

  public noCompleteProjectModal(): boolean {
    if (!this.profile.isEntrepreneur || this.profile.isCompleteProject) {
      return false;
    }
    this.modalsService.openConfirmTitle(
      '_no_complete_entrepreneur_modal.title',
      '_no_complete_entrepreneur_project_modal.text',
      {},
      {name: '_later', analitycsAction: 'New Profile Fill click Later'},
      {name: 'complete', analitycsAction: 'New Profile Fill click Continue'}
    )
      .subscribe(res => {
        if (res) {
          this.router.navigate(['/projects/create']);
        }
      });
    return true;
  }

  public noCompleteProjectNeedsModal(): boolean {
    if (!this.profile.isEntrepreneur || this.profile.isCompleteProjectNeed) {
      return false;
    }

    this.modalsService.openConfirmTitle(
      '_no_complete_entrepreneur_modal.title',
      '_no_complete_entrepreneur_project_need_modal.text',
      {},
      {name: '_later', analitycsAction: 'New Profile Fill click Later'},
      {name: 'complete', analitycsAction: 'New Profile Fill click Continue'}
    )
      .subscribe(res => {
        if (res) {
          this.router.navigate(['/projects/0/needs/create']);
        }
      });

    return true;
  }

  public noCompleteProjectNeedsRequestModal(): boolean {
    if (!this.profile.isEntrepreneur || this.profile.isCompleteProjectNeed) {
      return false;
    }

    const title = '_no_complete_entrepreneur_modal.title';
    const description = '_no_complete_entrepreneur_project_need_request_modal.text';

    this.modalsService.openConfirmTitle(
      title,
      description,
      {},
      {name: '_later', analitycsAction: 'New Profile Fill click Later'},
      {name: 'complete', analitycsAction: 'New Profile Fill click Continue'}
    )
      .subscribe(res => {
        if (res) {
          this.router.navigate(['/projects/0/needs/create']);
        }
      });

    return true;
  }

  public needPremium(title?: string, desc?: string): Observable<any> {
    if (!title || !desc) {
      return this.modalsService.openAlert('_need_premium');
    }

    return this.modalsService.openPremiumInfo(title, desc);
  }


  /************************************AUTH***************************************************/

  public authFacebook(ownerType: number = 0): Promise<any> {
    return this.socialAuth('facebook', 'Auth from Facebook', ownerType);
  }

  public authLinkedin(ownerType: number = 0): Promise<any> {
    return this.socialAuth('linkedin', 'Auth from LinkedIn', ownerType);
  }

  public authApple(ownerType: number = 0) {
    console.log(ownerType);
    AppleID.auth.signIn();
    // return this.socialAuth('apple', 'Auth from Apple', ownerType);
  }

  public socialAuth(provider: string, title = '', ownerType: number = 0): Promise<any> {
    let top = 0, left = 0;
    return (new Promise((resolve, reject) => {
      window['authDone'] = (result: any, wnd: any) => {
        result = JSON.parse(result);
        wnd.close();
        if (result.code !== 'ok') {
          return reject(result);
        }
        resolve(result);
      };

      top = Math.round((screen.height - 760) / 2);
      left = Math.round((screen.width - 640) / 2);

      const popupWin = window.open(
        '/social-auth/' + provider + '?ownerType=' + ownerType,
        title,
        `location=no, toolbar=no, status=yes, width=640, height=760, left=${left}, top=${top}`
      );

      popupWin && popupWin.focus();
    }));
  }

  public async registration(ownerType: number = 1, regType: number = 0, additionalParams: any = {}): Promise<boolean> {
    let regResult;

    try {
      ownerType = await this.authModalsService.openChooseTypeModal(ownerType, regType, additionalParams).toPromise();
      regResult = await this.authModalsService.openRegistrationModal(ownerType, regType, additionalParams).toPromise();
    } catch (err) {
      return false;
    }

    if (!regResult) {
      return false;
    }

    if (regResult.useSocial) {
      try {
        switch (regResult.useSocial) {
          case 'facebook':
            regResult = await this.authFacebook(ownerType);
            break;
          case 'linkedin':
            regResult = await this.authLinkedin(ownerType);
            break;
          default:
            return false;
        }
      } catch (err) {
        return false;
      }
    }

    if (regType === 1 && additionalParams.variant_id) {
      try {
        const variantId = Number.parseInt(additionalParams.variant_id + '');
        const premiumInfo = await this.infoPremium().pipe(take(1)).toPromise();
        await this.authModalsService.openWirateModal(variantId, premiumInfo).toPromise();
      } catch (err) {
        console.error(err);
      }
    }
    if (regResult.redirect_url && window.location.href.indexOf(regResult.redirect_url) === -1) {
      window.location.href = regResult.redirect_url;
      return true;
    }
    window.location.reload();
    return true;
  }

  public login(data: { login: string, pass: string, remember: boolean }): Observable<any> {
    return this.usersService.login(data);
  }

  public logout(): void {
    this.usersService.logout().subscribe(data => {
      if (data.redirect_url && window.location.href.indexOf(data.redirect_url) === -1) {
        window.location.href = data.redirect_url;
      } else {
        window.location.reload();
      }
    });
  }

  public authorized(redirect = true): Observable<boolean> {
    return this.profileStore.authenticatedProfile$.pipe(
      filter(profile => !!profile),
      take(1),
      map(profile => {
        if (!(profile && profile.isLogin)) {
          if (redirect) this.router.navigate(['/home']);
        }

        return profile && profile.isLogin;
      })
    );
  }

  public infoModalsProcess(): void {
    if (this.profile.isLogin && this.profile.isUser) {
      this.downloadAppModal();
      this.emailConfirmModal();
      this.closedAllNeedsModal();
      this.cancellationWirateModal();
      this.newRulesForProfilePageModal();
      this.finishUpProfileModal();
      this.openRedeemDiscountModal();
    }
  }

  public openRedeemDiscountModal(): void {

    const profile = this.profileStore.profile;

    const twoMothAgo = new Date();
    twoMothAgo.setMonth(twoMothAgo.getMonth() - this.REFERRED_DISCOUNT_MONTHS);

    if (
      profile.registered
      && profile.isReferred
      && twoMothAgo.getTime() < profile.registered.getTime()
      && profile.isComplete
    ) {
      this.premiumService.getReferralCoupon()
        .subscribe(coupon => this.modalsService.openRedeemDistountAlertTitle(coupon));
    }
  }

  public finishUpProfileModal(): void {
    const profile: AuthenticatedUserProfile = <AuthenticatedUserProfile>this.profile;

    if (
      profile.isEmailConfirmed
      && !profile.isCompleteProfile
      && profile.registered
      && profile.registered.getTime() >= this.NEW_PLATFORM_DATE.getTime()
    ) {
      const title = 'finish_up.title';
      const description = 'finish_up.description';

      this.modalsService.openAlertTitle(title,
        description,
        {buttonText: 'ok'},
        {'class': 'modal-md alert-big-title modal-centered-text modal-centered-button'}
      );
    }
  }

  public closedAllNeedsModal(): void {
    const profile = this.profile;
    if (!(profile.isEntrepreneur && profile.isCompleteProfile && profile.isActive)) return;

    this.entrepreneurSrv.checkClosedAllNeeds()
      .pipe(
        concatMap(res => {
          if (!res.closed) return of(null);
          return this.modalsService.openCloseAllNeeds(res.project_id, res.need_id);
        })
      )
      .subscribe(() => {
      });
  }

  public emailConfirmModal(): void {
    const profile = this.profile;
    if ((<AuthenticatedUserProfile>profile).shouldWiratePremium) return;
    if (profile.isEmailConfirmed) return;

    const exist = this.cookieSrv.get('emc');

    if (exist) return;
    const now = new Date();
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 5);
    this.cookieSrv.put('emc', now.getTime() + '', {expires});

    this.modalsService.openResendEmailDialog();
  }

  public cancellationWirateModal(): void {
    const profile = this.profile;
    if (!(<AuthenticatedUserProfile>profile).shouldWiratePremium) return;

    const exist = this.cookieSrv.get('wirc');

    if (exist) return;
    const now = new Date();
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 5);
    this.cookieSrv.put('wirc', now.getTime() + '', {expires});
    this.modalsService.openCancellationWirate()
      .pipe(
        concatMap(res => {
          if (!res) {
            return this.profileHttpService.useBasicServices()
              .pipe(concatMap(() => this.usersService.resendEmailConfirm()));
          }
          return this.infoPremium()
            .pipe(
              take(1),
              concatMap(premiumInfo => this.authModalsService.openWirateModal(<number>res, premiumInfo))
            );
        })
      )
      .subscribe(res => {
        if (res) this.flash.success(res.text);
      });
  }

  public newRulesForProfilePageModal(): void {
    const profile: AuthenticatedUserProfile = <AuthenticatedUserProfile>this.profile;

    if (profile.registered && profile.registered.getTime() >= this.NEW_PLATFORM_DATE.getTime()) return;
    if (!profile.isCompleteProfile && profile.isEmailConfirmed) {
      this.modalsService.openConfirmTitle(
        '_new_profile_no_complete_modal.title',
        '_new_profile_no_complete_modal.text',
        {'class': 'modal-sm alert-big-title'},
        {
          name: '_later',
          analitycsAction: 'New Profile Fill click Later',
        },
        {
          name: 'complete',
          analitycsAction: 'New Profile Fill click Continue',
        }
      ).subscribe(res => {
        if (res) this.router.navigateByUrl('/profile/fill');
      });
      return;
    }
    if (profile.isOldComplete) return;
    this.modalsService.openAlertTitle('_new_profile_modal.title', '_new_profile_modal.text');

  }

  public downloadAppModal(): void {
    if (GlobalConfig.IS_MOBILE_APP) return;
    const profile = this.profile;
    if ((<AuthenticatedUserProfile>profile).shouldWiratePremium) return;
    if (!this.deviceDetector.isMobile()) return;
    const exist = this.cookieSrv.get('appdw');

    if (exist) return;
    const now = new Date();
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    this.cookieSrv.put('appdw', now.getTime() + '', {expires});
    this.modalsService.openDownloadApp();
  }
}




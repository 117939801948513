import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormHelper } from '@shared/helpers/form.helper';
import { FlashService } from '@shared/services/flash.service';


@Component({
  selector: 'iv-comment-form',
  templateUrl: './comment-form.component.html',
  styles: [':host {display: block}']
})
export class CommentFormComponent implements OnInit, OnChanges {

  public readonly MAX_LENGTH: number = 500;

  private readonly ERROR_TEXT: string = 'comment_text_required';

  @Input()
  public saving: boolean = false;

  @Input()
  public title: string = 'write_comment';

  @Input()
  public required: boolean = true;

  @Output()
  public submit: EventEmitter<string> = new EventEmitter<string>();

  public control: FormControl;

  constructor(private flash: FlashService) {
  }

  ngOnInit(): void {
    let validators = [Validators.maxLength(this.MAX_LENGTH)];

    if (this.required) {
      validators.push(Validators.required);
    }

    this.control = new FormControl('', validators);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.control && changes.saving && changes.saving.currentValue === false) {
      this.control.setValue('');
      FormHelper.MarkFormAsPristineAndUntouched(this.control);
    }
  }

  public onSubmit(): void {
    if (this.control.valid) {
      this.submit.emit(this.control.value);
    } else {
      this.flash.warning(this.ERROR_TEXT);
      FormHelper.MarkFormAsDirtyAndTouched(this.control);
    }
  }

}

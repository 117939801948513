import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iv-sort-contacts',
  templateUrl: './sort-contacts.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortContactsComponent {

  public OPTIONS: { key: string, val: string }[] = [
    {val: 'recently', key: 'sort_recently'},
    {val: 'first_name', key: 'sort_first_name'},
    {val: 'last_name', key: 'sort_last_name'},
    {val: 'highest_reviews', key: 'sort_highest_reviews'},
    {val: 'lowest_reviews', key: 'sort_lowest_reviews'},
  ];

  @Input()
  public selected: string = this.OPTIONS[0].val;

  @Output()
  public selectedChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public set options(options: { key: string, val: string }[]) {
    this.OPTIONS = options;
    this.selected = this.OPTIONS[0].val;
  }

  public onChange(value: string): void {
    if (this.selected !== value) {
      this.selected = value;
      this.selectedChange.emit(this.selected);
    }
  }

}

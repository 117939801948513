import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICropImageData } from '../directives/cropped-photo-uploader/crop-image-data.interface';

@Injectable({
  providedIn: 'root'
})
export class FileUploaderService {

  constructor(private http: HttpClient) {
  }

  public upload<T>(data: ICropImageData, url: string): Observable<T> {
    const form = new FormData();

    form.append('x', data.x.toString());
    form.append('y', data.y.toString());
    form.append('width', data.width.toString());
    form.append('height', data.height.toString());
    form.append('orientation', data.orientation.toString());
    form.append('image', data.image, data.image.name);

    return this.http.post<T>(url, form);
  }
}
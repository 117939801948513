import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'iv-search',
  templateUrl: './search.component.html',
  styles: [':host {display: block}']
})
export class SearchComponent implements OnInit, OnDestroy {

  public readonly MENU = [
    {key: '/search/partners', value: 'search_future_partner', filters: ''},
    {key: '/search/entrepreneurs', value: 'entrepreneur', filters: ''},
    {key: '/search/projects', value: 'search_project', filters: '{"only_needs":1}'},
    {key: '/search/groups', value: 'search_group', filters: ''}
  ];

  @Input()
  public profile: AuthenticatedProfileTypes;

  @Output()
  public search: EventEmitter<{ route: string, query: string }> = new EventEmitter();

  public searchText: string = '';

  public selectedType: string = '';

  private subscription: Subscription;

  constructor(private router: Router) {
  }

  public ngOnInit(): void {
    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter((event: NavigationEnd) => this.MENU.some(menuItem => event.url.includes(menuItem.key))),
      map((event: NavigationEnd) => this.MENU.find(menuItem => event.url.includes(menuItem.key)))
    ).subscribe(menuItem => menuItem && this.setTypeSearch(menuItem.key));

    if (this.profile.isPartner) {
      this.MENU.unshift({key: '/search/recommended-projects', value: 'search_project_recom', filters: ''});
    }

    if (this.profile.isEntrepreneur) {
      this.MENU.unshift({key: '/search/recommended-partners', value: 'top_partners', filters: ''});
    }

    if (this.MENU.some(menuItem => this.router.isActive(menuItem.key, false))) {
      let activeSearchMenuItem = this.MENU.find(menuItem => this.router.isActive(menuItem.key, false));
      activeSearchMenuItem && this.setTypeSearch(activeSearchMenuItem.key);
    } else {
      if (this.profile.isPartner || this.profile.isConnector) {
        this.selectedType = this.MENU.find(menuItem => menuItem.key === '/search/projects')!.key;
      } else if (this.profile.isEntrepreneur) {
        this.selectedType = this.MENU.find(menuItem => menuItem.key === '/search/partners')!.key;
      }
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public startSearch(): void {
    // this.search.emit({route: this.selectedType, query: this.searchText});
    // @ts-ignore
    this.router.navigate([this.selectedType], { queryParams: { query: this.searchText, filters: '{"only_needs":1}' } });
  }

  public setTypeSearch(type: string): void {
    this.selectedType = type;
  }

  public isPremiumKey(key: string): boolean {
    return key === '/search/recommended-projects' || key === '/search/recommended-partners';
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITemplate } from '../models/template.interface';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class TemplateService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http, '/template');
  }

  getByName(name: string): Observable<ITemplate> {
    return this.get<ITemplate>(`/${name}`);
  }

}

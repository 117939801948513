import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { ProfileService } from '@shared/services/profile.service';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { ProfileStore } from '../../profile/profile.store';

@Injectable({
  providedIn: 'root'
})
export class ProfileCompleteGuard implements CanLoad {

  constructor(private profileStore: ProfileStore,
              private profileService: ProfileService) {
  }

  public canLoad(): Observable<boolean> {
    return this.profileStore.authenticatedProfile$.pipe(
      filter(profile => !!profile),
      take(1),
      map(() => !this.profileService.noCompleteModal())
    );
  }

}
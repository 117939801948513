import { Directive, forwardRef } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';
import { SkillInputComponent } from '@shared/components/skill-input/skill-input.component';

@Directive({
  selector: 'iv-skill-input[ivNavigatableFormControl][formControlName]',
  providers: [{provide: AbstractNavigatableDirective, useExisting: forwardRef(() => SkillInputNavigatableDirective)}]
})
export class SkillInputNavigatableDirective extends AbstractNavigatableDirective {

  constructor(private input: SkillInputComponent,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService) {
    super(formControlName, service, scrollableService);
  }

  public nativeFocus(): void {
    this.input.focus();
  }

}
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  public transform(type: number): string {
    return type ? this.translate.instant(`_user_type.${type}`) : '';
  }

}
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  googleMapsApiKey: 'AIzaSyACnhBgjnf9eqK7w3iOzQ6wgMz93Rh3Sjc',
  tinyMCEApiKey: 'zbkqc5lkdwla0w6656njd7ir7j0zvc6m9wrqa06ew65zxied',
  addThisApiKey: 'ra-5b46042df4449b97'
};

import { NgModule } from '@angular/core';
import { CommonFormsModule } from '../../common-forms/common-forms.module';
import { ChooseTypeModalComponent } from './choose-type-modal/choose-type-modal.component';
import { RegistrationModalComponent } from './registration-modal/registration-modal.component';
import { RegistrationFormComponent } from './registration-modal/registration-form/registration-form.component';
import { WirateModalComponent } from './wirate-modal/wirate-modal.component';


@NgModule({
  imports: [
    CommonFormsModule
  ],
  declarations: [
    RegistrationFormComponent,
    RegistrationModalComponent,
    ChooseTypeModalComponent,
    WirateModalComponent
  ],
  exports: [
    RegistrationModalComponent,
    ChooseTypeModalComponent,
    WirateModalComponent
  ],
  entryComponents: [
    WirateModalComponent,
    ChooseTypeModalComponent,
    RegistrationModalComponent
  ],
})
export class RegistrationModule {
}

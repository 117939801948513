import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { FormHelper } from '../../helpers/form.helper';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-confirm-reason',
  templateUrl: './confirm-reason.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmReasonComponent extends BaseModalComponent {

  @Input()
  public variants: { value: number, title: string, isTextarea?: boolean }[] = [];

  @Input()
  public title: string = '';

  @Input()
  public description: string = '';

  @Input()
  public deleteText: string = '';

  @Input()
  public analitycsAction: string = '';

  public form: FormGroup;

  public loading: boolean = false;

  public get isTextareaVisible(): boolean {
    let reasonValue = this.form.get('reason')!.value;

    return !!this.variants.find(variant => variant.value === reasonValue && !!variant.isTextarea);
  }

  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);

    this.form = new FormGroup({
      reason: new FormControl('', [Validators.required]),
      reasonDescription: new FormControl('')
    })
  }

  public confirm(): void {
    if (this.form.valid) {
      this.publishResult(this.form.value);
      this.loading = true;
    } else {
      FormHelper.MarkFormAsPristineAndUntouched(this.form);
    }
  }

}

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CountersStore } from '../../api/counters.store';

@Component({
  selector: 'iv-my-messages-counter-link',
  templateUrl: './my-messages-counter-link.component.html'
})
export class MyMessagesCounterLinkComponent implements OnInit, OnDestroy {

  public messages: number | null = null;

  private subscription: Subscription;

  constructor(private countersStore: CountersStore,
              private cd: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.subscription = this.countersStore.counters$.subscribe(res => {
      this.messages = res.messages || null;
      this.cd.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

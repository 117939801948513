import { Directive, ElementRef, forwardRef, HostListener } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';

@Directive({
  selector: 'input[type="checkbox"][ivNavigatableFormControl][formControlName]',
  providers: [{provide: AbstractNavigatableDirective, useExisting: forwardRef(() => CheckboxNavigatableDirective)}]
})
export class CheckboxNavigatableDirective extends AbstractNavigatableDirective {

  constructor(private elementRef: ElementRef<HTMLInputElement>,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService) {
    super(formControlName, service, scrollableService);
  }

  @HostListener('change')
  public onEnter(): void {
    this.next();
  }

  public nativeFocus(): void {
    this.elementRef.nativeElement.focus();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IClosedNeedsResponse } from '@shared/models/api-response/closed-needs-response.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IProject } from '../../projects/project.interface';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class EntrepreneurHttpService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http, '/entrepreneur');
  }

  public comparePartner(partnerId: number): Observable<boolean> {
    return this.get<{ compared: boolean }>(`/compare-partner/${partnerId}`)
      .pipe(map(res => res.compared));
  }

  public checkClosedAllNeeds(): Observable<IClosedNeedsResponse> {
    return this.get<IClosedNeedsResponse>('check-closed-all-needs');
  }

  public getProjects(): Observable<IProject[]> {
    return this.get<IProject[]>('projects');
  }
}


// listVotes: {
//   url: urlMigrate + ':id/votes/:page',
//     method: 'GET',
//     params: {id: '@id', page: '@page'},
//   isArray: false
// },
// countersComment: {
//   url: urlMigrate + ':action',
//     method: 'GET',
//     params: {action: 'counters_comments'},
//   isArray: false,
//     ignoreLoadingBar: true
// },
// comparePartner: {url: url + "/compare-partner/:id", method:'GET', params: {id:'@id'}, isArray: false},

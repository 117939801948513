import { FormArray, FormControl, FormControlName, FormGroup } from '@angular/forms';
import { Input, OnDestroy, OnInit } from '@angular/core';
import { NavigatableFormControlService } from '@shared/services/navigatable-form-control.service';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';

export abstract class AbstractNavigatableDirective implements OnInit, OnDestroy {

  public abstract nativeFocus(): void;

  @Input('ivNavigatableFormControlNextControlName')
  public nextControlName: string = '';

  @Input('ivNavigatableFormControlNavigateOnDirty')
  public isNavigateOnDirty: boolean = false;

  @Input('ivNavigatableFormControlScroll')
  public isScrollOnNavigate: boolean = false;

  @Input('ivNavigatableFormControlDisableNext')
  public isNextDisabled: boolean = false;

  @Input('ivNavigatableFormControl')
  private _name: string = '';

  public get control(): FormControl | null {
    return this.formControlName && this.formControlName.control;
  }

  public get parent(): FormGroup | FormArray | null {
    return this.control && this.control.parent;
  }

  public get name(): string {
    return this.formControlName && this.formControlName.path.join('.') || this._name;
  }

  protected constructor(protected formControlName: FormControlName | null = null,
                        protected service: NavigatableFormControlService,
                        protected scrollableService: ScrollableFormControlService) {
  }

  public ngOnInit(): void {
    this.service.add(this);
  }

  public ngOnDestroy(): void {
    this.service.remove(this);
  }

  public focus(): void {
    if (this.control && this.isScrollOnNavigate) {
      this.scrollableService.scrollToControl(this.control);
    } else {
      this.nativeFocus();
    }
  }

  public next(): void {
    if (this.isNextDisabled || (this.control && this.control.invalid)) {
      return;
    }

    this.service.focusNext(this);
  }

}
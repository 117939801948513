import { Component, Input } from '@angular/core';
import { IRegistration, UsersService } from '@shared/api/users.service';
import { BaseModalComponent } from '@shared/modals/base.modal.component';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';

@Component({
  selector: 'iv-registration-modal',
  templateUrl: './registration-modal.component.html'
})
export class RegistrationModalComponent extends BaseModalComponent {

  @Input()
  public ownerType: number;

  @Input()
  public regType: number;

  constructor(protected bsModalRef: BsModalRef,
              private userService: UsersService) {
    super(bsModalRef);
  }

  public onRegistration(data$: Observable<IRegistration>): void {
    data$.pipe(
      concatMap(data => this.userService.registration(data)),
      take(1),
    ).subscribe(res => this.cancel(res));
  }

  public onSocialRegistration(socialNetworkName: string): void {
    this.cancel({useSocial: socialNetworkName});
  }

  public cancel(data: any): void {
    this.setResult(data);
    this.close()
  }
}

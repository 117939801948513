import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'iv-payment-canceled',
  templateUrl: './payment-canceled.component.html',
  styleUrls: ['./payment-canceled.component.scss']
})
export class PaymentCanceledComponent implements OnInit {

  constructor(    public modalRef: BsModalRef) { }

  ngOnInit() {
  }

}

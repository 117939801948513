import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPaginationOptions } from './list-pagination-options.interface';

@Component({
  selector: 'iv-list-pagination',
  templateUrl: './list-pagination.component.html',
  styles: [':host {display: block}']
})
export class ListPaginationComponent {

  private readonly DEFAULT_ITEMS_PER_PAGE: number = 20;


  @Input()
  public paginationOptions: IPaginationOptions = {
    page: '1'
  };

  @Input()
  public page: string;

  @Input()
  public total: number;

  @Input()
  public noSearchResultText: string;

  @Input()
  public emptyMessageData: any;

  @Input()
  public title: string;

  @Input()
  public sortOptions: { val: string, key: string }[] | false = false;

  @Input()
  public searchPlaceholder: string = '';

  @Input()
  public isPaginationButtonsVisible: boolean = true;

  @Input()
  public searchInProgress: boolean = false;

  @Output()
  public paginationOptionsChange: EventEmitter<IPaginationOptions> = new EventEmitter();

  @Output()
  public searchChange: EventEmitter<IPaginationOptions> = new EventEmitter();

  @Input()
  public itemsPerPage: number = this.DEFAULT_ITEMS_PER_PAGE;

  @Input()
  public isContentClass: boolean = true;

  public get isEmpty(): boolean {
    return !this.searchInProgress && this.total === 0 && this.search === '';
  }

/*  public get page(): number {
    return +this.paginationOptions.page;
  }*/

  public get search(): string {
    return this.paginationOptions.search || '';
  }

  public onSortChange(sortBy: string): void {
    this.paginationOptions.sortBy = sortBy;
    this.paginationOptionsChange.emit(this.paginationOptions);
  }

  public onQueryChange(search: string): void {
    this.paginationOptions.search = search;
    this.searchChange.emit(this.paginationOptions);
  }

  public onPageChange(page: number): void {
    this.paginationOptions.page = page.toString();
    this.paginationOptionsChange.emit(this.paginationOptions);
  }
}



import { Component, OnInit } from '@angular/core';
import { SupportService } from '@shared/api/support.service';
import { FlashService } from '@shared/services/flash.service';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { ProfileStore } from '../profile/profile.store';

@Component({
  selector: 'iv-report-abuse-page',
  templateUrl: './report-abuse-page.component.html',
  styles: [':host {display: block}']
})
export class ReportAbusePageComponent implements OnInit {

  public readonly FORM_TITLE: string = 'report_abuse';

  public isLogin: boolean = false;

  public reCaptchaKey = GlobalConfig.RE_CAPCTCHA_KEY;

  constructor(private supportSrv: SupportService,
              private profileStore: ProfileStore,
              private flash: FlashService) {
  }

  ngOnInit(): void {
    this.profileStore.authenticatedProfile$.pipe(take(1))
      .subscribe(profile => this.isLogin = profile.isLogin);
  }

  public onSubmit(data$: Observable<any>): void {
    data$.pipe(
      concatMap(data => this.supportSrv.reportAbuse(data)),
      take(1),
    ).subscribe(res => this.flash.success(res.text));
  }

}

import { Directive, ElementRef, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl, FormControlName } from '@angular/forms';
import { ScrollableFormControlService } from '../services/scrollable-form-control.service';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';

@Directive({
  selector: '[ivScrollableFormControl]',
})
export class ScrollableFormControlDirective implements OnInit, OnDestroy {

  public get control(): FormControl {
    return <FormControl>this.formControlName.control;
  }

  public get nativeElement(): any {
    return this.elementRef.nativeElement;
  }

  constructor(private formControlName: FormControlName,
              private elementRef: ElementRef,
              private service: ScrollableFormControlService,
              @Optional()
              private navigatable: AbstractNavigatableDirective) {
  }

  public ngOnInit(): void {
    this.service.add(this);
  }

  public ngOnDestroy(): void {
    this.service.remove(this);
  }

  public scroll(): void {
    this.service.scrollToControl(this.control);
  }

  public focus(): void {
    if (this.navigatable) {
      this.navigatable.nativeFocus();
    }
  }

}
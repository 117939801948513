import { Component, OnInit } from '@angular/core';
import { TemplateService } from '@shared/api/template.service';

@Component({
  selector: 'iv-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  content = '';

  constructor(private template: TemplateService) {
  }

  ngOnInit() {
    this.template.getByName('footer').subscribe(template => this.content = template.content);
  }

}

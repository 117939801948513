import { Input, Renderer2 } from '@angular/core';

export abstract class AbstractFocusableDirective {

  @Input('ivFocusableLabel')
  private label?: HTMLInputElement;

  @Input('ivFocusableClass')
  private focusedClass: string = 'focused';

  protected constructor(protected renderer: Renderer2) {
  }

  protected addClass(): void {
    this.label && this.renderer.addClass(this.label, this.focusedClass);
  }

  protected removeClass(): void {
    this.label && this.renderer.removeClass(this.label, this.focusedClass);
  }

}
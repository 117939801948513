import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ModalsService } from '@shared/modals/modals.service';
import { ICropImageData } from '@shared/directives/cropped-photo-uploader/crop-image-data.interface';

@Component({
  selector: 'iv-choose-file-button',
  templateUrl: './choose-file-button.component.html'
})
export class ChooseFileButtonComponent {

  @Input()
  public title: string;

  @Input()
  public description: string;

  @Input()
  public helpMessage: string;

  @Input()
  public imageScr: string;

  @Input()
  public width: number = 300;

  @Input()
  public height: number = 300;

  @Input()
  public aspectRatio: number = 1;

  @Input()
  public modalHostClass: string = '';

  @Input()
  public buttonTitle: string = '_upload_photo';

  @Output()
  public added: EventEmitter<ICropImageData> = new EventEmitter();

  @Output()
  public closed: EventEmitter<void> = new EventEmitter();

  @ViewChild('uploadPhotoButton')
  private buttonRef: ElementRef<HTMLButtonElement>;

  constructor(private modalService: ModalsService) {
  }

  public focus(): void {
    this.buttonRef.nativeElement.focus();
  }

  public openChooseFileModal(): void {
    this.modalService.openChooseFileModal(
      this.title,
      this.description,
      this.helpMessage,
      this.imageScr,
      this.width,
      this.height,
      this.aspectRatio,
      {'class': this.modalHostClass}
    )
      .subscribe(result => {
        result && this.added.emit(result);
        this.closed.emit();
      });
  }

}

import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { AuthModule } from '../auth/auth.module';
import { ChangeLangDropdownComponent } from './change-lang-dropdown/change-lang-dropdown.component';
import { FooterComponent } from './footer/footer.component';
import { GuestNavbarComponent } from './guest-navbar/guest-navbar.component';
import { InfoPremiumComponent } from './info-premium/info-premium.component';
import { MainLayoutComponent } from './main-layout.component';
import { ProfileNavbarComponent } from './profile-navbar/profile-navbar.component';
import { SearchComponent } from './profile-navbar/search/search.component';
import { SponsorNavbarComponent } from './profile-navbar/sponsor-navbar/sponsor-navbar.component';
import { UserLandingFooterComponent } from './user-landing/user-landing-footer/user-landing-footer.component';
import { UserLandingHeaderComponent } from './user-landing/user-landing-header/user-landing-header.component';
import { UserLandingComponent } from './user-landing/user-landing.component';
import { PaymentCanceledComponent } from './profile-navbar/payment-canceled/payment-canceled.component';
import { PaymentCanceledSurveyComponent } from './profile-navbar/payment-canceled-survey/payment-canceled-survey.component';


@NgModule({
  imports: [
    SharedModule,
    AuthModule,
    SwiperModule
  ],
  declarations: [
    MainLayoutComponent,
    FooterComponent,
    ProfileNavbarComponent,
    ChangeLangDropdownComponent,
    GuestNavbarComponent,
    InfoPremiumComponent,
    SponsorNavbarComponent,
    SearchComponent,
    UserLandingComponent,
    UserLandingFooterComponent,
    UserLandingHeaderComponent,
    PaymentCanceledComponent,
    PaymentCanceledSurveyComponent
  ],
  entryComponents: [ PaymentCanceledComponent, PaymentCanceledSurveyComponent ],
  exports: [
    MainLayoutComponent,
    UserLandingComponent
  ],
})
export class MainLayoutModule {
}

import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { AccountType } from '@shared/enums/account/type.enum';
import { BaseModalComponent } from '@shared/modals/base.modal.component';
import { ModalsService } from '@shared/modals/modals.service';
import { BsModalRef } from 'ngx-bootstrap';


@Component({
  selector: 'iv-choose-type-modal',
  templateUrl: './choose-type-modal.component.html'
})
export class ChooseTypeModalComponent extends BaseModalComponent {

  @Input()
  public ownerType: number;

  @Input()
  public regType: number;

  public step: number = 1;

  public get questionType(): string {
    let result: string = '';

    switch (this.step) {
      case 1:
        result = 'register_type_question_1';
        break;
      case 2:
        result = 'register_type_question_2';
        break;
      case 3:
        result = 'register_type_question_3';
        break;
      default:
        result = '';
        break;
    }

    return result;
  }

  constructor(protected modalRef: BsModalRef,
              private cd: ChangeDetectorRef,
              private modalsService: ModalsService) {
    super(modalRef);
  }

  public showInfo(): void {
    let title = '', description = '';
    switch (this.step) {
      case 1:
        title = '_registration._info._enterpreneur._title';
        description = '_registration._info._enterpreneur._text';
        break;
      case 2:
        title = '_registration._info._partner._title';
        description = '_registration._info._partner._text';
        break;
    }
    this.modalsService.openAlertTitle(title, description);
  }

  public next(): void {
    this.setResult(this.ownerType);
    this.modalRef.hide();
  }

  public answerYes(): void {
    switch (this.step) {
      case 1:
        this.setResult(AccountType.Entrepreneur);
        this.modalRef.hide();
        break;
      case 2:

        this.setResult(AccountType.Partner);
        this.modalRef.hide();
        break;
      case 3:
        this.step = 1;
        break;
    }
    this.cd.markForCheck();
  }

  public answerNo(): void {
    switch (this.step) {
      case 1:
        this.step = 2;
        break;
      case 2:
        this.step = 3;
        break;
      case 3:
        this.modalRef.hide();
        break;
    }
    this.cd.markForCheck();
  }
}

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { concatMap } from 'rxjs/operators';
import { NeedStatus } from '../../../projects/needs/need-status.enum';
import { NeedsService } from '../../../projects/needs/needs.service';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-close-all-needs',
  templateUrl: './close-all-needs.component.html',
  styles: []
})
export class CloseAllNeedsComponent extends BaseModalComponent {

  public selected = 0;

  @Input()
  public projectId: number;

  @Input()
  public needId: number;

  constructor(protected bsModalRef: BsModalRef,
              private router: Router,
              private needsSrv: NeedsService) {
    super(bsModalRef);
    this.setResult(false);
  }

  public confirm(): void {
    switch (this.selected) {
      case 0:
        this.updateDeadline(3);
        break;
      case 1:
        this.updateDeadline(6);
        break;
      case 2:
        this.router.navigate(['/projects', this.projectId, 'needs', 'create']);
        break;
    }
    this.setResult(true);
    this.close();
  }

  public updateDeadline(months: number): void {
    if (!this.needId) return;
    this.needsSrv.getNeed(this.needId)
      .pipe(concatMap(need => {
        need.status = NeedStatus.InProgress;
        need.setDeadlineMonths(months);
          return this.needsSrv.save(need);
        })
      ).subscribe(() => {
    });
  }
}

export class ElementPositionRef {
  get positionTop(): number {
    const body = document.body;
    const docEl = document.documentElement;
    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const clientTop = docEl.clientTop || body.clientTop || 0;
    const rect = this.nativeElement.getBoundingClientRect();
    return rect.top + (scrollTop - clientTop);
  }

  get positionLeft(): number {
    const body = document.body;
    const docEl = document.documentElement;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;
    const rect = this.nativeElement.getBoundingClientRect();
    return rect.left + (scrollLeft - clientLeft);
  }

  constructor(public nativeElement: HTMLElement) {
  }

  scrollToSelf(offset: number = 0): void {
    window.scrollTo({
      behavior: 'smooth',
      top: this.positionTop - offset
    });
  }
}

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountActive } from '@shared/enums/account/active.enum';
import { ProfileInfoBase } from '@shared/models/account/profile/profile-info-base.model';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { AccountType } from '../enums/account/type.enum';
import { IResponseOk } from '../models/api-response/response-ok.interface';
import { BaseApiService } from './base-api-service';

export interface IInfoPremiumResponse {
  premium: boolean;
  active_period?: {
    id: number;
    begin: string;
    end: string;
  } | null;
  last_period?: {
    id: number;
    begin: string;
    end: string;
  } | null;
}

export interface IProfileSettingsResponse {
  has_password: boolean;
  has_verification_payment: boolean;
  subscribe?: number;
  newsletter?: number;
  commercial_info?: number;
  lang: number;
  privacy: {
    hide_name?: boolean;
    hide_email: boolean;
    hide_photo?: boolean;
    hide_profile?: boolean;
    nickname?: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ProfileHttpService extends BaseApiService {

  private settingsObservable$: Observable<IProfileSettingsResponse>;

  private profileInfoPremiumObservable$: Observable<IInfoPremiumResponse>;

  constructor(protected http: HttpClient,
              private location: Location) {
    super(http, '/profile');
  }

  public infoPremium(): Observable<IInfoPremiumResponse> {
    return this.get<IInfoPremiumResponse>('/info-premium');
  }

  public getSettings(): Observable<IProfileSettingsResponse> {
    if (!this.settingsObservable$) {
      this.settingsObservable$ = this.get<IProfileSettingsResponse>('/settings').pipe(shareReplay());
    }

    return this.settingsObservable$;
  }

  public saveSettings(settings: IProfileSettingsResponse): Observable<IProfileSettingsResponse> {
    return this.post<IProfileSettingsResponse>('/settings', settings);
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<{ text: string }> {
    return this.post<{ text: string }>('/change-password', {'current': currentPassword, 'new': newPassword});
  }

  public hasPassword(): Observable<{ password: boolean }> {
    return this.get<{ password: boolean }>('/has-password');
  }

  public getAuthenticatedProfile(): Observable<AuthenticatedProfileTypes> {
    if (GlobalConfig.ACC_ID === 0) {
      return of(<any>{
          id: 0,
          email: '',
          type: AccountType.NotSet,
          active: AccountActive.None,
          lang: this.location.prepareExternalUrl('').split('/').filter(s => !!s)[0]
        }
      );
    }

    return this.get<AuthenticatedProfileTypes>('/authenticated')
      .pipe(
        catchError(xhr => {
          const lang = xhr.error && xhr.error.lang ? xhr.error.lang : 'en';
          return of(<any>{
            id: 0,
            email: '',
            type: AccountType.NotSet,
            active: AccountActive.None,
            lang: lang
          })
        })
      );
  }

  public getApiToken(): Observable<{ token: string }> {
    return this.get<{ token: string }>('/api-token');
  }

  public getContact(accountId: string): Observable<ProfileInfoBase> {
    return this.get<ProfileInfoBase>(`info/${accountId}`).pipe(map(profile => new ProfileInfoBase(profile)));
  }

  public profileInfoPremium(): Observable<IInfoPremiumResponse> {
    if (!this.profileInfoPremiumObservable$) {
      this.profileInfoPremiumObservable$ = this.get<IInfoPremiumResponse>('/info-premium').pipe(shareReplay());
    }

    return this.profileInfoPremiumObservable$;
  }

  public checkCanRequest(): Observable<any> {
    return this.get<any>(`/check-can-request`);
  }

  public sendContactRequest(data: any): Observable<any> {
    return this.post<any>('/request', data);
  }

  public getRequestMessage(fromId: number, toId: number = GlobalConfig.ACC_ID): Observable<any> {
    return this.get<any>(`/request-message/${fromId}/${toId}`);
  }

  public sendRequestApply(fromContactId: number): Observable<any> {
    return this.post<any>('/request-apply', {user_id_from: fromContactId});
  }

  public sendRequestReject(rejectData: any): Observable<any> {
    return this.post<any>('/request-reject', rejectData);
  }

  public vatRate(countryIso: string): Observable<number> {
    return this.get<number>('/vat-rate', {params: {countryIso}});
  }

  public useBasicServices(): Observable<any> {
    return this.get<any>('use-basic-services');
  }

  public addFavoriteUser(userId: number): Observable<IResponseOk> {
    return this.post<IResponseOk>('favorite-user', {id: userId});
  }

  public addFavoriteProject(projectId: number): Observable<IResponseOk> {
    return this.post<IResponseOk>('favorite-project', {id: projectId});
  }

  public voteProject(vote: { comment: string, vote: number, id: number }): Observable<IVoteProjectResponse> {
    return this.post<IVoteProjectResponse>('/vote-project', vote)
  }
}

interface IVoteProjectResponse extends IResponseOk {
  avgVote: number;
  isNew: boolean;
}
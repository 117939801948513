import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TemplateService } from '@shared/api/template.service';
import { IRegistration } from '@shared/api/users.service';
import { AccountType } from '@shared/enums/account/type.enum';
import { ModalsService } from '@shared/modals/modals.service';
import { FlashService } from '@shared/services/flash.service';
import { FormatValidators } from '@shared/validators/format-validators';
import { PasswordValidators } from '@shared/validators/password-validators';
import { RecaptchaComponent } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { AbstractFormComponent } from '../../../../common-forms/abstract-form.component';
import { concatMap } from 'rxjs/operators';
import { ProfileService } from '@shared/services/profile.service';


@Component({
  selector: 'iv-registration-form',
  templateUrl: './registration-form.component.html'
})
export class RegistrationFormComponent extends AbstractFormComponent<IRegistration> implements OnInit, OnDestroy {

  @Input()
  public additionalParams: { inviteCode?: string } = {};

  @Input()
  public ownerType: number;

  @Input()
  public regType: number;

  @Output()
  public socialRegistration: EventEmitter<string> = new EventEmitter<string>();

  public message: string = '';

  public reCaptchaKey: string = GlobalConfig.RE_CAPCTCHA_KEY;

  @ViewChild('captchaRef')
  private captchaRef: RecaptchaComponent;

  private subscription: Subscription;

  constructor(protected flash: FlashService,
              protected cd: ChangeDetectorRef,
              protected fb: FormBuilder,
              private modalService: ModalsService,
              private staticPageService: TemplateService,
              private profileService: ProfileService
              ) {
    super(flash, cd, fb);

    this.subscription = this.form.get('showInvite')!.valueChanges
      .subscribe(value => this.toggleInviteCodeValidators(value));
  }

  public authFacebook(): void {
    this.socialRegistration.emit('facebook');
  }

  public authLinkedIn(): void {
    this.socialRegistration.emit('linkedin');
  }

  public authApple(): void {
    this.profileService.authApple();
  }

  public ngOnInit(): void {
    if (this.additionalParams.inviteCode) {
      this.form.patchValue(this.additionalParams);
      this.form.get('showInvite')!.setValue(true);
      this.form.get('showInvite')!.disable();
      this.form.get('inviteCode')!.disable();
    }

    if (this.ownerType) {
      this.form.get('type')!.setValue(this.ownerType);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onLicenceAgreementClick(event: MouseEvent): void {
    const element = event.srcElement;

    if (element instanceof HTMLAnchorElement) {
      const staticPageSlug = element.dataset.slug;

      event.preventDefault();
      event.stopPropagation();

      if (staticPageSlug) {
        const licenceText = staticPageSlug === 'charter' ?
          'license_agreement_terms_of_use' :
          'license_agreement_cookie_policy';

        this.staticPageService.getByName(staticPageSlug).pipe(
          concatMap(data => this.modalService.openAlertLicence(data.content, licenceText))
        )
          .subscribe(response => response && this.form.get('agree')!.setValue(true));
      }
    }
  }

  protected createForm(): FormGroup {
    const minFieldLength: number = 6;
    const maxFieldLength: number = 255;

    return this.fb.group({
      type: [AccountType.Entrepreneur, Validators.required],
      firstName: ['', [
        Validators.required,
        Validators.maxLength(maxFieldLength),
        FormatValidators.nameFormat
      ]],
      lastName: ['', [
        Validators.required,
        Validators.maxLength(maxFieldLength),
        FormatValidators.nameFormat
      ]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.maxLength(maxFieldLength)
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(minFieldLength),
        Validators.maxLength(maxFieldLength),
        FormatValidators.passwordFormat
      ]],
      confirmPassword: ['', PasswordValidators.matchPassword('password')],
      showInvite: false,
      inviteCode: '',
      agree: [false, Validators.requiredTrue],
      reCaptchaResponse: [null, Validators.required],
    });
  }

  protected onFinalize(): void {
    this.captchaRef.reset();
    super.onFinalize();
  }

  protected getFormData(): IRegistration {
    let formData = this.form.value;

    if (this.regType === 1) {
      formData.isWirate = true;
    }

    return formData;
  }

  private toggleInviteCodeValidators(value: boolean): void {
    const inviteCodeControl = this.form.get('inviteCode')!;

    if (value) {
      inviteCodeControl.enable();
      inviteCodeControl.setValidators(Validators.required);
    } else {
      inviteCodeControl.disable();
      inviteCodeControl.clearValidators();
    }

    inviteCodeControl.updateValueAndValidity();
  }

}

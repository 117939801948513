import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UsersService } from '@shared/api/users.service';
import { ICropImageData } from '@shared/directives/cropped-photo-uploader/crop-image-data.interface';
import { FlashService } from '@shared/services/flash.service';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ProfileStore } from '../../../../profile/profile.store';

@Component({
  selector: 'iv-user-photo-upload-button',
  templateUrl: './user-photo-upload-button.component.html',
  styleUrls: ['./user-photo-upload-button.component.scss']
})
export class UserPhotoUploadButtonComponent implements OnInit, OnDestroy {

  @Input()
  public hasPhoto: boolean = false;

  @Input()
  public hasOldPhoto: boolean = false;

  @Input()
  public uploadPhotoText: string;

  @Input()
  public navigatable: boolean = false;

  @Input()
  public nextNavigatableControlName: string = '';

  @Output()
  public photoUploaded: EventEmitter<string> = new EventEmitter<string>();

  public photoLoad: boolean = false;

  @ViewChild('input')
  private input: ElementRef;

  private subscription: Subscription;

  public get uploadText(): string {
    if (this.uploadPhotoText !== undefined) return this.uploadPhotoText;
    if (this.hasPhoto) {
      if (this.hasOldPhoto) return 'update_photo';
      return 'change_photo';
    }
    return 'upload_photo';
  }

  constructor(private flash: FlashService,
              private usersService: UsersService,
              private profileStore: ProfileStore) {
  }

  public ngOnInit(): void {
    this.subscription = this.profileStore.openPhotoDialog$
      .pipe(tap(() => this.input.nativeElement.click()))
      .subscribe(() => setTimeout(this.input.nativeElement.click.bind(this), 1000));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public open(): void {
    this.input.nativeElement.click();
  }

  public onFileAdd(file: ICropImageData): void {
    this.photoLoad = true;
    this.usersService.updatePhoto(file)
      .pipe(finalize(() => this.photoLoad = false))
      .subscribe(data => {
        this.profileStore.setProfileInfoPhoto(data.preview_url);
        this.flash.success(data.text);
        this.photoUploaded.emit(data.url);
      }, err => {
        if (err && err.error && err.error.text) {
          this.flash.danger(err.error.text);
        }
      });
  }
}

import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { DeviceDetectorService, DEVICES, OS } from 'ngx-device-detector';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-download-app',
  templateUrl: './download-app.component.html',
  styles: []
})
export class DownloadAppComponent extends BaseModalComponent {
  isAndroid: boolean;

  isIOS: boolean;

  constructor(protected bsModalRef: BsModalRef,
              private deviceService: DeviceDetectorService) {
    super(bsModalRef);
    this.setResult('close');
    const deviceInfo = this.deviceService.getDeviceInfo();
    this.isAndroid = deviceInfo.os === OS.ANDROID || deviceInfo.device === DEVICES.ANDROID;
    this.isIOS = deviceInfo.os === OS.IOS || deviceInfo.device === DEVICES.I_POD || deviceInfo.device === DEVICES.IPHONE;
  }
}

import { Component, Input } from '@angular/core';
import { UserDetail } from '../user-detail/models/user-detail.model';

@Component({
  selector: 'iv-profile-confirm-percent',
  templateUrl: './profile-confirm-percent.component.html',
  styles: [':host {display: block}']
})
export class ProfileConfirmPercentComponent {

  @Input()
  public set userDetail(userDetail: UserDetail) {
    if (userDetail) {
      this._userDetail = userDetail;
      this.setSteps();
      this.calculateTotalPercent();
    }
  }

  public get userDetail(): UserDetail {
    return this._userDetail;
  }

  private _userDetail: UserDetail;

  @Input()
  public set hasPhoto(hasPhoto: boolean) {
    if (this.userDetail) {
      this._hasPhoto = hasPhoto;
      this.setSteps();
      this.calculateTotalPercent();
    }
  }

  public get hasPhoto(): boolean {
    return this._hasPhoto;
  }

  private _hasPhoto: boolean = false;

  @Input()
  public tip: boolean = false;

  public totalPercent: number;

  public steps: {
    icon: string;
    percent: number;
  }[] = [];

  public get isTipVisible(): boolean {
    return this.totalPercent < 80 && this.tip;
  }

  public getPercent(percent: number): string {
    return Math.round(percent * this.steps.length) + '%';
  }

  private setSteps(): void {
    this.steps = [
      {
        icon: '<i class="fa fa-id-badge" aria-hidden="true"></i>',
        percent: this.calculateCompletedMe()
      },
      {
        icon: '<i class="fa fa-compass" aria-hidden="true"></i>',
        percent: this.calculateCompleteKnowledge()
      },
      {
        icon: '<i class="fa fa-graduation-cap" aria-hidden="true"></i>',
        percent: this.calculateCompleteEducation()
      },
      {
        icon: '<i class="fa fa-line-chart" aria-hidden="true"></i>',
        percent: this.calculateCompleteJob()
      }
    ];
  }

  private calculateTotalPercent(): void {
    this.totalPercent = this.steps
      .map(step => step.percent)
      .reduce((prev, current) => prev += current, 0);
  }

  private calculateCompletedMe(): number {
    let percent: number = 0;

    if (this.userDetail.gender) {
      percent += 5;
    }

    if (this.userDetail.dob) {
      percent += 5;
    }

    if (+this.userDetail.location.latitude && +this.userDetail.location.longitude) {
      percent += 6;
    }

    if (this.hasPhoto) {
      percent += 5;
    }

    if (Object.keys(this.userDetail.social).some(key => !!this.userDetail.social[key].url)) {
      percent += 4;
    }

    return percent;
  }

  private calculateCompleteKnowledge(): number {
    let percent: number = 0;

    if (this.userDetail.languages.length !== 0) {
      percent += 5;
    }

    if (this.userDetail.experience) {
      percent += 5;
    }

    if (this.userDetail.skills.length !== 0) {
      percent += 5;
    }

    if (this.userDetail.accomplishment.length !== 0) {
      percent += 5;
    }

    if (this.userDetail.abilities.length !== 0) {
      percent += 5;
    }

    return percent;
  }

  private calculateCompleteEducation(): number {
    let percent: number = 0;

    if (this.userDetail.education.length === 0) {
      return percent;
    }

    const firstEducation = this.userDetail.education[0];

    if (firstEducation.name) {
      percent += 5;
    }

    if (firstEducation.year_from || firstEducation.year_to) {
      percent += 4;
    }

    if (firstEducation.id_degree) {
      percent += 5;
    }

    if (firstEducation.study) {
      percent += 5;
    }

    if (firstEducation.description) {
      percent += 6;
    }

    return percent;
  }

  private calculateCompleteJob(): number {
    let percent: number = 0;

    if (this.userDetail.jobs.length === 0) {
      return 0;
    }

    const firstJob = this.userDetail.jobs[0];

    if (firstJob.company) {
      percent += 5;
    }

    if (firstJob.title) {
      percent += 5;
    }

    if (firstJob.location) {
      percent += 5;
    }

    if (firstJob.period_from) {
      percent += 5;
    }

    if (firstJob.description) {
      percent += 5;
    }

    return percent;
  }


}
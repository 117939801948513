import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { inject, InjectionToken, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { IvHttpInterceptor } from '@shared/api/iv-http-interceptor';
import { HttpLoaderFactory } from '@shared/api/translate-http-factory';
import { SharedModule } from '@shared/shared.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { RecaptchaModule } from 'ng-recaptcha';
import {
  BsDatepickerConfig,
  BsDatepickerModule,
  BsDropdownModule,
  CollapseModule,
  ModalModule,
  PaginationModule,
  RatingModule,
  TooltipModule,
} from 'ngx-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FlashMessagesModule } from 'ngx-flash-messages';
import { SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRouting } from './app.routing';
import { AuthModule } from './auth/auth.module';
import { CommonFormsModule } from './common-forms/common-forms.module';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { EmailConfirmedRedirectComponent } from './email-confirmed-redirect/email-confirmed-redirect.component';
import { FlashMessageComponent } from './flash-message/flash-message.component';
import { FriendInviteComponent } from './friend-invite/friend-invite.component';
import { ReferFormComponent } from './friend-invite/refer-form/refer-form.component';
import { MainLayoutModule } from './main-layout/main-layout.module';
import { ConfirmWithCheckboxComponent } from './page/confirm-with-checkbox/confirm-with-checkbox.component';
import { PageComponent } from './page/page.component';
import { ProfileStore } from './profile/profile.store';
import { ReportAbusePageComponent } from './report-abuse-page/report-abuse-page.component';
import { SignUpRedirectComponent } from './sign-up-redirect/sign-up-redirect.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaymentComponent } from './payment/payment.component';
import {NfResolver} from './not-found/not-found.resolver';

export const BS_CONFIG = new InjectionToken<Observable<Partial<BsDatepickerConfig>>>('bsConfig', {
  providedIn: 'root',
  factory: () => {
    const translateService: TranslateService = inject(TranslateService);
    const profileStore: ProfileStore = inject(ProfileStore);

    let locale = translateService.currentLang || window.navigator.language;

    return profileStore.getAuthenticatedProfile().pipe(
      map(profile => {

        if (locale === 'en' && profile.countryIso !== 'US') {
          locale = 'en-GB';
        }

        return {
          containerClass: 'theme-blue',
          dateInputFormat: locale === 'en' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'
        };
      })
    );
  }
});

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 1,
  mousewheel: true,
  keyboard: true,
  threshold: 50,
  spaceBetween: 5,
};

@NgModule({
  declarations: [
    AppComponent,
    SignUpRedirectComponent,
    FriendInviteComponent,
    FlashMessageComponent,
    ReferFormComponent,
    ContactUsPageComponent,
    ReportAbusePageComponent,
    PageComponent,
    EmailConfirmedRedirectComponent,
    ConfirmWithCheckboxComponent,
    NotFoundComponent,
    PaymentComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MainLayoutModule,
    HttpClientModule,
    FlashMessagesModule,
    CookieModule.forRoot(),
    DeviceDetectorModule.forRoot(),
    LoadingBarHttpClientModule,
    RecaptchaModule.forRoot(),
    Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    RatingModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),

    AuthModule,

    CommonFormsModule,
    SharedModule.forRoot(),

    AppRouting,

    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
      libraries: ['places'],
      language: 'en'
    })
  ],
  providers: [
    NfResolver,
    {provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG},
    {provide: HTTP_INTERCEPTORS, useClass: IvHttpInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';

@Directive({
  selector: 'input:not([type="radio"]):not([bsDatepicker]):not([type="file"]):not([type="checkbox"):not([ivGooglePlacesAutocomplete])[ivNavigatableFormControl][formControlName], textarea[ivNavigatableFormControl][formControlName]',
  providers: [{provide: AbstractNavigatableDirective, useExisting: forwardRef(() => DefaultNavigatableDirective)}]
})
export class DefaultNavigatableDirective extends AbstractNavigatableDirective {

  @Input('ivNavigatableFormControlPreventDefaultBehavior')
  private isPreventDefaultBehavior: boolean = true;

  @Input('ivNavigatableFormControlNavigateOnTab')
  private navigateOnTab: boolean = false;

  private tabListener: (() => void) | null = null;

  constructor(private elementRef: ElementRef<HTMLInputElement>,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService,
              private renderer: Renderer2) {
    super(formControlName, service, scrollableService);
  }

  @HostListener('focus')
  public addTabListener(): void {
    if (this.navigateOnTab) {
      this.tabListener = this.renderer.listen('window', 'keydown.tab', (event: KeyboardEvent) => {
        if (event.srcElement === this.elementRef.nativeElement) {
          event.preventDefault();
          this.service.focusNext(this);
        }
      });
    }
  }

  @HostListener('blur')
  public removeTabListener(): void {
    this.tabListener && this.tabListener();
  }

  @HostListener('keyup.enter')
  public onEnter(): void {
    this.next();
  }

  @HostListener('keydown.enter', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    this.isPreventDefaultBehavior && event.preventDefault();
  }

  public nativeFocus(): void {
    this.elementRef.nativeElement.focus();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination } from '@shared/components/pagination/pagination.interface';
import { BehaviorSubject, of } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { concatMap, map, shareReplay, take, tap } from 'rxjs/operators';
import { ProfileStore } from '../../profile/profile.store';
import { IProjectListItem } from '../../projects/project-list-item.interface';
import { IProject } from '../../projects/project.interface';
import { ICropImageData } from '../directives/cropped-photo-uploader/crop-image-data.interface';
import { IRemoveReason } from '../modals/confirm-reason/remove-reason.interface';
import { IResponseOk } from '../models/api-response/response-ok.interface';
import { FileUploaderService } from '../services/file-uploader.service';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends BaseApiService {

  private readonly _projectsList$: BehaviorSubject<IProjectListItem[]> = new BehaviorSubject([]);

  public readonly projectsList$: Observable<IProjectListItem[]> = this._projectsList$.asObservable();

  private readonly _projects$: BehaviorSubject<IProject[]> = new BehaviorSubject<IProject[]>([]);

  constructor(protected http: HttpClient,
              private profileStore: ProfileStore,
              private fileUploader: FileUploaderService) {
    super(http, '/project');
  }

  public getProjects(reload: boolean = false): Observable<IProjectListItem[]> {
    if (this._projectsList$.value.length === 0 || reload) {
      return this.get<IProjectListItem[]>('/projects', {otherUrlPart: '/entrepreneur'})
        .pipe(
          shareReplay(),
          tap(projects => this._projectsList$.next(projects)),
          concatMap(() => this.projectsList$)
        );
    }

    return this.projectsList$;
  }

  public getProject(projectId: number, source: boolean = false, force: boolean = false): Observable<IProject> {
    if (force) {
      let index = this._projects$.value.findIndex(savedProject => savedProject.id === projectId);

      if (!!~index) {
        this._projects$.value.splice(index, 1);
      }

      return this.getProjectFromBackend(projectId, source);
    }

    let project = this._projects$.value.find(project => project.id === projectId);

    if (project && !source) {
      return of(project);
    }

    return this.getProjectFromBackend(projectId, source);
  }

  public getProjectBySlug(slug: string): Observable<IProject> {
    return this.get<IProject>(`/single/${slug}`).pipe(shareReplay());
  }

  public listFront(options: { sort?: string, size?: number } = {}): Observable<IPagination<IProjectListItem>> {
    const {sort = 'id', size = 7} = options;
    return this.get<IPagination<IProjectListItem>>('/list-frontend', {
      params: {
        size: size.toString(),
        sort
      }
    }).pipe(shareReplay());
  }

  public isDeleted(id: number): Observable<{ removed: boolean, rel_type: number, owner_removed: boolean, owner_name: string }> {
    return this.get<{ removed: boolean, rel_type: number, owner_removed: boolean, owner_name: string }>(`/${id}/is-removed`);
  }

  public listFrontConnectorGroup(): Observable<IPagination<IProjectListItem>> {
    return this.get<IPagination<IProjectListItem>>('list-frontend/group');
  }

  public save(project: IProject): Observable<IProject> {
    return this.post<IProject>('', project)
      .pipe(
        concatMap(project => this._projects$.value.length === 0 ?
          this.profileStore.loadProfile().pipe(map(() => project)) :
          of(project)
        )
      );
  }

  public remove(projectId: number, removeReason: IRemoveReason): Observable<IResponseOk> {
    let params: any = {reason: removeReason.reason.toString()};

    if (removeReason.reasonDescription) {
      params.reason_desc = removeReason.reasonDescription;
    }

    return this.del<IResponseOk>(`/${projectId}`, {params})
      .pipe(
        tap(res => res.code === 'ok' && this.removeProject(projectId)),
        tap(() => this._projects$.value.length === 0 && this.profileStore.loadProfile().subscribe())
      );
  }

  public isCanRemove(projectId: number): Observable<IResponseOk> {
    return this.get<IResponseOk>(`/${projectId}/can-remove`);
  }

  public uploadLogo(projectId: number, data: ICropImageData): Observable<{ preview: string }> {
    return this.fileUploader.upload<{ preview: string }>(data, this.getApiUrl(`/logo/${projectId}`))
  }

  public uploadBanner(projectId: number, data: ICropImageData): Observable<{ preview: string }> {
    return this.fileUploader.upload<{ preview: string }>(data, this.getApiUrl(`/banner/${projectId}`))
  }

  public addNewProject(project: IProject): void {
    let projects = this._projects$.value;
    let index = projects.findIndex(item => item.id === project.id);

    if (index !== -1) {
      projects.splice(index, 1);
    }

    projects.push(project);

    this._projects$.next([...projects]);

    this.getProjects(true).pipe(take(1)).subscribe();
  }

  private removeProject(projectId: number): void {
    let projects = this._projects$.value;
    let projectsList = this._projectsList$.value;

    let projectIndex = projects.findIndex(project => project.id === projectId);
    let projectListIndex = projectsList.findIndex(project => project.id === projectId);

    projects.splice(projectIndex, 1);
    projectsList.splice(projectListIndex, 1);

    this._projects$.next([...projects]);
    this._projectsList$.next([...projectsList]);
  }

  private getProjectFromBackend(projectId: number, source: boolean = false): Observable<IProject> {
    let params = {};

    if (source) {
      params = {source: 1};
    }

    return this.get<IProject>(`/${projectId}`, {params}).pipe(
      shareReplay(),
      tap(project => {
        if (source) {
          return;
        }

        let projects = this._projects$.value;
        projects.push(project);

        this._projects$.next(projects);
      })
    );
  }

}

import { Directive, HostListener, Renderer2 } from '@angular/core';
import { AbstractFocusableDirective } from '@shared/directives/focusable-form-control/abstract-focusable.directive';

@Directive({
  selector: 'input[ivFocusable], textarea[ivFocusable], button[ivFocusable]'
})
export class DefaultFocusableDirective extends AbstractFocusableDirective {

  constructor(protected renderer: Renderer2) {
    super(renderer);
  }

  @HostListener('focus')
  public onFocus(): void {
    this.addClass();
  }

  @HostListener('blur')
  public onBlur(): void {
    this.removeClass();
  }

}
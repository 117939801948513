import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class IndexService extends BaseApiService {

  private euCountriesObservable$: Observable<string[]>;

  constructor(protected http: HttpClient) {
    super(http);
  }

  public getEuCountries(): Observable<string[]> {
    if (!this.euCountriesObservable$) {
      this.euCountriesObservable$ = this.get<string[]>('/eu-countries').pipe(shareReplay());
    }

    return this.euCountriesObservable$;
  }

}
import { Injectable } from '@angular/core';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';

@Injectable({
  providedIn: 'root'
})
export class NavigatableFormControlService {

  private navigatableControls: AbstractNavigatableDirective[] = [];

  private navigatableControlsMap: Map<string, AbstractNavigatableDirective> = new Map();

  public add(control: AbstractNavigatableDirective): void {
    this.navigatableControls.push(control);

    if (!control.name) {
      throw Error('Navigatable control should have name.');
    }

    this.navigatableControlsMap.set(control.name, control);
  }

  public remove(control: AbstractNavigatableDirective): void {
    const index = this.navigatableControls.findIndex(navigatableControl => navigatableControl === control);

    if (index !== -1) {
      this.navigatableControls.splice(index, 1);
    }

    if (!control.name) {
      throw Error('Navigatable control should have name.');
    }

    this.navigatableControlsMap.delete(control.name);
  }

  public focusNext(control: AbstractNavigatableDirective): void {

    let nextControl;

    if (control.nextControlName && this.navigatableControlsMap.has(control.nextControlName)) {
      nextControl = this.navigatableControlsMap.get(control.nextControlName);
    } else {
      const index = this.navigatableControls.findIndex(navigatableControl => navigatableControl === control);

      if (index !== -1 && (index + 1) < this.navigatableControls.length) {
        nextControl = this.navigatableControls[index + 1];
      }
    }

    if (nextControl) {
      if (NavigatableFormControlService.CanNavigate(nextControl)) {
        nextControl.focus();
      } else {
        !nextControl.isNextDisabled && this.focusNext(nextControl);
      }
    }
  }

  private static CanNavigate(control: AbstractNavigatableDirective): boolean {
    const nextControlValue = control.control && control.control.value;
    const isInvalidControl = control.control && control.control.invalid;

    return isInvalidControl
      || control.isNavigateOnDirty
      || !nextControlValue
      || Array.isArray(nextControlValue) && nextControlValue.length === 0;
  }

}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'iv-payment',
  templateUrl: './payment.component.html',
  styles: []
})
export class PaymentComponent implements OnInit {

  constructor(activatedRoute: ActivatedRoute, router: Router) {
    if (activatedRoute.snapshot.queryParamMap.get('type') === '2') {
      router.navigate(['premium' , 'buy' , 'success'], { queryParams: {done: activatedRoute.snapshot.queryParamMap.get('success')}});
    } else if (activatedRoute.snapshot.queryParamMap.get('type') === '1') {
      router.navigate(['profile' , 'verification-history']);
    } else {
      router.navigate(['not-found']);
    }
  }

  ngOnInit() {
  }

}

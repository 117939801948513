import { Component } from '@angular/core';
import { AccountType } from '@shared/enums/account/type.enum';
import { BaseModalComponent } from '@shared/modals/base.modal.component';
import { ModalsService } from '@shared/modals/modals.service';
import { ProfileService } from '@shared/services/profile.service';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { concatMap, take } from 'rxjs/operators';
import { AuthModalsService } from '../auth-modals.service';


@Component({
  selector: 'iv-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends BaseModalComponent {

  constructor(protected bsModalRef: BsModalRef,
              private authModalsService: AuthModalsService,
              private modalsService: ModalsService,
              private profileService: ProfileService) {
    super(bsModalRef);
  }

  public registration(): void {
    this.bsModalRef.hide();
    this.profileService.registration();
  }

  public login(data$: Observable<any>): void {
    data$.pipe(
      concatMap(data => this.profileService.login(data)),
      take(1)
    )
      .subscribe(
        data => this.onLoginResponse(data),
        err => this.catchLoginError(err)
      );
  }

  public restorePassword(): void {
    this.authModalsService.openRestorePassword();
  }

  public onSocialNetworkLogin(socialNetworkName: string): void {
    if (socialNetworkName === 'facebook') {
      this.facebook();
    } else if (socialNetworkName === 'linkedin') {
      this.linkedin();
    } else if (socialNetworkName === 'apple') {
      this.apple();
    }
  }

  private facebook(): void {
    this.profileService.authFacebook().then(data => this.onLoginResponse(data));
  }

  private linkedin(): void {
    this.profileService.authLinkedin().then(data => this.onLoginResponse(data));
  }

  private apple(): void {
    // this.profileService.authApple().then(data => this.onLoginResponse(data));
    this.profileService.authApple();
  }

  private onLoginResponse(data: any): void {
    if (data.code === 'ok') {
      window.location.href = data.redirect_url;
    }
    this.bsModalRef.hide();
  }

  private catchLoginError(err: any): void {
    const data: { acc_type: number; type: string } = err.error;
    let title, desc;
    if (!data.type) {
      return;
    }
    if (data.type === 'inactive') {
      if (data.acc_type === AccountType.Sponsor) {
        title = '_modal_inform_sponsor_not_activated_title';
        desc = '_modal_inform_sponsor_not_activated_text';
      } else if (data.acc_type === AccountType.Connector) {
        title = '_modal_inform_connector_not_activated_title';
        desc = '_modal_inform_connector_not_activated_text';
      }
    } else if (data.type === 'mail-confirm') {
      if (data.acc_type === AccountType.Connector) {
        title = '_modal_inform_group_not_confirmed_title';
        desc = '_modal_inform_group_not_confirmed_text';
      } else if (data.acc_type === AccountType.Sponsor) {
        title = '_modal_inform_sponsor_not_confirmed_title';
        desc = '_modal_inform_sponsor_not_confirmed_text';
      }
    }
    if (title && desc) {
      this.modalsService.openAlertTitle(title, desc);
    }
  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { IPremiumBuyItem } from '../../../premium/premium-buy-item.interface';
import { PremiumService } from '../../../premium/premium.service';
import { IPremiumVariant } from '../../models/premium/premium.variant';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-cancellation-wirate',
  templateUrl: './cancellation-wirate.component.html',
  styles: []
})
export class CancellationWirateComponent extends BaseModalComponent implements OnInit {
  title = 'cancellation_payment_wirate';

  text = 'cancellation_payment_wirate_text';

  variant: IPremiumVariant;

  get textTranslateBind() {
    if (!this.variant) return {};
    return {
      currency: this.variant.price.currency.symbol,
      price: this.variant.price.price,
      other_price: 144, //TODO: need use price for some variant with current equal months
      months: this.variant.month
    }
  }

  constructor(protected bsModalRef: BsModalRef,
              private cd: ChangeDetectorRef,
              private premiumSrv: PremiumService) {
    super(bsModalRef);
    this.setResult(false);
  }

  ngOnInit() {
    this.premiumSrv.getBuyHistory().subscribe(res => this.initPaymentsHandle(res));
    this.premiumSrv.getWirateVariant().subscribe(res => {
      this.variant = res;
      this.cd.markForCheck();
    });
  }

  initPaymentsHandle(res: IPremiumBuyItem[]): void {
    if (res.length === 0) return;
    const lastPayment = res[0];
    if (lastPayment.state === -2) {
      this.title = 'refusal_payment_wirate';
      this.text = 'refusal_payment_wirate_text';
      this.cd.markForCheck();
    }
  }

  basicServices():void {
    this.close();
  }

  public specialOffer():void {
    this.setResult(this.variant.id);
    this.close();
  }
}

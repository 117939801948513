import { Directive, forwardRef } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { Subscription } from 'rxjs';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';
import { filter } from 'rxjs/operators';

@Directive({
  selector: 'ng-select[ivNavigatableFormControl][formControlName]',
  providers: [{provide: AbstractNavigatableDirective, useExisting: forwardRef(() => NgSelectNavigatableDirective)}]
})
export class NgSelectNavigatableDirective extends AbstractNavigatableDirective {

  private subscription: Subscription;

  constructor(private ngSelect: NgSelectComponent,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService) {
    super(formControlName, service, scrollableService);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.subscription = this.ngSelect.closeEvent.pipe(
      filter(() => !this.ngSelect.multiple && this.ngSelect.hasValue)
    )
      .subscribe(() => this.next());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public nativeFocus(): void {
    this.ngSelect.open();
  }

}
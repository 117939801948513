import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, shareReplay, tap } from 'rxjs/operators';
import { UsersService } from './users.service';


export interface ICounters {
  receiveContactRequest: number | null;
  sendContactRequest: number | null;
  activeContacts?: number;
  contacts?: number;
  messages?: number;

  [key: string]: number | any;
}

@Injectable({
  providedIn: 'root'
})
export class CountersStore {

  private counters: ICounters = {
    activeContacts: 0,
    contacts: 0,
    messages: 0,
    receiveContactRequest: null,
    sendContactRequest: null,
  };

  private countersSource = new BehaviorSubject<ICounters>(this.counters);

  public counters$: Observable<ICounters> = this.countersSource.asObservable();

  constructor(private userSrv: UsersService) {
    this.init();
  }

  public update(counters: any = {}): Observable<any> {
    return this.load(counters)
      .pipe(
        shareReplay(),
        concatMap(() => this.counters$)
      );
  }

  public get(): ICounters {
    return this.counters;
  }

  public set(name: string, value: number): void {
    this.counters[name] = value;
    this.countersSource.next(this.counters);
  }

  public increment(name: string, value: number = 1): void {
    this.counters[name] += value;
    this.countersSource.next(this.counters);
  }

  public decrement(name: string, value: number = 1): void {
    this.counters[name] -= value;
    this.countersSource.next(this.counters);
  }

  private init(): void {
    this.load().subscribe();
  }

  private load(counters: any = {}): Observable<any> {
    return this.userSrv.getCounters<ICounters>(counters)
      .pipe(tap(counters => {
        Object.assign(this.counters, counters);
        this.countersSource.next(this.counters);
      }));
  }
}

import { EventEmitter, Inject, Injectable } from '@angular/core';
import { ScrollableFormControlDirective } from '../directives/scrollable-form-control.directive';
import { FormControl } from '@angular/forms';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { DOCUMENT } from '@angular/common';
import { HeaderHeightService } from '@shared/services/header-height.service';

@Injectable({
  providedIn: 'root'
})
export class ScrollableFormControlService {

  private readonly SCROLL_DURATION: number = 250;

  private readonly FIELD_OFFSET_CHANGE: number = 20;

  private controls: ScrollableFormControlDirective[] = [];

  private scrollFinish: EventEmitter<boolean> = new EventEmitter();

  private currentControl: ScrollableFormControlDirective | null = null;

  constructor(@Inject(DOCUMENT)
              private document: Document,
              private pageScrollService: PageScrollService,
              private headerHeightService: HeaderHeightService) {
    this.scrollFinish
      .subscribe(() => this.currentControl && this.currentControl.focus());
  }


  public add(control: ScrollableFormControlDirective): void {
    this.controls.push(control);
  }

  public remove(control: ScrollableFormControlDirective): void {
    const index = this.controls.findIndex(c => c === control);

    if (index !== -1) {
      this.controls.splice(index, 1);
    }
  }

  public scrollToControl(control: FormControl): void {
    const scrollControl = this.controls.find(scrollControl => scrollControl.control === control);

    if (scrollControl) {
      this.currentControl = scrollControl;

      this.pageScrollService.start(
        PageScrollInstance.newInstance({
          scrollTarget: scrollControl.nativeElement,
          document: this.document,
          pageScrollOffset: this.headerHeightService.height + this.FIELD_OFFSET_CHANGE,
          pageScrollDuration: this.SCROLL_DURATION,
          pageScrollFinishListener: this.scrollFinish
        })
      );
    }
  }

}
import { Injectable } from '@angular/core';
import { IInfoPremiumResponse } from '@shared/api/profile-http.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { ChooseTypeModalComponent } from './registration/choose-type-modal/choose-type-modal.component';
import { RegistrationModalComponent } from './registration/registration-modal/registration-modal.component';
import { WirateModalComponent } from './registration/wirate-modal/wirate-modal.component';
import { RestorePasswordComponent } from './restore-password/restore-password.component';

@Injectable({
  providedIn: 'root'
})
export class AuthModalsService {

  constructor(private bsModalService: BsModalService) {
  }

  public openRestorePassword(options?: ModalOptions): Promise<string> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': `modal-sm`
    }, options || {});

    const bsModalRef = this.bsModalService.show(RestorePasswordComponent, modalOpts);

    return bsModalRef.content.result$;
  }

  public openChooseTypeModal(ownerType: number = 1, regType: number = 0, additionalParams: any = {}, options?: ModalOptions): Observable<number> {
    let windowClass = '';
    switch (regType) {
      case 1: // WIRATE
        windowClass = 'for-wirate';
        break;
    }

    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': `modal-sm reg-modal-type ${windowClass}`,
      initialState: {ownerType, regType, additionalParams}
    }, options || {});

    const bsModalRef = this.bsModalService.show(ChooseTypeModalComponent, modalOpts);

    return bsModalRef.content.result$;

  }

  public openRegistrationModal(ownerType: number = 1, regType: number = 0, additionalParams: any = {}, options?: ModalOptions): Observable<any> {
    let windowClass = '';
    switch (regType) {
      case 1: // WIRATE
        windowClass = 'for-wirate';
        break;
    }

    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': `modal-sm reg-modal-form ${windowClass}`,
      initialState: {ownerType, regType, additionalParams}
    }, options || {});

    const bsModalRef = this.bsModalService.show(RegistrationModalComponent, modalOpts);

    return bsModalRef.content.result$;
  }

  public openWirateModal(variantId: number, premiumInfo: IInfoPremiumResponse, options?: ModalOptions): Observable<any> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': `premium-buy-modal for-wirate`,
      keyboard: false,
      ignoreBackdropClick: true,
      initialState: {variantId, premiumInfo}
    }, options || {});

    const bsModalRef = this.bsModalService.show(WirateModalComponent, modalOpts);

    return bsModalRef.content.result$;
  }
}

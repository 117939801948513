import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashService } from '@shared/services/flash.service';
import { FormatValidators } from '@shared/validators/format-validators';
import { AbstractFormComponent } from '../../../common-forms/abstract-form.component';

@Component({
  selector: 'iv-login-form',
  templateUrl: './login-form.component.html',
})
export class LoginFormComponent extends AbstractFormComponent<any> {

  @Output()
  public socialNetworkLogin: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public restorePassword: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected flash: FlashService,
              protected cd: ChangeDetectorRef,
              protected fb: FormBuilder) {
    super(flash, cd, fb);
  }

  protected createForm(): FormGroup {

    const minFieldLength: number = 6;
    const maxFieldLength: number = 255;

    return this.fb.group({
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.maxLength(maxFieldLength)
      ]],
      password: ['', [
        Validators.required,
        Validators.minLength(minFieldLength),
        Validators.maxLength(maxFieldLength),
        FormatValidators.passwordFormat
      ]],
      remember: false,
    });
  }

}

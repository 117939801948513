import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from '@shared/services/profile.service';
import { Subscription } from 'rxjs';
import { InfoPremiumEnum } from './info-premium.enum';

@Component({
  selector: 'iv-info-premium',
  templateUrl: './info-premium.component.html'
})
export class InfoPremiumComponent implements OnInit, OnDestroy {

  public InfoPremiumEnum = InfoPremiumEnum;

  public premiumBuyUrl: string = '/premium/become';

  public friendInviteUrl: string = '/invite-friend';

  public state: InfoPremiumEnum;

  public date: Date;

  public days: number;

  public showReferFriend: boolean;

  private subscription: Subscription;

  constructor(public profileService: ProfileService,
              private router: Router,
              private ngZone: NgZone) {
  }

  public ngOnInit(): void {
    this.subscription = this.profileService.infoPremium().subscribe(
      infoPremium => {
        if (!infoPremium.premium && (!infoPremium.last_period || !infoPremium.last_period.end)) {
          this.state = InfoPremiumEnum.NoPremium;
        } else if (infoPremium.premium && infoPremium.active_period) {
          this.date = new Date(infoPremium.active_period.end);
          this.days = Math.round((this.date.getTime() - (new Date()).getTime()) / 1000 / 60 / 60 / 24);
          this.state = this.days <= 15 ? InfoPremiumEnum.EndSoon : InfoPremiumEnum.IsPremium;
        } else if (infoPremium.last_period) {
          this.date = new Date(infoPremium.last_period.end);
          this.state = InfoPremiumEnum.EndedPremium;
        }
        this.toggleReferFriend();
        this.checkPage();
      }
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private toggleReferFriend(): void {
    const intervalTime = 1000 * 30;
    if (this.router.isActive(this.friendInviteUrl, true)) {
      this.showReferFriend = false;
    } else if (this.state === InfoPremiumEnum.NoPremium || this.state === InfoPremiumEnum.IsPremium) {
      this.showReferFriend = true;
    }
    setTimeout(() => {
      if (this.router.isActive(this.friendInviteUrl, true)) {
        this.showReferFriend = false;
      } else if (this.state === InfoPremiumEnum.EndedPremium || this.state === InfoPremiumEnum.EndSoon) {
        this.showReferFriend = !this.showReferFriend;
      } else {
        this.showReferFriend = true;
      }
      this.toggleReferFriend();
    }, intervalTime);
  }

  private checkPage(): void {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        if (this.router.isActive(this.friendInviteUrl, true)) {
          this.showReferFriend = false;
        } else if (this.state === InfoPremiumEnum.NoPremium || this.state === InfoPremiumEnum.IsPremium) {
          this.showReferFriend = true;
        }
      }, 300);
    });
  }
}

import { Gender } from '@shared/enums/account/user/gender.enum';
import { UserStatus } from '@shared/enums/account/user/status.enum';
import { AccountModel } from '@shared/models/account/account.model';
import { IReview } from '@shared/models/account/profile/review.interface';

export class ProfileInfoBase extends AccountModel {

  public premium: boolean;

  public has_verified: boolean;

  public photo: string;

  public nickname: string;

  public hide_name: boolean;

  public gender: Gender;

  public dob: Date;

  public hasVideo: boolean;

  public skills: string;

  public experience: string;

  public industry_sector: string;

  public review_info: IReview;

  public status: UserStatus;

  public isFavorite: boolean;

  public first_name: string;

  public last_name: string;

  public organization: string;

}
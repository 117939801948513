import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splice'
})
export class SplicePipe implements PipeTransform {

  public transform(value: string, end: number): any {
    if (typeof value !== 'string' || value === '') {
      return value;
    }

    const start = 0;

    if (value.length > end) {
      return value.substring(start, end) + '...';
    }

    return value;
  }

}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'iv-loading-spinner',
  templateUrl: './loading-spinner.component.html',
})
export class LoadingSpinnerComponent {

  @Input()
  public loading: boolean = false;

}

import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class PasswordValidators {
  static matchPassword(fieldName: string): ValidatorFn {
    let controlSecond: AbstractControl | null;

    return function matchPasswordFn(controlFirst: AbstractControl): ValidationErrors | null {
      if (!controlFirst.parent) return null;

      if (!controlSecond) {
        controlSecond = controlFirst.parent.get(fieldName);

        if (!controlSecond) throw new Error('matchPasswordFn(): Second control is not found in the parent group!');

        controlSecond.valueChanges.subscribe(() => controlFirst.updateValueAndValidity());
      }

      let invalid = controlSecond.value !== controlFirst.value;
      return invalid ? {matchPassword: true} : null;
    };
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ICurrency } from '../models/currency.interface';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService extends BaseApiService {

  private locationObservable$: Observable<ICurrency>;

  private currencies$: Observable<ICurrency[]>;

  constructor(protected http: HttpClient) {
    super(http, '/currencies');
  }

  public getCurrencies(): Observable<ICurrency[]> {
    if (!this.currencies$) {
      this.currencies$ = this.get<ICurrency[]>('').pipe(shareReplay());
    }

    return this.currencies$;
  }

  location(): Observable<ICurrency> {
    if (!this.locationObservable$) {
      this.locationObservable$ = this.get<ICurrency>('/location').pipe(shareReplay());
    }

    return this.locationObservable$;
  }

}

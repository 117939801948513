export class Patterns {

  public static readonly FACEBOOK: RegExp = /^http(s)?:\/\/(www\.)?facebook\.com\/.+$/mi;

  public static readonly LINKEDIN: RegExp = /^http(s)?:\/\/(www\.)?linkedin\.com\/in\/.+/mi;

  public static readonly TWITTER: RegExp = /^http(s)?:\/\/(www\.)?twitter\.com\/.+/mi;

  public static readonly BLOGGER: RegExp = /^http(s)?:\/\/(www\.)?.+\.blogspot\.com/mi;

  public static readonly VIADEO: RegExp = /^http(s)?:\/\/(www\.)?viadeo\.com\/.+/mi;

  public static readonly VIDEO_PATTERN: RegExp = /^(http(s)?:\/\/)?(www\.)?((youtube\.com)|(youtu\.be)|(vimeo\.com))\/.+/mi;

  public static readonly WEB_SITE: RegExp = /^http(s)?:\/\/(www\.)?.+/mi;

  public static readonly TWO_WORDS_MIN: RegExp = /([^\s]+\s+){2,}/mi;

  public static readonly EMAIL: RegExp = /\S*@\S*(\s|$)/gmi;

  public static readonly NOT_STRICT_WEB_SITE: RegExp = /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)$/gmi;

  public static readonly PHONE_NUMBER: RegExp = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/gmi;

  public static readonly USERNAME: RegExp = /[<>@!#$%^&*()_+\[\]{}?:;|"\/\\,.~\-=0-9]+/g;

  public static readonly PASSWORD: RegExp = /\s+/g;

  public static readonly PROJECT_TITLE: RegExp = /[<>@#$%^*()_+\[\]{}|"\/\\~\-=]+/g;
}

import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'iv-not-found',
  templateUrl: './not-found.component.html',
  styles: []
})
export class NotFoundComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}

import { AccountType } from '@shared/enums/account/type.enum';
import { Gender } from '@shared/enums/account/user/gender.enum';
import { ISocialNetwork } from '@shared/models/account/social-network.interface';
import { ILocation } from '@shared/models/location.interface';
import { Education } from './education.interface';
import { Job } from './job.interface';
import { Knowledge } from './knowledge.interface';
import { Language } from './language.interface';
import { Accomplishment } from './accomplishment.interface';
import { UserAbout } from './user-about.interface';

export class UserDetail implements Knowledge, UserAbout {

  public education: Education[];

  public jobs: Job[];

  public languages: Language[];

  public experience: number | null;

  public abilities: number[];

  public skills: number[];

  public accomplishment: Accomplishment[];

  public video: string;

  public gender: Gender;

  public type: AccountType;

  public location: ILocation;

  public social: ISocialNetwork;

  public dob: Date | null;

  public mobilePhone: string | null = null;

  constructor(userDetail: UserDetail) {
    Object.assign(this, userDetail);
    this.dob = this.dob ? new Date(this.dob) : null;
  }

}
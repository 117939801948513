import { Pipe, PipeTransform } from '@angular/core';
import { PaymentMethod } from '../models/authenticated-profile/payment-method.enum';

@Pipe({
  name: 'paymentMethod'
})
export class PaymentMethodPipe implements PipeTransform {

  public transform(value: number): string {
    switch (value) {
      case PaymentMethod.PAYPAL:
        return 'PayPal';
      case PaymentMethod.BITPAY:
        return 'BitPay';
      case PaymentMethod.VISA_MASTERCARD:
        return 'Visa/MasterCard';
      default:
        return 'Unknown';
    }
  }

}

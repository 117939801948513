import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashService } from '@shared/services/flash.service';
import { AbstractFormComponent } from '../../../common-forms/abstract-form.component';

@Component({
  selector: 'iv-restore-password-form',
  templateUrl: './restore-password-form.component.html',
})
export class RestorePasswordFormComponent extends AbstractFormComponent<{ email: string }> {

  @Input()
  public messageError: string = '';

  constructor(protected flash: FlashService,
              protected cd: ChangeDetectorRef,
              protected fb: FormBuilder) {
    super(flash, cd, fb);
  }

  protected createForm(): FormGroup {
    return this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }

}

import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import * as EXIF from 'exif-js';
import { BsModalRef } from 'ngx-bootstrap';
import { CropperPosition } from 'ngx-image-cropper';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-crop',
  templateUrl: './crop.component.html',
  styles: []
})
export class CropComponent extends BaseModalComponent {

  @Input()
  public size = 300;

  @Input()
  public aspectRatio = 1;

  public croppedImage: string;

  public cropper: CropperPosition = {
    x1: -100,
    y1: -100,
    x2: 1000,
    y2: 1000,
  };

  @ViewChild('cropperEl', {read: ElementRef})
  public cropperRef: ElementRef;

  private originalWidth: number;

  private originalOrientation: number;

  private _imageSrc: string;

  public get imageSrc(): string {
    return this._imageSrc;
  }

  @Input()
  public set imageSrc(src: string) {
    const image = new Image();
    const canvas = <HTMLCanvasElement>document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    image.src = src;

    if (ctx === null) {
      return;
    }

    EXIF.getData(<any>image, () => {
      const width = image.width,
        height = image.height,
        orientation = EXIF.getTag(image, 'Orientation') || 1;

      canvas.width = (4 < orientation && orientation < 9) ? height : width;
      canvas.height = (4 < orientation && orientation < 9) ? width : height;

      // transform context before drawing image
      switch (orientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(image, 0, 0);

      // export base64
      this.originalWidth = (4 < orientation && orientation < 9) ? height : width;
      this.originalOrientation = orientation;
      this._imageSrc = canvas.toDataURL();
    });
  }

  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }

  public imageCropped(image: string): void {
    this.croppedImage = image;
  }

  public save(): void {
    const sourceImg = this.cropperRef.nativeElement.querySelector('.source-image');
    const ratio = this.originalWidth / sourceImg.offsetWidth;
    const data = {
      x: Math.round(this.cropper.x1 * ratio),
      y: Math.round(this.cropper.y1 * ratio),
      width: Math.round((this.cropper.x2 - this.cropper.x1) * ratio),
      height: Math.round((this.cropper.y2 - this.cropper.y1) * ratio),
      preview: this.croppedImage,
      orientation: this.originalOrientation || 1
    };
    this.setResult(data);
    this.close();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { OptionService } from '@shared/api/option.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileStore } from '../../profile/profile.store';

@Pipe({
  name: 'langName'
})
export class LangNamePipe implements PipeTransform {

  constructor(private option: OptionService,
              private profileStore: ProfileStore) {
  }

  public transform(languageId: number): Observable<string> {
    if (!languageId) return of('');

    return this.option.getLanguages().pipe(
      map(languages => {
        let language = languages.find(lang => +lang.id === +languageId);

        if (!language) {
          return '';
        }

        const nativeName = language.nativeName && language.code !== this.profileStore.profile.lang
          ? `(${language.nativeName})`
          : '';

        return `${language.name} ${nativeName}`;
      })
    )
  }

}
import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { BsDatepickerDirective } from 'ngx-bootstrap';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { Subscription } from 'rxjs';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';

@Directive({
  selector: '[bsDatepicker][ivNavigatableFormControl][formControlName]',
  providers: [{provide: AbstractNavigatableDirective, useExisting: forwardRef(() => BsDatepickerNavigatableDirective)}]
})
export class BsDatepickerNavigatableDirective extends AbstractNavigatableDirective {

  private subscription: Subscription;

  private tabListener: (() => void) | null = null;

  constructor(private datepicker: BsDatepickerDirective,
              private elementRef: ElementRef<HTMLInputElement>,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService,
              private renderer: Renderer2) {
    super(formControlName, service, scrollableService);
  }

  @HostListener('focus')
  public addTabListener(): void {
    this.tabListener = this.renderer.listen('window', 'keydown.tab', (event: KeyboardEvent) => {
      if (event.srcElement === this.elementRef.nativeElement) {
        event.preventDefault();
        this.service.focusNext(this);
        this.datepicker.hide();
      }
    });
  }

  @HostListener('blur')
  public removeTabListener(): void {
    this.tabListener && this.tabListener();
  }

  @HostListener('keyup.enter')
  public onEnter(): void {
    if (this.control && this.control.valid) {
      this.datepicker.hide();
      this.next();
    }
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.datepicker.onHidden.subscribe(() => this.next());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public nativeFocus(): void {
    this.elementRef.nativeElement.focus();
    this.datepicker.show();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractOrderService } from '@shared/common/order/abstract-order.service';
import { IReferUser } from '@shared/models/interfaces';
import { IBonusToken } from '@shared/models/premium/bonus-token.interface';
import { IPremiumVariant } from '@shared/models/premium/premium.variant';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { ICoupon } from './become-premium/become-premium-select-plan/promo-block/coupon.interface';
import { IPremiumBuyItem } from './premium-buy-item.interface';

@Injectable({
  providedIn: 'root'
})
export class PremiumService extends AbstractOrderService {

  private coupon: ICoupon;

  private variantsObservable$: Observable<IPremiumVariant[]>;

  private referralCoupon: Observable<ICoupon>;

  constructor(protected http: HttpClient) {
    super(http, '/premium');
  }

  public sendSuggest(params: { useful: number[], suggestions: string }): Observable<Object> {
    return this.post('/suggest', params);
  }

  public sendInvite(user: IReferUser): Observable<any> {
    return this.post('/send-invite', user);
  }

  public getVariants(): Observable<IPremiumVariant[]> {
    if (!this.variantsObservable$) {
      this.variantsObservable$ = this.get<IPremiumVariant[]>('/variants').pipe(shareReplay());
    }

    return this.variantsObservable$;
  }

  public getVariant(countryIso: string, variantId: string): Observable<IPremiumVariant> {
    return this.get<IPremiumVariant>(`/variant/${variantId}`, {params: {country: countryIso}}).pipe(shareReplay());
  }

  public getCustomer(): Observable<any> {
    return super.getCustomer('/last-customer');
  }

  public listBonusTokens(): Observable<IBonusToken[]> {
    return this.get<IBonusToken[]>('/bonus-tokens');
  }

  public geBonusVariant(): Observable<IPremiumVariant> {
    return this.get<IPremiumVariant>('/bonus-variant');
  }

  public getBuyHistory(): Observable<IPremiumBuyItem[]> {
    return this.get<IPremiumBuyItem[]>('/history');
  }

  public checkCoupon(promoCode: string): Observable<ICoupon> {
    return this.get<ICoupon>('check-coupon', {params: {code: promoCode}})
      .pipe(tap(coupon => this.coupon = coupon));
  }

  public getLastCoupon(): ICoupon {
    return this.coupon;
  }

  public getWirateVariant(variantId?: number | string): Observable<IPremiumVariant> {
    const params = {};
    if (variantId) {
      params['variant_id'] = variantId + '';
    }
    return this.get<IPremiumVariant>('/wirate-variant', {params});
  }

  public getReferralCoupon(): Observable<ICoupon> {
    if (!this.referralCoupon) {
      this.referralCoupon = this.get<ICoupon>('referral-coupon');
    }

    return this.referralCoupon;
  }

  public sendPaymentCancelSurvey(answer: Object)/*: Observable<string>*/ {
    return this.post('/survey', answer);
    // console.log(this.post('/survey', answer));
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'routeUrl'
})
export class RouteUrlPipe implements PipeTransform {

  constructor(private translateService: TranslateService) {
  }

  public transform(value: string): any {
    const searchRegExp = this.getSearchRegExp();
    return value.replace(searchRegExp, '/');
  }

  private getSearchRegExp() {
    return new RegExp(`^(https?:\\/\\/[a-z0-9_\\-\\.]*)?\/${this.translateService.currentLang}\/(u#\/)?`, 'm');
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ICurrency } from '../../models/currency.interface';
import { IOption } from '../../models/option.interface';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'iv-input-with-currency',
  templateUrl: './input-with-currency.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputWithCurrencyComponent),
    multi: true
  }]
})
export class InputWithCurrencyComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {

  private LABEL_FOCUS_CLASS: string = 'focused';

  @Input()
  public label: string = '';

  @Input()
  public isInline: boolean = false;

  @Input()
  public currencies: ICurrency[] = [];

  @Input()
  public items: { [param: number]: IOption[] };

  @Input()
  public required: boolean = false;

  @Input()
  public isOrangeStar: boolean = false;

  @Input()
  public isCurrencyFirst: boolean = false;

  @Input()
  public initCurrencyOnLoad: boolean = true;

  @Input()
  private highlightLabelOnFocus: boolean = false;

  @ViewChild('itemNgSelect')
  public itemNgSelect: NgSelectComponent;

  @ViewChild('currencyNgSelect')
  public currencyNgSelect: NgSelectComponent;

  @ViewChild('inputLabel')
  private labelElement: ElementRef<HTMLLabelElement>;

  public form: FormGroup;

  public onTouched = () => {
  };

  private oldCurrencyId: number | null;

  private subscription: Subscription;

  private onChange = (_: number | null) => {
  };

  public get selectedCurrencyId(): number {
    return this.currencyControl.value;
  }

  private get currencyControl(): FormControl {
    return <FormControl>this.form.get('currency');
  }

  private get itemControl(): FormControl {
    return <FormControl>this.form.get('item');
  }

  constructor(private cd: ChangeDetectorRef,
              private renderer: Renderer2) {
    this.form = new FormGroup({
      item: new FormControl(),
      currency: new FormControl()
    });

    this.subscription = this.currencyControl.valueChanges.subscribe(value => this.onCurrencyChange(value));
    this.subscription.add(this.itemControl.valueChanges.subscribe(value => this.onChange(value)));
  }

  public ngOnInit(): void {
    if (this.initCurrencyOnLoad) {
      this.form.patchValue({currency: this.currencies[0] && this.currencies[0].id}, {emitEvent: false});
      this.form.updateValueAndValidity();
      this.cd.markForCheck();
    }
  }

  public ngAfterViewInit(): void {
    if (this.highlightLabelOnFocus) {
      this.addFocusListener();
      this.addBlurListener();
    }
  }

  public writeValue(value: number | null): void {
    if (!value) {
      return;
    }

    this.itemControl.setValue(value);

    const currencyId = this.getActiveCurrencyByItemId();
    this.currencyControl.setValue(currencyId);
  }

  public registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.currencyControl.disable();
      this.itemControl.disable();
    } else {
      this.currencyControl.enable();
      this.currencyControl.enable();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setItemId(value: number | null): void {
    this.oldCurrencyId = this.currencyControl.value;
    this.itemControl.setValue(value);
    this.onChange(value);
  }

  private onCurrencyChange(currencyId: string): void {
    if (!currencyId) {
      this.setItemId(null);
      return;
    }

    const itemId = this.getItemIdByCurrencyId(currencyId);
    if (this.currencyControl.dirty){
      this.setItemId(itemId);
    }
  }

  private getActiveCurrencyByItemId(): string | null {
    for (let currencyId in this.items) {
      let itemsOptions = this.items[currencyId];
      let result = itemsOptions.some(option => +option.id === +this.itemControl.value);

      if (result) {
        return currencyId;
      }
    }

    return null;
  }

  private getItemIdByCurrencyId(currencyId: string): number | null {
    let currentItemOrder: number;

    if (this.oldCurrencyId && !this.items[this.oldCurrencyId]) {
      return null;
    }

    if (!this.oldCurrencyId) {
      currentItemOrder = this.getMinOrderNumber(currencyId);
    } else {
      const item = this.items[this.oldCurrencyId]
        .find(item => +item.id === +this.itemControl.value);

      currentItemOrder = item ? +item.ord : this.getMinOrderNumber(currencyId);
    }

    return this.items[currencyId].find((option: IOption) => +option.ord === +currentItemOrder).id;
  }

  private getMinOrderNumber(currencyId: string): number {
    return this.items[+currencyId]
      .reduce((min: number, current: any) => +current.ord < +min ? +current.ord : min, +this.items[+currencyId][0].ord);
  }

  private addFocusListener(): void {
    this.subscription.add(
      this.itemNgSelect.focusEvent.subscribe(() => this.addLabelFocusClass())
    );

    this.subscription.add(
      this.currencyNgSelect.focusEvent.subscribe(() => this.addLabelFocusClass())
    );
  }

  private addBlurListener(): void {
    this.subscription.add(
      this.itemNgSelect.blurEvent.subscribe(() => this.removeLabelFocusClass())
    );

    this.subscription.add(
      this.currencyNgSelect.blurEvent.subscribe(() => this.removeLabelFocusClass())
    );
  }

  private addLabelFocusClass(): void {
    this.renderer.addClass(this.labelElement.nativeElement, this.LABEL_FOCUS_CLASS);
  }

  private removeLabelFocusClass(): void {
    this.renderer.removeClass(this.labelElement.nativeElement, this.LABEL_FOCUS_CLASS);
  }

}

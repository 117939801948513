import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';
import { FlashService } from '../services/flash.service';

@Injectable({
  providedIn: 'root'
})
export class IvHttpInterceptor implements HttpInterceptor {

  constructor(private flash: FlashService,
              private translateService: TranslateService,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = req.clone({
      headers: req.headers.set('X-Requested-With', 'XMLHttpRequest')
    });

    return next.handle(newReq)
      .pipe(
        catchError(err => {
          if (err && err.status === 401) {
            this.router.navigate(['/home']).then(() => window.location.reload());
            return throwError(err);
          }

          if (err.error && err.error.text) {
            this.flash.danger(err.error.text.length < 256 ? err.error.text : this.translateService.instant('you_found_bug'));
          }
          return throwError(err);
        })
      );
  }
}

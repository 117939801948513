import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { CollapseModule } from 'ngx-bootstrap';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AlertTitleComponent } from './alert-title/alert-title.component';
import { AlertComponent } from './alert/alert.component';
import { CancellationWirateComponent } from './cancellation-wirate/cancellation-wirate.component';
import { CloseAllNeedsComponent } from './close-all-needs/close-all-needs.component';
import { ConfirmReasonComponent } from './confirm-reason/confirm-reason.component';
import { ConfirmTitleComponent } from './confirm-title/confirm-title.component';
import { UserRequestComponent } from './contact/contact-requests/user-request/user-request.component';
import { CropComponent } from './crop/crop.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { PremiumInfoComponent } from './premium-info/premium-info.component';
import { RemoveProfileComponent } from './remove-profile/remove-profile.component';
import { RejectComponent } from './request/reject/reject.component';
import { AlertLicenceComponent } from '@shared/modals/alert-licence/alert-licence.component';
import { ChooseFileComponent } from './choose-file/choose-file.component';
import { CroppedPhotoUploaderDirective } from '@shared/directives/cropped-photo-uploader/cropped-photo-uploader.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ImageCropperModule,
    Angulartics2Module,
    CollapseModule
  ],
  declarations: [
    AlertTitleComponent,
    PremiumInfoComponent,
    AlertComponent,
    ConfirmTitleComponent,
    RemoveProfileComponent,
    CropComponent,
    UserRequestComponent,
    RejectComponent,
    ConfirmReasonComponent,
    DownloadAppComponent,
    CancellationWirateComponent,
    CloseAllNeedsComponent,
    AlertLicenceComponent,
    ChooseFileComponent,

    CroppedPhotoUploaderDirective
  ],
  entryComponents: [
    AlertTitleComponent,
    ConfirmTitleComponent,
    AlertComponent,
    RemoveProfileComponent,
    CropComponent,
    PremiumInfoComponent,
    UserRequestComponent,
    RejectComponent,
    DownloadAppComponent,
    CloseAllNeedsComponent,
    CancellationWirateComponent,
    ConfirmReasonComponent,
    AlertLicenceComponent,
    ChooseFileComponent
  ],
  exports: [
    CroppedPhotoUploaderDirective
  ]
})
export class ModalsModule {
}

import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { AsyncSubject } from 'rxjs/AsyncSubject';
import { Observable } from 'rxjs/Observable';
import { concatMap } from 'rxjs/operators';
import { ModalsService } from '../../modals/modals.service';
import { FlashService } from '../../services/flash.service';
import { ICropImageData } from './crop-image-data.interface';

@Directive({
  selector: '[ivCroppedPhotoUploader]'
})
export class CroppedPhotoUploaderDirective {

  private readonly IMAGE_SIZE_TOO_BIG_TITLE: string = '_modal_inform_image_size_too_big';

  private readonly IMAGE_SIZE_TOO_SMALL_TITLE: string = '_modal_inform_image_too_small';

  private readonly IMAGE_SIZE_ERROR_DESCRIPTION: string = '_modal_inform_image_too_small_text';

  @Output('ivCroppedPhotoUploaderFileAdd')
  public fileAdded: EventEmitter<ICropImageData> = new EventEmitter<ICropImageData>();

  @Output('ivCroppedPhotoUploaderFileError')
  public error: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  private maxSizeMb: number = 3;

  @Input()
  private minWidth: number = 300;

  @Input()
  private minHeight: number = 300;

  @Input()
  private aspectRatio: number = 1;

  constructor(private element: ElementRef,
              private flash: FlashService,
              private modalsService: ModalsService,) {
  }

  @HostListener('change')
  public onFileChange(): any {
    let files = this.element.nativeElement.files;

    if (files && files[0]) {
      this.addFile(files[0]);
      this.element.nativeElement.value = '';
    }

  }

  public addFile(file: File) {
    this.validateFile(file)
      .pipe(concatMap(src => this.modalsService.openCrop(src, this.minWidth, this.aspectRatio)))
      .subscribe((data: ICropImageData) => {
          data.image = file;
          this.fileAdded.emit(data);
        },
        err => err && err.error && err.error.text && this.error.emit(err.error.text)
      )
  }

  private validateFile(file: File): Observable<string> {
    const subject = new AsyncSubject<string>();
    const subject$ = subject.asObservable();
    const pattern = /image-*/;
    const reader = new FileReader();
    const image = new Image();

    if (!file.type.match(pattern)) {
      this.flash.danger('bad_image_format');
      subject.error(new Error('Bad image format'));
      return subject$;
    }

    if (file.size > 1024 * 1024 * this.maxSizeMb) {
      this.modalsService.openAlertTitle(this.IMAGE_SIZE_TOO_BIG_TITLE, this.IMAGE_SIZE_ERROR_DESCRIPTION, {
        width: this.minWidth,
        height: this.minHeight,
        maxSize: this.maxSizeMb
      });
      subject.error(new Error('Image size too big'));
      return subject$;
    }

    image.onload = () => {
      if (image.width < this.minWidth || image.height < this.minHeight) {
        this.modalsService.openAlertTitle(this.IMAGE_SIZE_TOO_SMALL_TITLE, this.IMAGE_SIZE_ERROR_DESCRIPTION, {
          width: this.minWidth,
          height: this.minHeight,
          maxSize: this.maxSizeMb
        });
        subject.error(new Error('Image size too big'));
      } else {
        subject.next(image.src);
        subject.complete();
      }
    };

    reader.onload = () => {
      image.src = reader.result;
    };

    reader.readAsDataURL(file);

    return subject$;
  }

}

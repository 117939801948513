import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[ivCheckboxValues]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxValuesDirective),
      multi: true
    }
  ]
})
export class CheckboxValuesDirective implements ControlValueAccessor {

  @Input('ivCheckboxValuesTrue')
  public trueValue: any = true;

  @Input('ivCheckboxValuesFalse')
  public falseValue: any = false;

  private onChange = (_: any) => {
  };

  private onTouched = () => {
  };

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  @HostListener('change', ['$event'])
  onHostChange(ev: any) {
    this.onChange(ev.target.checked ? this.trueValue : this.falseValue);
  }

  @HostListener('blur')
  onHostTouch() {
    this.onTouched();
  }

  writeValue(obj: any): void {
    if (obj === this.trueValue) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'checked', true);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'checked', false);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

}

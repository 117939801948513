import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'iv-list-pagination-buttons-wrapper',
  templateUrl: './list-pagination-buttons-wrapper.component.html',
  styles: [':host { display: block }'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPaginationButtonsWrapperComponent {

  public readonly ITEMS_PER_PAGE: number = 20;

  public readonly MAX_PAGINATION_SIZE: number = 10;

  public get isInit(): boolean {
    return this.total !== null;
  }

  @Input()
  public total: number | null = null;

  @Input()
  public disabled: boolean = false;

  @Input()
  public itemsPerPage: number = this.ITEMS_PER_PAGE;

  @Input()
  public maxSize: number = this.MAX_PAGINATION_SIZE;

  @Input()
  public page: number | null = null;

  @Output()
  public pageChange: EventEmitter<number> = new EventEmitter<number>();

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FlashMessagesService } from 'ngx-flash-messages';
import { IFlashMessage } from 'ngx-flash-messages/lib-dist/flash-messages.service';

@Component({
  selector: 'iv-flash-message',
  templateUrl: './flash-message.component.html'
})
export class FlashMessageComponent implements OnInit {

  public messages: IFlashMessage[] = [];

  constructor(private flashMessagesService: FlashMessagesService,
              private cd: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.flashMessagesService.message.subscribe(message => this.handleMessage(message));
  }

  private handleMessage(message: IFlashMessage): void {
    const defaultOpts: any = {
      classes: [],
      timeout: 3000
    };

    message.options = Object.assign(defaultOpts, message.options);

    const timestamp = message.timestamp = +(new Date());
    this.messages.push(message);

    setTimeout(() => {
      this.messages = this.messages.filter(msg => msg.timestamp !== timestamp);
      this.cd.detectChanges();
    }, message.options!.timeout || defaultOpts.timeout);
  }

}

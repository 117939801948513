/// <reference path="../../../../node_modules/@types/googlemaps/index.d.ts" />

import { MapsAPILoader } from '@agm/core';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILocation } from '../models/location.interface';
import Autocomplete = google.maps.places.Autocomplete;
import PlaceResult = google.maps.places.PlaceResult;

@Directive({
  selector: '[ivGooglePlacesAutocomplete]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GooglePlacesAutocompleteDirective),
      multi: true
    }
  ]
})
export class GooglePlacesAutocompleteDirective implements OnInit, ControlValueAccessor {

  @Input('ivGooglePlacesAutocompleteRaw')
  public isRawData: boolean = false;

  @Input('ivGooglePlacesAutocompleteTypes')
  public types: string[] = ['(cities)'];

  @Output('ivGooglePlacesAutocompleteSelected')
  public changeEvent: EventEmitter<PlaceResult | ILocation | null> = new EventEmitter();

  private onChange = (_: any): void => void {};

  private onTouched = (_: PlaceResult | ILocation | null): void => void {};

  private autocomplete: Autocomplete;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2,
              private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone,
              private cd: ChangeDetectorRef) {
  }

  private static formatter(location: ILocation): string {
    let formattedValue = '';

    if (location !== null && typeof location === 'object') {
      if (location.formatted_address) {
        formattedValue = location.formatted_address;
      } else {
        formattedValue = GooglePlacesAutocompleteDirective.getFormattedAddress(location);
      }
    }

    return formattedValue;
  }

  private static getFormattedAddress(location: ILocation): string {
    let address = [location.city, location.region, location.country, location.zip].filter(i => i);

    return address.join(address.length > 2 ? ',' : '');
  }

  private static parseGoogleObject(place: PlaceResult): ILocation | null {
    let location: ILocation = <ILocation>{};

    if (Array.isArray(place.address_components)) {
      place.address_components.forEach(addressComponent => {
        let types = addressComponent.types;

        if (~types.indexOf('locality')) {
          location.city = addressComponent.long_name;
          location.city_short = addressComponent.short_name;
        } else if (~types.indexOf('administrative_area_level_1')) {
          location.region = addressComponent.long_name;
          location.region_short = addressComponent.short_name;
        } else if (~types.indexOf('country')) {
          location.country = addressComponent.long_name;
          location.country_iso = addressComponent.short_name;
        } else if (~types.indexOf('postal_code')) {
          location.zip = +addressComponent.long_name;
        } else if (~types.indexOf('administrative_area_level_2')) {
          location.second_area = addressComponent.long_name;
        }
      });
    }

    if (place.formatted_address) {
      location.formatted_address = place.formatted_address;
    }

    if (place.geometry) {
      if (typeof place.geometry.location.lat == 'function' && typeof place.geometry.location.lng == 'function') {
        location.latitude = place.geometry.location.lat();
        location.longitude = place.geometry.location.lng();
      }
    }

    return Object.keys(location).length === 0 ? null : location;
  }

  @HostListener('input', ['$event.target.value'])
  public onInput(value: string): void {
    this.onChange(value);
  }

  public ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.autocomplete = new google.maps.places.Autocomplete(this.elementRef.nativeElement, <any>{
        types: this.types,
        fields: ['address_components', 'formatted_address', 'geometry.location', 'types']
      });

      this.autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          let place: PlaceResult = this.autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          const outputPlace = this.isRawData ? place : GooglePlacesAutocompleteDirective.parseGoogleObject(place);

          this.onChange(outputPlace);
          this.onTouched(outputPlace);
          this.cd.markForCheck();
        });
      });
    });
  }

  public writeValue(obj: string | ILocation): void {
    if (obj === null) {
      return;
    }

    if (typeof obj === 'string') {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', obj);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', GooglePlacesAutocompleteDirective.formatter(obj));
    }
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = (place: PlaceResult | ILocation | null) => {
      this.changeEvent.emit(place);
      fn();
    };
  }

  public setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
import { ChangeDetectorRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { FlashService } from '@shared/services/flash.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { concatMap, finalize, take, tap } from 'rxjs/operators';
import { AbstractFormComponent } from '../../../common-forms/abstract-form.component';
import { ProfileStore } from '../../../profile/profile.store';
import { IndexService } from '../../api/index.service';
import { PaymentMethod } from '../../models/authenticated-profile/payment-method.enum';
import { ICurrencyPrice, IVerificationCustomer } from '../../models/interfaces';
import { AbstractOrderService } from './abstract-order.service';

export abstract class AbstractOrderForm extends AbstractFormComponent<any> implements OnInit, OnDestroy {

  protected abstract paymentService: AbstractOrderService;

  protected abstract getCurrencyPrice(countryIso: string): Observable<ICurrencyPrice>;

  @Input()
  public variantId: string = '';

  public customer: IVerificationCustomer;

  public profile: AuthenticatedProfileTypes;

  public currencyPrice: ICurrencyPrice;

  public isEuCountry: boolean = false;

  public priceLoading: boolean = false;

  public isCurrencyPriceLoading: boolean = false;

  protected subscription: Subscription;

  protected constructor(protected flash: FlashService,
                        protected cd: ChangeDetectorRef,
                        protected fb: FormBuilder,
                        protected profileStore: ProfileStore,
                        protected indexService: IndexService) {
    super(flash, cd, fb);
  }

  public ngOnInit(): void {
    this.subscription = forkJoin(
      this.profileStore.authenticatedProfile$.pipe(
        take(1),
        tap(profile => this.profile = profile),
        tap(() => this.isCurrencyPriceLoading = true),
        concatMap(() => this.getCurrencyPrice(this.profile.countryIso))
      ),
      this.paymentService.getCustomer(),
      this.indexService.getEuCountries(),
    )
      .pipe(
        finalize(() => {
          this.isCurrencyPriceLoading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe(([currencyPrice, customer, euCountries]) => {
        this.customer = customer;
        this.isEuCountry = !!~euCountries.indexOf(this.profile.countryIso);
        this.currencyPrice = currencyPrice;
      });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onCountryChange(countryIso: string): void {
    this.priceLoading = true;

    forkJoin(
      this.profileStore.getVatRate(countryIso),
      this.getCurrencyPrice(countryIso)
    )
      .pipe(
        finalize(() => {
          this.priceLoading = false;
          this.cd.markForCheck();
        })
      )
      .subscribe(([vatRate, currencyPrice]) => {
        this.customer.vat_rate = vatRate;
        this.currencyPrice = currencyPrice;
      });
  }

  protected getPaymentRequest(data: any = {}): Observable<any> | null {
    let request$: Observable<any> | null = null;

    data.payment_type = this.form.value.payment_type;

    switch (data.payment_type) {
      case PaymentMethod.Free: {
        request$ = this.paymentService.payFree(data, this.variantId);
        break;
      }

      case PaymentMethod.PAYPAL: {
        request$ = this.paymentService.payPaypal(data, this.variantId);
        break;
      }

      case PaymentMethod.BITPAY: {
        request$ = this.paymentService.payBitpay(data, this.variantId);
        break;
      }

      case PaymentMethod.VISA_MASTERCARD: {
        // request$ = this.paymentService.payPayline(data, this.variantId);
        request$ = this.paymentService.payMollie(data, this.variantId);
        break;
      }
    }

    return request$;
  }

  protected createForm(): FormGroup {
    return this.fb.group({
      payment_type: [PaymentMethod.PAYPAL, Validators.required]
    });
  }
}

import { Component, Input } from '@angular/core';
import { AuthenticatedUserProfile } from '@shared/models/authenticated-profile/authenticated-user-profile.model';

@Component({
  selector: 'iv-user-email-block',
  templateUrl: './user-email-block.component.html',
  styles: [':host {display: block}']
})
export class UserEmailBlockComponent {

  @Input()
  public profile: AuthenticatedUserProfile;

}

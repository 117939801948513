import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import {Router} from '@angular/router';

@Injectable()
export class NfResolver implements Resolve<any> {
  constructor(private router: Router) {
    if(window.location.href.match('remove-profile')){
      this.router.navigate(['/home']);
    }
  }

  resolve() {

  }
}

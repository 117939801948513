import { Injectable } from '@angular/core';
import { UsersService } from '@shared/api/users.service';
import { UtilsHelper } from '@shared/helpers/utils.helper';
import { FlashService } from '@shared/services/flash.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { AlertTitleComponent } from './alert-title/alert-title.component';
import { AlertComponent } from './alert/alert.component';
import { CancellationWirateComponent } from './cancellation-wirate/cancellation-wirate.component';
import { CloseAllNeedsComponent } from './close-all-needs/close-all-needs.component';
import { ConfirmReasonComponent } from './confirm-reason/confirm-reason.component';
import { IRemoveReason } from './confirm-reason/remove-reason.interface';
import { ConfirmTitleComponent } from './confirm-title/confirm-title.component';
import { UserRequestComponent } from './contact/contact-requests/user-request/user-request.component';
import { ICropData } from './crop/crop-data.interface';
import { CropComponent } from './crop/crop.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { PremiumInfoComponent } from './premium-info/premium-info.component';
import { RemoveProfileComponent } from './remove-profile/remove-profile.component';
import { RejectComponent } from './request/reject/reject.component';
import { AlertLicenceComponent } from '@shared/modals/alert-licence/alert-licence.component';
import { ICoupon } from '../../premium/become-premium/become-premium-select-plan/promo-block/coupon.interface';
import { ChooseFileComponent } from '@shared/modals/choose-file/choose-file.component';
import { ICropImageData } from '@shared/directives/cropped-photo-uploader/crop-image-data.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalsService {

  private currentModalRef: BsModalRef;

  constructor(private bsModalService: BsModalService,
              private userService: UsersService,
              private flash: FlashService) {
  }

  public closeCurrentModal(): void {
    this.currentModalRef && this.currentModalRef.hide();
  }

  public openAlertTitle(title: string, description: string, values: any = {_hideClose: false}, options?: ModalOptions): Observable<any> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': 'modal-md alert-big-title',
      keyboard: !values._hideClose,
      initialState: {title, description, values}
    }, options || {});


    const bsModalRef = this.bsModalService.show(AlertTitleComponent, modalOpts);
    return bsModalRef.content.result$;
  }

  public openConfirmTitle(title: string,
                          description: string,
                          values: any = {},
                          cancelOptions: {
                            name: string;
                            analitycsAction?: string;
                          } = {name: 'cancel'},
                          confirmOptions: {
                            name: string;
                            analitycsAction?: string;
                          } = {name: 'confirm'},
                          options?: ModalOptions,
  ): Observable<boolean> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': 'modal-md alert-big-title',
      keyboard: !values._hideClose,
      initialState: {title, description, values, cancelOptions, confirmOptions}
    }, options || {});

    const bsModalRef = this.bsModalService.show(ConfirmTitleComponent, modalOpts);
    return bsModalRef.content.result$;

  }

  public openAlert(description: string, values: any = {}, options?: ModalOptions): Observable<any> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': 'modal-md',
      keyboard: !values._hideClose,
      initialState: {description, values}
    }, options || {});


    const bsModalRef = this.bsModalService.show(AlertComponent, modalOpts);
    return bsModalRef.content.result$;
  }

  public openDownloadApp(options?: ModalOptions): Observable<any> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': 'modal-sm',
      keyboard: true
    }, options || {});

    const bsModalRef = this.bsModalService.show(DownloadAppComponent, modalOpts);
    return bsModalRef.content.result$;
  }

  public openPremiumInfo(title: string, description: string, options?: ModalOptions): Observable<any> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': 'modal-md clear-wrap',
      initialState: {title, description}
    }, options || {});


    const bsModalRef = this.bsModalService.show(PremiumInfoComponent, modalOpts);
    return bsModalRef.content.result$;
  }

  public openRemoveProfile(options?: ModalOptions): Observable<any> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': 'modal-md iv-modal-dialog modal-reason'
    }, options || {});

    const bsModalRef = this.bsModalService.show(RemoveProfileComponent, modalOpts);
    return bsModalRef.content.result$;
  }

  public openCrop(imageSrc: string, size: number = 300, aspectRatio: number = 1, options?: ModalOptions): Observable<ICropData> {
    const modalOpts: ModalOptions = <ModalOptions>Object.assign({
      'class': 'modal-md',
      initialState: {imageSrc, size, aspectRatio}
    }, options || {});


    const bsModalRef = this.bsModalService.show(CropComponent, modalOpts);
    return bsModalRef.content.result$;
  }

  public openContactRequest(contact: any, profile: any, request?: any): Observable<any> {
    let modalInputs: any = {contact, profile, request};

    modalInputs.cancelOptions = {name: 'cancel'};
    modalInputs.confirmOptions = {name: 'confirm'};

    const modalOptions: ModalOptions = {
      class: 'iv-modal-dialog modal-request modal-sm',
      initialState: modalInputs
    };

    this.currentModalRef = this.bsModalService.show(UserRequestComponent, modalOptions);

    return this.currentModalRef.content.result$;
  }

  public openContactConfirmDialog(modalInputs: any): Observable<any> {
    modalInputs.cancelOptions = {name: 'refuse'};
    modalInputs.confirmOptions = {name: 'accept'};

    const modalOptions: ModalOptions = {
      class: 'iv-modal-dialog modal-request modal-sm',
      initialState: modalInputs
    };

    this.currentModalRef = this.bsModalService.show(UserRequestComponent, modalOptions);

    return this.currentModalRef.content.result$;
  }

  public openRejectDialog(modalInputs: any): Observable<any> {
    const modalOptions: ModalOptions = {
      class: 'iv-modal-dialog modal-request modal-sm',
      initialState: modalInputs
    };

    this.currentModalRef = this.bsModalService.show(RejectComponent, modalOptions);

    return this.currentModalRef.content.result$;
  }

  public openConfirmDeleteNeedDialog(): Observable<IRemoveReason> {
    const modalInputs = {
      title: '_manage_need._modal._delete_reason.title',
      description: '_manage_need._modal._delete_reason.description',
      deleteText: '_manage_need._modal._delete_reason.delete',
      analitycsAction: 'Ok reason delete need',
      variants: [
        {
          value: 1,
          title: '_manage_need._modal._delete_reason._variant_1'
        },
        {
          value: 2,
          title: '_manage_need._modal._delete_reason._variant_2'
        },
        {
          value: 3,
          title: '_manage_need._modal._delete_reason._variant_3',
        },
        {
          value: 4,
          title: '_manage_need._modal._delete_reason._variant_4',
          isTextarea: true
        }
      ]
    };

    return this.openConfirmDeleteDialog(modalInputs);
  }

  public openConfirmDeleteProjectDialog(): Observable<IRemoveReason> {
    const totalVariants = 7;

    let variants = UtilsHelper.getEmptyArray(totalVariants, i => ({
      value: i + 1,
      title: `_manage_project._modal._delete_reason._variant_${i + 1}`,
      isTextarea: i + 1 === 7
    }));

    const modalInputs = {
      title: '_manage_project._modal._delete_reason.title',
      description: '_manage_project._modal._delete_reason.description',
      deleteText: '_manage_project._modal._delete_reason.delete',
      analitycsAction: 'Ok reason delete project',
      variants
    };

    return this.openConfirmDeleteDialog(modalInputs);
  }

  public openResendEmailDialog(): void {
    const title = '_email_confirm_modal.title';
    const description = '_email_confirm_modal.desc';

    const modalOptions = {
      class: 'modal-lg alert-big-title modal-centered-button',
    };

    this.openAlertTitle(title, description, {buttonText: '_email_confirm_modal.resend'}, modalOptions)
      .subscribe(response => response !== 'close' && this.userService.resendEmailConfirm()
        .subscribe(res => this.flash.success(res.text))
      );
  }

  public openCancellationWirate(): Observable<number | boolean> {
    const modalOptions: ModalOptions = {
      class: 'premium-again for-wirate modal-md',
      keyboard: true,
      backdrop: 'static'
    };

    this.currentModalRef = this.bsModalService.show(CancellationWirateComponent, modalOptions);

    return this.currentModalRef.content.result$;
  }

  public openCloseAllNeeds(projectId: number, needId: number): Observable<number> {
    const modalOptions: ModalOptions = {
      class: 'modal-sm alert-big-title',
      keyboard: true,
      backdrop: 'static',
      initialState: {projectId, needId}
    };

    this.currentModalRef = this.bsModalService.show(CloseAllNeedsComponent, modalOptions);
    return this.currentModalRef.content.result$;
  }

  public openNoProfilePhoto(callback: () => void): Observable<boolean> {
    const title = '_no_photo_modal_title';
    const description = '_no_photo_modal_desc';

    const cancelButton = {
      name: '_later',
      analitycsAction: 'Photo Fill Profile click Later'
    };

    const confirmButton = {
      name: '_upload_photo',
      analitycsAction: 'Photo Fill Profile Upload'
    };

    return this.openConfirmTitle(
      title,
      description,
      {callback},
      cancelButton,
      confirmButton
    );
  }

  public openNoNeeds(): Observable<boolean> {
    const title = '_fill_needs.title';
    const description = '_fill_needs.desc';

    const cancelButton = {
      name: '_fill_needs.ignore',
      analitycsAction: 'Need Fill Needs click Ignore',
    };

    const confirmButton = {
      name: '_fill_needs.start',
      analitycsAction: 'Need Fill Needs click My Needs'
    };

    return this.openConfirmTitle(
      title,
      description,
      {},
      cancelButton,
      confirmButton
    );
  }

  public openAlertLicence(content: string, licenceText: string): Observable<boolean> {
    this.currentModalRef = this.bsModalService.show(AlertLicenceComponent, {
      initialState: {content, licenceText},
      'class': 'modal-lg'
    });
    return this.currentModalRef.content.result$;
  }

  public showNewFranceInfo(isPartner: boolean): void {
    const title = '_modal_inform_first_time_title';
    let desc = '_modal_inform_first_time_e_desc';
    let additional = '_modal_inform_first_time_e_additional';

    if (isPartner) {
      desc = '_modal_inform_first_time_p_desc';
      additional = '_modal_inform_first_time_p_additional';
    }

    this.openAlertTitle(title, desc, {additional}, {'class': 'modal-lg alert-big-title'});
  }

  public openRedeemDistountAlertTitle(coupon: ICoupon): void {
    const title = 'redeem_discount.title';
    const description = 'redeem_discount.description';
    const discount = coupon.options.percent;
    const months = coupon.options.months || 2;
    const promoCode = coupon.code;
    const buttonText = 'ok';

    this.openAlertTitle(
      title,
      description,
      {discount, months, buttonText, promoCode},
      {'class': 'modal-md alert-big-title modal-centered-button'}
    );
  }

  public openChooseFileModal(title: string,
                             description: string,
                             helpMessage: string,
                             logoSrc: string,
                             width: number,
                             height: number,
                             aspectRatio: number,
                             options: ModalOptions): Observable<false | ICropImageData> {
    const modalOpts: ModalOptions = <ModalOptions>{
      'class': `modal-md alert-big-title modal-centered-text modal-centered-button ${options.class}`,
      initialState: {title, description, helpMessage, logoSrc, width, height, aspectRatio}
    };


    const bsModalRef = this.bsModalService.show(ChooseFileComponent, modalOpts);
    return bsModalRef.content.result$;
  }

  private openConfirmDeleteDialog(modalInputs: any): Observable<IRemoveReason> {
    const modalOptions = {
      class: 'iv-modal-dialog modal-reason modal-md',
      initialState: modalInputs
    };

    this.currentModalRef = this.bsModalService.show(ConfirmReasonComponent, modalOptions);

    return this.currentModalRef.content.result$;
  }
}

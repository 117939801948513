import { AccountActive } from '@shared/enums/account/active.enum';
import { PartnershipStatus } from '@shared/enums/account/partnership/status.enum';
import { UserStatus } from '@shared/enums/account/user/status.enum';
import { AccountTypeModel } from '@shared/models/account/account-type.model';
import { ProfileInfoBase } from '@shared/models/account/profile/profile-info-base.model';
import { IReview } from '@shared/models/account/profile/review.interface';
import { ISocialNetwork } from '@shared/models/account/social-network.interface';
import { PremiumPayment } from '@shared/models/authenticated-profile/premium-payment.interface';
import { ICurrency } from '@shared/models/currency.interface';
import { VerificationStatus } from '../../../profile/verification/verification-status.enum';

export class AuthenticatedProfile extends AccountTypeModel {

  public email: string;

  public active: AccountActive;

  public lang: 'en' | 'fr';

  public social: ISocialNetwork;

  public registered: Date | null;

  public photo: string;

  public hasPhoto: boolean;

  public locationCurrency: ICurrency;

  public status: UserStatus | PartnershipStatus;

  public country_iso?: string;

  /** USER **/

  public premium?: boolean;

  public name?: string;

  public dob?: Date;

  public isCompleteProfile?: boolean;

  public isCompleteExtra?: boolean;

  public isOldComplete?: boolean;

  public first_name?: string;

  public last_name?: string;

  public has_verified?: boolean;

  public has_old_photo?: boolean;

  public review_info?: IReview;

  public shouldWiratePremium?: boolean;

  public has_bonus?: boolean;

  public has_verification_payment?: boolean;

  public verification_status?: VerificationStatus;

  public premiumInfoLast?: { date_begin: Date, date_end: Date, payment: PremiumPayment };

  /** PARTNER USER **/

  public isCompleteWishes?: boolean;

  public isReferred: boolean;

  /** ENTREPRENEUR USER **/

  public countProject?: number;

  public countProjectNeed?: number;

  /** PARTNERSHIP **/

  public organization?: string;

  public get isActive(): boolean {
    return this.active === AccountActive.On;
  }

  public get countryIso(): string {
    return this.country_iso || '';
  }

  public get currencySymbol(): string {
    return this.locationCurrency.symbol;
  }

  public get isComplete(): boolean {
    return this.status === UserStatus.Complete; // isCompleteProfile && isCompleteExtra
  }

  public get isEmailConfirmed(): boolean {
    return this.status === UserStatus.Checked || this.status === UserStatus.Complete;
  }

  public get isNew(): boolean {
    return this.status === UserStatus.New;
  }

  public get isPremium(): boolean {
    return !!this.premium;
  }

  public get firstName(): string {
    return this.first_name || '';
  }

  public get lastName(): string {
    return this.last_name || '';
  }

  public get isVerified(): boolean {
    return !!this.has_verified;
  }

  public get isOldPhoto(): boolean {
    return !!this.has_old_photo;
  }

  public get hasBonus(): boolean {
    return !!this.has_bonus;
  }

  public get hasVerificationPayment(): boolean {
    return !!this.has_verification_payment;
  }

  public get verificationStatus(): VerificationStatus | undefined {
    return this.verification_status;
  }

  public get reviewInfo(): IReview | undefined {
    return this.review_info;
  }

  public get isCompleteProject(): boolean {
    return this.countProject !== 0;
  }

  public get isCompleteProjectNeed(): boolean {
    return this.countProjectNeed !== 0;
  }

  public get isPublishedGroup(): boolean {
    return this.status ? this.status === PartnershipStatus.Public : true;
  }

  public get isActiveGroup(): boolean {
    return this.status ? this.status === PartnershipStatus.Active : true;
  }

  public get isVerificationStatusCancel(): boolean {
    return this.verificationStatusEqual(VerificationStatus.CANCEL);
  }

  public get isVerificationStatusPending(): boolean {
    return this.verificationStatusEqual(VerificationStatus.PENDING);
  }

  public get isVerificationStatusRefuse(): boolean {
    return this.verificationStatusEqual(VerificationStatus.REFUSE);
  }

  constructor(profile: AuthenticatedProfile) {
    super(profile);
    this.registered = this.registered ? new Date(this.registered) : null;

    /** USER **/
    this.dob = this.dob ? new Date(this.dob) : undefined;

    if (this.isPremium && this.premiumInfoLast) {
      this.premiumInfoLast.date_begin = new Date(this.premiumInfoLast.date_begin);
      this.premiumInfoLast.date_end = new Date(this.premiumInfoLast.date_end);
      this.premiumInfoLast.payment.date = new Date(this.premiumInfoLast.payment.date);
    }
  }

  public verificationStatusEqual(status: VerificationStatus): boolean {
    return this.hasVerificationPayment && this.verificationStatus === status;
  }

  public isLang(lang: string): boolean {
    return this.lang === lang;
  }

  public canRequest(profile: ProfileInfoBase) {
    if (profile.removed || profile.banned) {
      return false;
    }

    if ((this.isPartnership && !this.isInvestor) && profile.isPartnership) {
      return false;
    }
    return profile.noContact;
  }

}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthenticatedUserProfile } from '@shared/models/authenticated-profile/authenticated-user-profile.model';

@Component({
  selector: 'iv-user-photo-block',
  templateUrl: './user-photo-block.component.html',
  styles: [':host {display: block}']
})
export class UserPhotoBlockComponent {

  @Input()
  public checkValid = false;

  @Input()
  public updatePhoto = false;

  @Input()
  public preview = '';

  @Input()
  public profile: AuthenticatedUserProfile;

  @Output()
  public photoUploaded: EventEmitter<void> = new EventEmitter();

}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'iv-empty-list',
  templateUrl: './empty-list.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListComponent {

  @Input()
  public data: {
    text: string;
    link?: string;
    title?: string;
  } = {
    text: '',
    link: '',
    title: ''
  };

}

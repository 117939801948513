import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPagination } from '@shared/components/pagination/pagination.interface';
import { ProfileInfoBase } from '@shared/models/account/profile/profile-info-base.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ICropImageData } from '../directives/cropped-photo-uploader/crop-image-data.interface';
import { IResponseOk } from '../models/api-response/response-ok.interface';
import { FileUploaderService } from '../services/file-uploader.service';
import { BaseApiService } from './base-api-service';

export interface IRegistration {
  connector?: number;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  inviteCode: string;
  confirmPassword: string;
  reCaptchaResponse?: string;
  type: number;
}

export interface IRegistrationResponse {
  code: string;
  redirect_url: string;
  text: string;
}

export interface IUpdatePhotoResponse {
  percentMe: number;
  percentProfile: number;
  text: string;
  url: string;
  preview_url: string;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService extends BaseApiService {
  partnerUrlPart = '/partner';

  constructor(protected http: HttpClient,
              private fileUploader: FileUploaderService) {
    super(http, '/user');
  }

  public listFrontPartner(options: { sort?: string, size?: number }): Observable<IPagination<ProfileInfoBase>> {
    const {sort = 'id', size = 7} = options;
    return this.get<IPagination<ProfileInfoBase>>('list-frontend', {
      otherUrlPart: this.partnerUrlPart,
      params: {size: size.toString(), sort}
    }).pipe(
      tap(response => {
        response.items = response.items.map(item => new ProfileInfoBase(item));
      })
    );
  }

  public listFrontConnectorGroup(): Observable<IPagination<ProfileInfoBase>> {
    return this.get<IPagination<ProfileInfoBase>>(`/front/connector/group`, {
      isOld: true,
      otherUrlPart: this.partnerUrlPart
    }).pipe(
      tap(response => response.items = response.items.map(item => new ProfileInfoBase(item)))
    );
  }

  public registration(data: IRegistration): Observable<IRegistrationResponse> {
    return this.post<IRegistrationResponse>('/registration', data);
  }

  public login(data: any): Observable<any> {
    return this.post<any>('/login', data);
  }

  public logout(): Observable<any> {
    return this.get<any>('/logout');
  }

  public restorePassword(data: any): Observable<any> {
    return this.post<any>('/restore-password', data);
  }

  public isDeleted(user: { id: number }): Observable<{ removed: boolean, type: number }> {
    return this.get<{ removed: boolean, type: number }>(`/${user.id}/is-removed`);
  }

  public hasBanned(user: { id: number }): Observable<{ banned: boolean }> {
    return this.get<{ banned: boolean }>(`/${user.id}/has-banned`);
  }

  public requestRemove(data: { reason: number, reason_desc?: string }): Observable<{ text: string }> {
    return this.post<{ text: string }>('/request-remove', data);
  }

  public updatePhoto(data: ICropImageData): Observable<IUpdatePhotoResponse> {
    return this.fileUploader.upload<IUpdatePhotoResponse>(data, this.getApiUrl('update_photo'));
  }

  public getCounters<T extends any>(counters: any = {}): Observable<T> {
    return this.get<T>('/counters', {params: {counters: JSON.stringify(counters)}});
  }

  public createAfrica(data: any): Observable<any> {
    return this.post<any>('create', data, {otherUrlPart: 'africa'});
  }

  public resendEmailConfirm(): Observable<IResponseOk> {
    return this.get<IResponseOk>('resend-email-confirm');
  }

  public confirmEmail(verificationCode: string): Observable<any> {
    return this.post<any>('confirm-email', {verificationCode});
  }
}

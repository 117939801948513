import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'textarea[autosize]'
})
export class TextareaAutosizeDirective implements OnInit {

  @Input('autoresize')
  public maxHeight: number;

  constructor(public element: ElementRef) {
  }

  ngOnInit(): void {
    this.adjust();
  }

  @HostListener('input')
  public onInput(): void {
    this.adjust();
  }

  private adjust(): void {
    let ta = this.element.nativeElement,
      newHeight;

    if (ta) {
      ta.style.overflow = 'hidden';
      ta.style.height = 'auto';
      if (this.maxHeight) {
        newHeight = Math.min(ta.scrollHeight, this.maxHeight);
      } else {
        newHeight = ta.scrollHeight;
      }
      ta.style.height = newHeight + 'px';
    }
  }

}
import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormHelper } from '@shared/helpers/form.helper';
import { Patterns } from '@shared/helpers/patterns';
import { RequestModel } from '@shared/models/request/request.model';
import { FlashService } from '@shared/services/flash.service';
import { BsModalRef } from 'ngx-bootstrap';
import { BaseModalComponent } from '../../base.modal.component';

@Component({
  selector: 'iv-reject',
  templateUrl: './reject.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RejectComponent extends BaseModalComponent {

  public readonly VALID_PATTERN: RegExp = Patterns.TWO_WORDS_MIN;

  private readonly INVALID_FORM_MESSAGE: string = '_request._modal.error_validate';

  @Input()
  public request: RequestModel;

  @Input()
  public textPrev: string = '';

  @Input()
  public contentPreTitle: string = '';

  @Input()
  public contentTitle: string = '';

  @Input()
  public refuseTextPost: string = '';

  @Input()
  public nameTo: string = '';

  @Input()
  public nameFrom: string = '';

  @Input()
  public isEditable: boolean = false;

  @Input()
  public title: string = '';

  @Input()
  public contentText: string = '';

  @Input()
  public cancelOptions: {
    name: string;
    analitycsAction?: string;
  };

  @Input()
  public confirmOptions: {
    name: string;
    analitycsAction?: string;
  };

  public rejectText: string = '';

  public isLoading: boolean = false;

  @ViewChild(NgForm)
  private form: NgForm;

  constructor(protected bsModalRef: BsModalRef,
              private flash: FlashService) {
    super(bsModalRef);
    this.setResult(false);
  }

  public isInvalid(controlName: string): boolean {
    let control = this.form.form.get(controlName);

    return !!control && control.invalid && control.dirty
  }

  public send(): void {
    if (this.form.valid) {
      this.isLoading = true;
      this.publishResult({rejectText: this.rejectText});
    } else {
      this.flash.danger(this.INVALID_FORM_MESSAGE);
      FormHelper.MarkFormAsDirtyAndTouched(this.form.form);
    }
  }

}
import { INeed } from './need.interface';
import { ILocation } from '@shared/models/location.interface';

export class Need implements INeed {

  private readonly DEFAULT_STATUS: number = 381;

  private readonly DEFAULT_DEADLINE_MONTHS: number = 3;

  public abilities: string = '';

  public comment: string = '';

  public deadline: Date = new Date();

  public exp_abroad: ILocation | null = null;

  public experience: number;

  public id: number = 0;

  public id_project: number = 0;

  public partner_function: number;

  public role: string = '';

  public skill: number;

  public skills: {
    id: number;
    name: string;
  }[] = [];

  public status: number = this.DEFAULT_STATUS;

  public title: string = '';

  public travels: number = 0;

  constructor(need?: INeed) {
    this.setDeadlineMonths(this.DEFAULT_DEADLINE_MONTHS);

    if (need) {
      Object.assign(this, need);

      this.deadline = need.deadline ? new Date(need.deadline) : this.deadline;
    }
  }

  public setDeadlineMonths(months: number): void {
    this.deadline.setMonth(this.deadline.getMonth() + months);
    this.deadline.setHours(0, -this.deadline.getTimezoneOffset());
  }

}
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { UsersService } from '../../api/users.service';
import { FlashService } from '../../services/flash.service';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-remove-profile',
  templateUrl: './remove-profile.component.html',
  styles: []
})
export class RemoveProfileComponent extends BaseModalComponent {
  data = {
    reason: 1,
    reason_desc: ''
  };

  constructor(protected bsModalRef: BsModalRef,
              private flash: FlashService,
              private userService: UsersService) {
    super(bsModalRef);
    this.setResult(false);
  }

  remove() {
    this.userService.requestRemove(this.data).subscribe(
      data => {
        this.flash.success(data.text);
        this.setResult(true);
        this.close();
      });
  }
}

import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { LoginComponent } from './login/login.component';
import { RegistrationModule } from './registration/registration.module';
import { RestorePasswordComponent } from './restore-password/restore-password.component';
import { LoginFormComponent } from './login/login-form/login-form.component';
import { RestorePasswordFormComponent } from './restore-password/restore-password-form/restore-password-form.component';

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    LoginComponent,
    LoginFormComponent,
    RestorePasswordComponent,
    RestorePasswordFormComponent,
  ],
  exports: [
    LoginComponent,
    RegistrationModule,
    RestorePasswordComponent
  ],
  entryComponents: [
    RestorePasswordComponent,
    LoginComponent
  ],
})
export class AuthModule {
}

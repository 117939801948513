import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {PremiumService} from '../../../premium/premium.service';
import { BsModalRef } from 'ngx-bootstrap/modal';


@Component({
  selector: 'iv-payment-canceled-survey',
  templateUrl: './payment-canceled-survey.component.html',
  styleUrls: ['./payment-canceled-survey.component.scss']
})
export class PaymentCanceledSurveyComponent implements OnInit {
  public surveyForm: FormGroup;

  constructor(public modalRef: BsModalRef, private premiumService: PremiumService) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.surveyForm = new FormGroup({
        answer: new FormControl('', Validators.required)
      }
    );
  }

  onSubmit() {
    this.premiumService.sendPaymentCancelSurvey(this.surveyForm.value)
      .subscribe(
        () => this.modalRef.hide()
      );
  }

}

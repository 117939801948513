import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPartnership } from '../models/account/profile/partnership/partnership.interface';
import { BaseApiService } from './base-api-service';

@Injectable({
  providedIn: 'root'
})
export class PartnershipService extends BaseApiService {

  constructor(protected http: HttpClient) {
    super(http, '/partnership');
  }

  sendSuggest(params: { useful: number[], suggestions: string }): Observable<Object> {
    return this.post('/suggest', params);
  }

  list(options: { page: number, size: number, type?: number }): Observable<{ items: IPartnership[], count: number }> {
    const {page = 1, size = 24, type} = options;

    const params: any = {
      size: size + '',
    };
    if (type) {
      params.type = type;
    }

    return this.get<{ items: IPartnership[], count: number }>(`/list/${page}`, {params});
  }
}

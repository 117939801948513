import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { IndexService } from '@shared/api/index.service';
import { IInfoPremiumResponse } from '@shared/api/profile-http.service';
import { AbstractOrderForm } from '@shared/common/order/abstract-order-form';
import { FormHelper } from '@shared/helpers/form.helper';
import { PaymentMethod } from '@shared/models/authenticated-profile/payment-method.enum';
import { ICurrency } from '@shared/models/currency.interface';
import { ICurrencyPrice } from '@shared/models/interfaces';
import { IPremiumVariant } from '@shared/models/premium/premium.variant';
import { FlashService } from '@shared/services/flash.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CouponType } from '../../premium/become-premium/become-premium-select-plan/promo-block/coupon-type.enum';
import { ICoupon } from '../../premium/become-premium/become-premium-select-plan/promo-block/coupon.interface';
import { PremiumService } from '../../premium/premium.service';
import { ProfileStore } from '../../profile/profile.store';

@Component({
  selector: 'iv-premium-buy',
  templateUrl: './premium-buy.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PremiumBuyComponent extends AbstractOrderForm {

  @Input()
  public infoPremium: IInfoPremiumResponse;

  @Input()
  public coupon: ICoupon;

  @Input()
  public tokensPrice: number = 0;

  public variant: IPremiumVariant;

  public price: number;

  public totalPrice: number;

  public discount: number;

  public get currency(): ICurrency {
    return this.variant.price.currency;
  }

  public get variantMonth(): number {
    return this.variant.month;
  }

  constructor(protected fb: FormBuilder,
              protected paymentService: PremiumService,
              protected profileStore: ProfileStore,
              protected cd: ChangeDetectorRef,
              protected flash: FlashService,
              protected indexService: IndexService,
              private router: Router,
              @Inject(DOCUMENT)
              private document: Document) {
    super(flash, cd, fb, profileStore, indexService);
  }

  public startOrder(): void {
    if (this.form.valid) {

      let data = this.form.value;

      if (this.coupon) {
        data.promo = this.coupon.code;
      }

      if (this.tokensPrice) {
        data.tokensPrice = this.tokensPrice;
      }

      data.payment_type = this.totalPrice === 0 ? PaymentMethod.Free : data.payment_type;

      let request$: Observable<any> | null = this.getPaymentRequest(data);

      if (request$) {
        request$
          .subscribe((data: any) => {
            if (this.totalPrice === 0) {
              this.profileStore.loadProfile().subscribe(() =>
                this.router.navigate(['/premium/buy/success'], {
                  queryParams:
                    {
                      done: data.done,
                      startDate: data.startDate,
                      endDate: data.endDate
                    }
                })
              );
              return;
            }

            if (data.url) {
              this.document.location.href = data.url;
            } else {
              this.flash.danger(data.text);
            }
          });
      }
    } else {
      FormHelper.MarkFormAsDirtyAndTouched(this.form);
    }
  }

  protected getCurrencyPrice(countryIso: string): Observable<ICurrencyPrice> {
    return this.paymentService.getVariant(countryIso, this.variantId)
      .pipe(
        map(variant => {
          this.variant = variant;

          this.tokensPrice = this.variant.price.price > +this.tokensPrice ? +this.tokensPrice : this.variant.price.price;
          this.price = this.variant.price.price - +this.tokensPrice;
          this.totalPrice = this.price;
          this.setCoupon();

          return this.variant.price;
        })
      );
  }

  private setCoupon(): void {
    if (!this.coupon || this.coupon.premium_variant_id && this.coupon.premium_variant_id !== this.variant.id) {
      return;
    }

    this.discount = 0;

    if (this.coupon.type === CouponType.DISCOUNT && this.coupon.options.percent) {
      this.discount = this.price / 100 * this.coupon.options.percent;
      this.totalPrice = this.price - this.discount;
    }
  }
}

import { Input, OnDestroy } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';

export abstract class BaseModalComponent implements OnDestroy {

  @Input()
  public additionalParams: any;

  private _onClose: Subject<any> = new Subject<any>();

  public result$: Observable<any> = this._onClose.asObservable();

  private _result: any;

  protected constructor(protected bsModalRef: BsModalRef) {
  }

  public setResult(result: any) {
    this._result = result;
  }

  public publishResult(result: any): void {
    console.log(result);
    this.setResult(result);
    this._onClose.next(this._result);
  }

  public ngOnDestroy(): void {
    if (this._result !== undefined) {
      this._onClose.next(this._result);
    } else {
      this._onClose.error(new Error('Empty result'));
    }

    this._onClose.complete();
  }

  public close(): void {
    this.bsModalRef.hide();
  }

}

import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { ModalsService } from '@shared/modals/modals.service';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ProfileStore } from '../../profile/profile.store';
import { CookieService } from 'ngx-cookie';

@Injectable({
  providedIn: 'root'
})
export class NotNewAccountGuard implements CanLoad {

  constructor(private profileStore: ProfileStore,
              private cookieService: CookieService,
              private modalsService: ModalsService,
              private router: Router) {
  }

  public canLoad(): Observable<boolean> {
    return this.profileStore.authenticatedProfile$.pipe(
      filter(profile => !!profile),
      take(1),
      map(profile => !profile.isNew),
      tap(canActivate => {
        if (!canActivate) {
          this.setEmailConfirmationTimeCookie();
          this.modalsService.openResendEmailDialog();
          this.router.navigate(['/profile/fill']);
        }
      })
    );
  }

  private setEmailConfirmationTimeCookie(): void {
    const now = new Date();
    const expires = new Date();
    expires.setMinutes(expires.getMinutes() + 5);
    this.cookieService.put('emc', now.getTime() + '', {expires});
  }

}
import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, CanLoad } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileService } from '../services/profile.service';

@Injectable({
  providedIn: 'root'
})
export class CanActiveAuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(private profileService: ProfileService) {
  }

  public canActivate(): Observable<boolean> {
    return this.profileService.authorized();
  }

  public canActivateChild(): Observable<boolean> {
    return this.profileService.authorized();
  }

  public canLoad(): Observable<boolean> {
    return this.profileService.authorized();
  }
}

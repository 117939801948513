export class BaseModel<T extends { id: number }> {
  id: number;

  constructor(attrs: T = <T>{id: 0}) {
    this.fetch(attrs);
  }

  fetch(attrs: T): this {
    attrs.id = attrs.id || 0;
    Object.assign(this, attrs);

    return this;
  }
}

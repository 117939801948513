import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-alert',
  templateUrl: './alert.component.html',
  styles: []
})
export class AlertComponent extends BaseModalComponent {

  @Input()
  public description = '';

  @Input()
  public values: any = {};

  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
    this.setResult('close');
  }
}

import { Directive, HostListener, Input } from '@angular/core';
import { EventLogService } from '../api/event-log.service';

@Directive({
  selector: '[ivEventLog]'
})
export class EventLogDirective {

  @Input('ivEventLog')
  private event: string;

  @Input('ivEventLogCategory')
  private category: string;

  @Input('ivEventLogTarget')
  private target: string;

  constructor(private eventLog: EventLogService) {
  }

  @HostListener('click')
  public onClick(): void {
    this.eventLog.create({
      event: this.event,
      category: this.category,
      target: this.target
    });
  }

}
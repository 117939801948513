import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'iv-search-contacts',
  templateUrl: './search-contacts.component.html',
  styles: [':host {display: block}']
})
export class SearchContactsComponent implements OnInit, OnDestroy {

  private readonly DEFAULT_SEARCH_PLACEHOLDER: string = 'search_for_contacts';

  private readonly DEBOUNCE_TIME: number = 200;

  @Input()
  public searchInProgress: boolean = false;

  @Output()
  public queryChange: EventEmitter<string> = new EventEmitter<string>();

  public control = new FormControl('');

  @Input()
  public placeholder: string = this.DEFAULT_SEARCH_PLACEHOLDER;

  private subscription: Subscription;

  public get query(): string {
    return this.control.value;
  }

  @Input()
  public set query(value: string) {
    this.control.setValue(value);
  }

  ngOnInit(): void {
    this.subscription = this.control.valueChanges.pipe(
      debounceTime(this.DEBOUNCE_TIME),
      distinctUntilChanged(),
    )
      .subscribe(query => {
        this.searchInProgress = true;
        this.queryChange.emit(query);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
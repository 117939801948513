import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'iv-confirm-with-checkbox',
  templateUrl: './confirm-with-checkbox.component.html'
})
export class ConfirmWithCheckboxComponent {

  @Input()
  public checkboxText: string = 'license_agreement_terms_of_use_and_confirm_email';

  @Input()
  public helpMessage: string = 'email_confirm_please_resend';

  @Input()
  public buttonLabel: string = '_email_confirm_modal.resend';

  @Output()
  public confirmed: EventEmitter<void> = new EventEmitter<void>();

  public agreeFormControl: FormControl = new FormControl(false);
}

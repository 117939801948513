import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '@shared/modals/base.modal.component';
import { BsModalRef } from 'ngx-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iv-alert-licence',
  templateUrl: './alert-licence.component.html'
})
export class AlertLicenceComponent extends BaseModalComponent {

  @Input()
  public content: any;

  @Input()
  public licenceText: string;

  constructor(protected bsModalRef: BsModalRef,
              private sanitizer: DomSanitizer) {
    super(bsModalRef);
    this.setResult(false);

    this.content = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }

  public confirm(): void {
    this.setResult(true);
    this.close();
  }

}

import { AfterViewInit, Directive, forwardRef } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';
import { InputWithCurrencyComponent } from '@shared/components/input-with-currency/input-with-currency.component';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

@Directive({
  selector: 'iv-input-with-currency[ivNavigatableFormControl][formControlName]',
  providers: [{
    provide: AbstractNavigatableDirective,
    useExisting: forwardRef(() => InputWithCurrencyNavigatableDirective)
  }]
})
export class InputWithCurrencyNavigatableDirective extends AbstractNavigatableDirective implements AfterViewInit {

  private subscription: Subscription;

  private get itemSelect(): NgSelectComponent {
    return this.inputWithCurrency.itemNgSelect;
  }

  private get currencySelect(): NgSelectComponent {
    return this.inputWithCurrency.currencyNgSelect;
  }

  constructor(private inputWithCurrency: InputWithCurrencyComponent,
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService) {
    super(formControlName, service, scrollableService);
  }

  public ngAfterViewInit(): void {
    this.subscription = this.itemSelect.closeEvent.pipe(
      filter(() => !this.itemSelect.multiple && this.itemSelect.hasValue)
    )
      .subscribe(() => this.currencySelect.focus());

    this.subscription.add(
      this.currencySelect.closeEvent.pipe(
        filter(() => !this.currencySelect.multiple && this.currencySelect.hasValue)
      )
        .subscribe(() => this.next())
    );
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.subscription.unsubscribe();
  }

  public nativeFocus(): void {
    if (this.currencySelect.hasValue) {
      this.itemSelect.open();
    } else {
      this.currencySelect.open();
    }
  }

}

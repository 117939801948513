import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

@Component({
  selector: 'iv-input-number',
  templateUrl: './input-number.component.html',
  styles: [],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputNumberComponent),
    multi: true
  }]
})
export class InputNumberComponent implements ControlValueAccessor {
  @Input() min: number = 0;

  @Input() max: number = 100;

  @Input() step: number = 1;

  private innerValue: any = '';

  private onTouchedCallback: () => void = noop;

  private onChangeCallback: (_: any) => void = noop;


  get value(): any {
    return this.innerValue;
  };

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  downValue() {
    if (this.innerValue - this.step < this.min) {
      this.value = this.min;
    } else {
      this.value -= this.step;
    }
  }

  upValue() {
    if (this.innerValue + this.step > this.max) {
      this.value = this.max;
    } else {
      this.value += this.step;
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'iv-sign-up-redirect',
  template: '',
  styles: []
})
export class SignUpRedirectComponent {

  constructor(router: Router, route: ActivatedRoute) {
    const queryParams = route.snapshot.queryParams;
    router.navigate(['/home', {outlets: {'popup': ['sign-up']}}], {queryParams});
  }

}

import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { ProfileStore } from '../../../profile/profile.store';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-alert-title',
  templateUrl: './alert-title.component.html',
  styleUrls: ['./alert-title.component.scss']
})
export class AlertTitleComponent extends BaseModalComponent {

  @Input()
  public title = '';

  @Input()
  public description = '';

  @Input()
  public values: any = {_hideClose: false, openPhotoDialog: false, additional: ''};

  constructor(protected bsModalRef: BsModalRef,
              private profileStore: ProfileStore) {
    super(bsModalRef);
    this.setResult('close');
  }

  public close(result?: boolean): void {
    if (result) {
      this.setResult(result);
    }

    if (this.values.openPhotoDialog) {
      this.profileStore.openPhotoDialog();
    }

    this.bsModalRef.hide();
  }
}

import { Component, Input } from '@angular/core';
import { ModalsService } from '@shared/modals/modals.service';

@Component({
  selector: 'iv-need-premium',
  templateUrl: './need-premium.component.html',
  styles: [':host {display: block}']
})
export class NeedPremiumComponent {

  @Input()
  public total: number = 0;

  constructor(private modalsService: ModalsService) {
  }

  public openInfoPremium(): void {
    const title = '_premium._need.fans.modal.title';
    const text = '_premium._need.fans.modal.info';

    this.modalsService.openPremiumInfo(title, text);
  }

}
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormHelper } from '@shared/helpers/form.helper';
import { IReferUser } from '@shared/models/interfaces';

@Component({
  selector: 'iv-refer-form',
  templateUrl: './refer-form.component.html',
  styles: []
})
export class ReferFormComponent {

  @Input()
  public form: FormGroup;

  @Output()
  public send: EventEmitter<IReferUser> = new EventEmitter<IReferUser>();

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  public onSend(): void {
    this.form.valid && this.send.emit(<IReferUser>this.form.value) || FormHelper.MarkFormAsDirtyAndTouched(this.form);
  }
}

import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ProfileStore } from '../../profile/profile.store';

@Injectable({
  providedIn: 'root'
})
export class PartnerGuard implements CanLoad {

  constructor(private profileStore: ProfileStore,
              private router: Router) {
  }

  public canLoad(): Observable<boolean> {
    return this.profileStore.authenticatedProfile$.pipe(
      filter(profile => !!profile),
      take(1),
      map(profile => profile.isPartner),
      tap(canActivate => !canActivate && this.router.navigate(['/home']))
    );
  }

}
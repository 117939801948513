import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'iv-payment-info',
  templateUrl: './payment-info.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentInfoComponent {

  public readonly DEFAULT_NUMBER_FORMAT: string = '1.2-2';

  @Input()
  public totalPrice: number;

  @Input()
  public vatRate: number;

  @Input()
  public currencyName: string;

  @Input()
  public loading: boolean = true;

  public get vat(): number {
    return this.totalPrice - (this.totalPrice / ((this.vatRate / 100) + 1));
  }

  public get vatBase(): number {
    return this.totalPrice / ((this.vatRate / 100) + 1);
  }

}

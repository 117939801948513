import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-premium-info',
  templateUrl: './premium-info.component.html',
  styles: ['h3{text-align: center;}']
})
export class PremiumInfoComponent extends BaseModalComponent {

  @Input()
  public description = '';

  @Input()
  public title = '';

  constructor(protected bsModalRef: BsModalRef,
              private router: Router) {
    super(bsModalRef);
    this.setResult('close');
  }

  public close(): void {
    this.bsModalRef.hide();
  }

  public becomePremium(): void {
    this.router.navigate(['/premium/become']);
    this.close();
  }
}

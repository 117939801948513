import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@shared/api/base-api-service';

@Injectable({
  providedIn: 'root'
})
export class EventLogService extends BaseApiService {
  constructor(protected http: HttpClient) {
    super(http, '/event-log');
  }

  public create(data: { event: string, category: string, target: string }): void {
    this.post('create', {
      event: data.event,
      category: data.category,
      target_id: data.target
    }).subscribe();
  }

}
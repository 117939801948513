import { WindowRef } from '@agm/core/utils/browser-globals';
import { Directive, ElementRef, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ScriptService } from '@shared/services/script.service';
import { finalize } from 'rxjs/operators';

@Directive({
  selector: '[ivAddthis]'
})
export class AddthisDirective implements OnInit {

  @Input('ivAddthis')
  public method: string = 'toolbox';

  @Input('ivAddthisUrl')
  public url: string;

  private addthis: any;

  constructor(@Inject(WindowRef)
              private window: WindowRef,
              private elementRef: ElementRef,
              private translate: TranslateService,
              private renderer: Renderer2,
              private scriptService: ScriptService) {
  }

  public ngOnInit(): void {
    this.scriptService.loadAddThis(this.renderer).pipe(
      finalize(() => {
        this.addthis = this.window.getNativeWindow()['addthis'];
        if (this.method === 'counter') {
          this.setCounter();
        } else {
          this.addthis[this.method](this.elementRef.nativeElement, {click: () => console.log('click')}, {url: this.url});
        }
      })
    ).subscribe();
  }

  private setCounter(): void {
    this.addthis.sharecounters.getShareCounts({service: 'compact', countUrl: this.url}, (data: any[]) => {
      const total = data.reduce((prev: number, current: { count: number }) => prev += current.count, 0);
      const shareText = this.translate.instant(`_project_share${total > 1 ? 's' : ''}`);
      this.elementRef.nativeElement.innerHTML = `${total} ${shareText}`;
    });
  }

}
import { Injectable } from '@angular/core';
import { CurrencyService } from '@shared/api/currency.service';
import { IInfoPremiumResponse, ProfileHttpService } from '@shared/api/profile-http.service';
import { AccountType } from '@shared/enums/account/type.enum';
import { AuthenticatedPartnershipProfile } from '@shared/models/authenticated-profile/authenticated-partnership-profile.model';
import { AuthenticatedProfileTypes } from '@shared/models/authenticated-profile/authenticated-profile-types';
import { AuthenticatedUserProfile } from '@shared/models/authenticated-profile/authenticated-user-profile.model';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileStore {

  private readonly _authenticatedProfile$: BehaviorSubject<AuthenticatedProfileTypes> = new BehaviorSubject<AuthenticatedProfileTypes>(<any>null);

  private readonly _profilePremium$: BehaviorSubject<IInfoPremiumResponse> = new BehaviorSubject<IInfoPremiumResponse>(<any>null);

  private readonly _openPhotoDialog$: Subject<void> = new Subject<void>();

  public authenticatedProfile$: Observable<AuthenticatedProfileTypes> = this._authenticatedProfile$.asObservable();

  public profileInfoPremium$: Observable<IInfoPremiumResponse> = this._profilePremium$.asObservable();

  public openPhotoDialog$: Observable<void> = this._openPhotoDialog$.asObservable();

  public get profile(): AuthenticatedProfileTypes {
    return this._authenticatedProfile$.value;
  }

  constructor(protected backend: ProfileHttpService,
              private currencyService: CurrencyService) {
  }

  public getAuthenticatedProfile(): Observable<AuthenticatedProfileTypes> {
    if (!this._authenticatedProfile$.value) {
      return this.loadProfile().pipe(concatMap(() => this.authenticatedProfile$));
    } else {
      return this.authenticatedProfile$;
    }
  }

  public getProfilePremium(): Observable<Observable<IInfoPremiumResponse>> {
    return this.backend.profileInfoPremium()
      .pipe(
        tap(profileInfoPremium => !this._profilePremium$.value && this._profilePremium$.next(profileInfoPremium)),
        map(() => this.profileInfoPremium$)
      );
  }

  public getVatRate(countryIso: string): Observable<number> {
    return this.backend.vatRate(countryIso);
  }

  public setProfileInfoPhoto(previewUrl: string): void {
    let profile = <AuthenticatedUserProfile>this._authenticatedProfile$.value;
    profile.photo = previewUrl;
    profile.has_old_photo = false;
    profile.hasPhoto = true;

    this._authenticatedProfile$.next(profile);
  }

  public loadProfile(): Observable<AuthenticatedProfileTypes> {
    const profileRequest$ = this.backend.getAuthenticatedProfile();
    const currencyRequest$ = this.currencyService.location();

    return forkJoin(profileRequest$, currencyRequest$)
      .pipe(
        map(([profile, currency]) => {
          profile.locationCurrency = currency;
          this._authenticatedProfile$.next(this.prepareProfile(<AuthenticatedProfileTypes>profile));
          return profile;
        })
      );
  }

  public openPhotoDialog(): void {
    this._openPhotoDialog$.next();
  }

  private prepareProfile(profile: AuthenticatedProfileTypes): AuthenticatedProfileTypes {
    switch (profile.type) {
      case AccountType.NotSet:
      case AccountType.Entrepreneur:
      case AccountType.Partner:
        profile = new AuthenticatedUserProfile(<AuthenticatedUserProfile>profile);
        break;
      case AccountType.Connector:
      case AccountType.Sponsor:
        profile = new AuthenticatedPartnershipProfile(<AuthenticatedPartnershipProfile>profile);
        break;
      default:
        throw new Error('Unknown type: ' + profile.type);
    }

    return profile;
  }
}

import { Component } from '@angular/core';
import { ProfileService } from '@shared/services/profile.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap';
import { LoginComponent } from '../../../auth/login/login.component';

@Component({
  selector: 'iv-user-landing-header',
  templateUrl: './user-landing-header.component.html',
  styleUrls: ['./user-landing-header.component.scss']
})
export class UserLandingHeaderComponent {

  public isNavbarCollapsed = true;

  constructor(private bsModalService: BsModalService,
              private profileService: ProfileService) {
  }

  public openLogin(): void {
    this.bsModalService.show(LoginComponent, <ModalOptions>{'class': 'modal-sm'});
  }

  public registration(): void {
    this.profileService.registration();
  }
}

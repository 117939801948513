import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProfileService } from '@shared/services/profile.service';

@Component({
  selector: 'iv-user-landing',
  templateUrl: './user-landing.component.html',
  styleUrls: ['./user-landing.component.scss']
})
export class UserLandingComponent {

  public readonly CONFIG: any = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  public readonly USER_TYPES = {
    ENTREPRENEUR: 'entrepreneur',
    PARTNER: 'partner'
  };

  public readonly SLIDES: { img: string, name: string, text: string }[] = [
    {
      img: 'user_landing.content.what_say_about.testimonials.img.1',
      name: 'user_landing.content.what_say_about.testimonials.name.1',
      text: 'user_landing.content.what_say_about.testimonials.text.1'
    },
    {
      img: 'user_landing.content.what_say_about.testimonials.img.2',
      name: 'user_landing.content.what_say_about.testimonials.name.2',
      text: 'user_landing.content.what_say_about.testimonials.text.2'
    },
    {
      img: 'user_landing.content.what_say_about.testimonials.img.3',
      name: 'user_landing.content.what_say_about.testimonials.name.3',
      text: 'user_landing.content.what_say_about.testimonials.text.3'
    },
    {
      img: 'user_landing.content.what_say_about.testimonials.img.4',
      name: 'user_landing.content.what_say_about.testimonials.name.4',
      text: 'user_landing.content.what_say_about.testimonials.text.4'
    },
    {
      img: 'user_landing.content.what_say_about.testimonials.img.5',
      name: 'user_landing.content.what_say_about.testimonials.name.5',
      text: 'user_landing.content.what_say_about.testimonials.text.5'
    },
  ];

  public readonly userType: string = '';

  constructor(private profileService: ProfileService,
              private route: ActivatedRoute) {
    this.userType = this.route.snapshot.data.userTypeText;
  }

  public register(): void {
    this.profileService.registration();
  }

}

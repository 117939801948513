import { Directive, forwardRef, Optional } from '@angular/core';
import { NavigatableFormControlService } from '../../services/navigatable-form-control.service';
import { FormControlName } from '@angular/forms';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';
import { ChooseFileButtonComponent } from '@shared/components/choose-file-button/choose-file-button.component';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'iv-choose-file-button[ivNavigatableFormControl]',
  providers: [{
    provide: AbstractNavigatableDirective,
    useExisting: forwardRef(() => ChooseFileButtonNavigatableDirective)
  }]
})
export class ChooseFileButtonNavigatableDirective extends AbstractNavigatableDirective {

  private subscription: Subscription;

  constructor(private chooseFileButton: ChooseFileButtonComponent,
              @Optional()
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService) {
    super(formControlName, service, scrollableService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.chooseFileButton.closed.subscribe(() => this.next());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public nativeFocus(): void {
    this.chooseFileButton.focus();
  }

}

import { HttpClient } from '@angular/common/http';
// AoT requires an exported function for factories
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export class IvTranslateHttpLoader extends TranslateHttpLoader {
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new IvTranslateHttpLoader(httpClient, '/locale-', '.json');
}


import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export class FormHelper {

  public static MarkFormAsDirtyAndTouched(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(
        controlName => FormHelper.MarkFormAsDirtyAndTouched(control.get(controlName)!)
      );
    }

    if (control instanceof FormArray) {
      control.controls.forEach(c => FormHelper.MarkFormAsDirtyAndTouched(c));
    }

    control.markAsDirty();
    control.markAsTouched();
  }

  public static MarkFormAsPristineAndUntouched(control: AbstractControl): void {
    if (control instanceof FormGroup) {
      Object.keys(control.controls).forEach(
        controlName => FormHelper.MarkFormAsPristineAndUntouched(control.get(controlName)!)
      );
    }

    if (control instanceof FormArray) {
      control.controls.forEach(c => FormHelper.MarkFormAsPristineAndUntouched(c));
    }

    control.markAsUntouched();
    control.markAsPristine();
  }

  public static GetFirstInvalidControl(form: FormGroup | FormArray): FormControl | null {
    const controls = form.controls;

    let invalidControl: FormControl | null = null;

    for (const name in controls) {
      if (controls.hasOwnProperty(name) && controls[name].invalid) {
        if (controls[name] instanceof FormGroup || controls[name] instanceof FormArray) {
          invalidControl = FormHelper.GetFirstInvalidControl(controls[name]);
        } else {
          invalidControl = controls[name];
        }

        break;
      }
    }

    return invalidControl;
  }

  public static GetControlName(form: FormGroup | FormArray, control: FormControl): string | null {
    const controls = form.controls;
    let controlName: string | null = null;

    for (const name in controls) {
      if (controls.hasOwnProperty(name)) {
        if (controls[name] instanceof FormGroup || controls[name] instanceof FormArray) {
          controlName = FormHelper.GetControlName(controls[name], control);

          if (controlName !== null) {
            break;
          }
        }

        if (controls[name] === control) {
          controlName = name;
          break;
        }
      }
    }

    return controlName;
  }

}

import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { BaseModalComponent } from '../base.modal.component';

@Component({
  selector: 'iv-confirm-title',
  templateUrl: './confirm-title.component.html',
  styles: []
})
export class ConfirmTitleComponent extends BaseModalComponent {

  @Input()
  public title = '';

  @Input()
  public description = '';

  @Input()
  public values: any = {_hideClose: false};

  @Input()
  public cancelOptions: {
    name: string;
    analitycsAction?: string;
  };

  @Input()
  public confirmOptions: {
    name: string;
    analitycsAction?: string;
  };

  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
    this.setResult(false);
  }

  confirm() {
    this.setResult(true);
    this.close();

    if (this.values.callback) {
      this.values.callback();
    }
  }

  cancel() {
    this.close();
  }

}

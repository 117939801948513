import { Component, Input } from '@angular/core';
import { BaseModalComponent } from '@shared/modals/base.modal.component';
import { BsModalRef } from 'ngx-bootstrap';
import { ICropImageData } from '@shared/directives/cropped-photo-uploader/crop-image-data.interface';

@Component({
  selector: 'iv-choose-file',
  templateUrl: './choose-file.component.html',
  styleUrls: ['./choose-file.component.scss']
})
export class ChooseFileComponent extends BaseModalComponent {

  @Input()
  public title: string = '';

  @Input()
  public description: string = '';

  @Input()
  public helpMessage: string = '';

  @Input()
  public logoSrc: string = '';

  @Input()
  public width: number = 300;

  @Input()
  public height: number = 300;

  @Input()
  public aspectRatio: number = 1;

  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
    this.setResult(false);
  }

  public onLogoAdd(file: ICropImageData): void {
    this.setResult(file);
    this.close();
  }

}

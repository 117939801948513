import { Component, Input } from '@angular/core';
import { IInfoPremiumResponse } from '@shared/api/profile-http.service';
import { BaseModalComponent } from '@shared/modals/base.modal.component';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'iv-wirate-modal',
  templateUrl: './wirate-modal.component.html',
})
export class WirateModalComponent extends BaseModalComponent {

  @Input()
  public variantId: number;

  @Input()
  public infoPremium: IInfoPremiumResponse;

  constructor(protected bsModalRef: BsModalRef) {
    super(bsModalRef);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'iv-email-confirmed-redirect',
  templateUrl: './email-confirmed-redirect.component.html',
  styles: []
})
export class EmailConfirmedRedirectComponent implements OnInit {

  constructor(router: Router, route: ActivatedRoute) {
    const type = route.snapshot.params.type;
    const queryParams = route.snapshot.queryParams;
    router.navigate(['/home', {outlets: {'popup': ['email-confirmed', type]}}], {queryParams});
  }

  ngOnInit() {
  }

}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  forwardRef,
  Host,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { OptionService } from '../../api/option.service';
import { IOption } from '../../models/option.interface';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'iv-skill-input',
  templateUrl: './skill-input.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SkillInputComponent),
    multi: true
  }]
})
export class SkillInputComponent implements OnInit, DoCheck, OnDestroy, ControlValueAccessor {

  public skillTypeahead$: Subject<string> = new Subject<string>();

  public skillsLoading: boolean = false;

  public filteredSkills: IOption[] = [];

  public control: FormControl = new FormControl(null);

  public isDisabled: boolean;

  public isControlDirty: boolean = false;

  public onTouched = () => {
  };

  public onChange = (_: number[]) => {
  };

  @Input()
  public isLabelVisible: boolean = true;

  @Input()
  public isLabelFocusedClass: boolean = false;

  @Input()
  public isCanAdd: boolean = true;

  @Input()
  public isPlaceholder: boolean = true;

  private _skills: IOption[] = [];

  @Input()
  private set skills(skills: IOption[]) {
    this._skills = skills;
    this.filteredSkills = [...skills];
  }

  private get skills(): IOption[] {
    return this._skills;
  }

  @Input()
  private formControlName: string = '';

  @ViewChild(NgSelectComponent)
  private ngSelect: NgSelectComponent;

  public bindCreateSkillFunction: any;

  private hostControl: AbstractControl | null;

  private subscription: Subscription;

  private get ngClassDirty(): boolean {
    return !!this.hostControl && this.hostControl.dirty;
  }

  constructor(private optionsService: OptionService,
              private cd: ChangeDetectorRef,
              @Optional() @Host() @SkipSelf()
              private controlContainer?: ControlContainer) {
    this.bindCreateSkillFunction = this.createSkill.bind(this);
  }

  public ngOnInit(): void {
    this.findSkills();

    if (this.controlContainer && this.formControlName) {
      this.hostControl = this.controlContainer.control && this.controlContainer.control.get(this.formControlName);

      if (this.hostControl) {
        this.control.setValidators(this.hostControl.validator);
      }

      this.control.updateValueAndValidity();
    }
  }

  public ngDoCheck(): void {
    if (this.isControlDirty !== this.ngClassDirty) {
      this.isControlDirty = this.ngClassDirty;
      this.cd.markForCheck();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public writeValue(skills: number[]): void {
    this.control.setValue(skills);
  }

  public registerOnChange(fn: (value: number[]) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(): void {
    this.isDisabled ? this.control.disable() : this.control.enable();
  }

  public focus(): void {
    this.ngSelect.open();
  }

  public onNgSelectChange(value: IOption[]): void {
    let ids = value.map(v => v.id);
    this.onChange(ids);
  }

  public createSkill(name: string): Promise<IOption> {
    return this.optionsService.createSkill(name)
      .pipe(
        tap(() => this.skillsLoading = true)
      )
      .toPromise()
      .then(skill => {
        this.skillsLoading = false;
        this.skills.push(skill);
        return skill;
      });
  }

  private findSkills(): void {
    this.subscription = this.skillTypeahead$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.skillsLoading = true),
        switchMap(term => this.optionsService.getSkills(term))
      )
      .subscribe(items => {
        this.filteredSkills = items;
        this.skillsLoading = false;
        this.cd.markForCheck();
      }, () => this.filteredSkills = []);
  }

}

import { Directive, ElementRef, forwardRef, Optional } from '@angular/core';
import { AbstractNavigatableDirective } from '@shared/directives/navigatable-form-control/abstract-navigatable.directive';
import { FormControlName } from '@angular/forms';
import { NavigatableFormControlService } from '@shared/services/navigatable-form-control.service';
import { CroppedPhotoUploaderDirective } from '@shared/directives/cropped-photo-uploader/cropped-photo-uploader.directive';
import { Subscription } from 'rxjs';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';

@Directive({
  selector: 'input[type="file"][ivNavigatableFormControl][ivCroppedPhotoUploader]',
  providers: [{
    provide: AbstractNavigatableDirective,
    useExisting: forwardRef(() => CroppedPhotoUploaderNavigatableDirective)
  }]
})
export class CroppedPhotoUploaderNavigatableDirective extends AbstractNavigatableDirective {

  private subscription: Subscription;

  constructor(private uploader: CroppedPhotoUploaderDirective,
              private elementRef: ElementRef<HTMLInputElement>,
              @Optional()
              protected formControlName: FormControlName,
              protected service: NavigatableFormControlService,
              protected scrollableService: ScrollableFormControlService) {
    super(formControlName, service, scrollableService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.subscription = this.uploader.fileAdded.subscribe(() => this.next());
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  public nativeFocus(): void {
    this.elementRef.nativeElement.click();
  }

}
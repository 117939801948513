import { ProfileInfoBase } from '@shared/models/account/profile/profile-info-base.model';
import { RequestState } from '../../enums/account/request-state.enum';
import { BaseModel } from '../base.model';

export class RequestModel extends BaseModel<RequestModel> {
  public date: Date;

  public state: RequestState;

  public text_expectations: string;

  public text_like_project?: string;

  public text_reject?: string;

  public date_response: Date | null;

  public id_project?: string;

  public project_title?: string;

  public user_from: ProfileInfoBase;

  public user_to: ProfileInfoBase;

  public get isWait(): boolean {
    return this.state === RequestState.Wait;
  }

  public get isAccept(): boolean {
    return this.state === RequestState.Accept;
  }

  public get isReject(): boolean {
    return this.state === RequestState.Reject;
  }

  public get isCancel(): boolean {
    return this.state === RequestState.Cancel;
  }

  public get isUserDeleted(): boolean {
    return this.state === RequestState.UserDeleted;
  }

  public get isResponse(): boolean {
    return this.isAccept || this.isReject;
  }

  fetch(attrs: RequestModel): this {
    super.fetch(attrs);

    this.state = attrs.state;
    this.text_expectations = attrs.text_expectations;
    this.text_like_project = attrs.text_like_project;
    this.text_reject = attrs.text_reject;
    this.id_project = attrs.id_project;
    this.project_title = attrs.project_title;

    this.date = new Date(attrs.date);
    this.date_response = attrs.date_response && new Date(attrs.date_response) || null;
    this.user_from = new ProfileInfoBase(attrs.user_from);
    this.user_to = new ProfileInfoBase(attrs.user_to);

    return this;
  }
}
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalsService } from '@shared/modals/modals.service';
import { FlashService } from '@shared/services/flash.service';
import { FormatValidators } from '@shared/validators/format-validators';
import { Subscription } from 'rxjs';
import { PremiumService } from '../premium/premium.service';
import { ProfileStore } from '../profile/profile.store';

@Component({
  selector: 'iv-friend-invite',
  templateUrl: './friend-invite.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FriendInviteComponent implements OnInit, OnDestroy {

  private readonly MODAL_BASE_TEXT: string = '_refer_user_exist';

  private readonly USER_EXISTS_CODE: string = 'user_exist';

  public form: FormGroup;

  public currencySymbol: string = '';

  private subscription: Subscription;

  constructor(private fb: FormBuilder,
              private router: Router,
              private premiumService: PremiumService,
              private modalsService: ModalsService,
              private cd: ChangeDetectorRef,
              private flash: FlashService,
              private profileStore: ProfileStore) {
    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.subscription = this.profileStore.authenticatedProfile$
      .subscribe(profile => this.currencySymbol = profile.currencySymbol);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public send(user: any): void {
    this.premiumService.sendInvite(user)
      .subscribe(
        res => {
          if (res.code && res.code === this.USER_EXISTS_CODE) {
            this.openUserExistModal();
          } else {
            this.flash.success(res.text);
            this.cancel();
          }
        }
      );
  }

  public cancel(): void {
    this.router.navigate(['/home']);
  }

  private createForm(): FormGroup {
    return this.fb.group({
      firstName: ['', [Validators.required, FormatValidators.nameFormat]],
      lastName: ['', [Validators.required, FormatValidators.nameFormat]],
      gender: [1, Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]]
    });
  }

  private openUserExistModal(): void {
    this.modalsService.openConfirmTitle(
      `${this.MODAL_BASE_TEXT}.title`,
      `${this.MODAL_BASE_TEXT}.text`,
      {},
      {name: 'no_thanks', analitycsAction: 'New Profile Fill click Later'},
      {name: 'refer', analitycsAction: 'New Profile Fill click Continue'}
    )
      .subscribe(res => res ? this.resetForm() : this.router.navigate(['/home']));
  }

  private resetForm(): void {
    this.form.reset({
      gender: 1,
      firstName: '',
      lastName: '',
      email: '',
    });

    this.cd.markForCheck();
  }

}

import { AccountType } from '../../enums/account/type.enum';


export abstract class AccountTypeModel {

  public id: number;

  public type: AccountType;

  public get isPartner(): boolean {
    return this.type === AccountType.Partner;
  }

  public get isEntrepreneur(): boolean {
    return this.type === AccountType.Entrepreneur;
  }

  public get isTypeNone(): boolean {
    return this.type === AccountType.NotSet;
  }

  public get isConnector(): boolean {
    return this.type === AccountType.Connector;
  }

  public get isSponsor(): boolean {
    return this.type === AccountType.Sponsor;
  }

  public get isProfessional(): boolean {
    return this.type === AccountType.Professional;
  }

  public get isInvestor(): boolean {
    return this.type === AccountType.Investor;
  }

  public get isUser(): boolean {
    return this.isPartner || this.isEntrepreneur || this.isTypeNone;
  }

  public get isPartnership(): boolean {
    return this.isConnector || this.isSponsor || this.isProfessional || this.isInvestor;
  }

  public get isLogin(): boolean {
    return this.id !== undefined && this.id > 0;
  }

  public get isGuest(): boolean {
    return !this.isLogin;
  }

  protected constructor(props: any) {
    Object.assign(this, props);
  }
}

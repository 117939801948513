import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { LongDateFormatKey } from 'moment';
import { ProfileStore } from '../../profile/profile.store';
import 'moment/locale/en-gb'

@Pipe({
  name: 'locDate'
})
export class LocDatePipe implements PipeTransform {

  constructor(private translateService: TranslateService,
              private profileStore: ProfileStore) {
  }

  public transform(date: any, format?: LongDateFormatKey): string {

    let locale = this.translateService.currentLang || window.navigator.language;

    if (locale === 'en' && this.profileStore.profile.countryIso !== 'US') {
      locale = 'en-GB';
    }

    const d = moment(date);
    d.locale(locale);

    return d.format(d.localeData().longDateFormat(format || 'L'));
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import { PaymentState } from '@shared/models/authenticated-profile/payment-state.enum';

@Pipe({
  name: 'paymentState'
})
export class PaymentStatePipe implements PipeTransform {

  public transform(state: number): string {
    if (!state && state !== 0) {
      return '';
    }

    const allowedValues = Object.values(PaymentState)
      .filter(value => typeof value === 'number');

    if (isNaN(+state) || allowedValues.indexOf(+state) === -1) {
      return '';
    }

    return `_premium._menu._history._statuses.${state}`;
  }

}

import { Component, OnInit } from '@angular/core';
import { ProfileService } from '@shared/services/profile.service';
import { BsModalService, ModalOptions } from 'ngx-bootstrap';
import { LoginComponent } from '../../auth/login/login.component';

@Component({
  selector: 'iv-guest-navbar',
  templateUrl: './guest-navbar.component.html',
  styleUrls: ['./guest-navbar.component.scss']
})
export class GuestNavbarComponent implements OnInit {


  isNavbarCollapsed = true;

  constructor(private bsModalService: BsModalService,
              public PROFILE: ProfileService) {
  }

  ngOnInit() {

  }

  openLogin() {
    this.bsModalService.show(LoginComponent, <ModalOptions>{'class': 'modal-sm'});
  }

}

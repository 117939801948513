import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  NgZone,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OptionService } from '@shared/api/option.service';
import { ProfileService } from '@shared/services/profile.service';
import * as $ from 'jquery';
import { BsModalService, ModalOptions } from 'ngx-bootstrap';
import { interval } from 'rxjs';
import { concatMap, take, tap } from 'rxjs/operators';
import { LoginComponent } from './auth/login/login.component';
import { ProfileStore } from './profile/profile.store';

@Component({
  selector: 'iv-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  public profileLoaded = false;

  private footerHeight: number;

  private headerHeight: number;

  constructor(private translateService: TranslateService,
              private elementRef: ElementRef,
              private renderer: Renderer2,
              private profileStore: ProfileStore,
              private ngZone: NgZone,
              private cd: ChangeDetectorRef,
              private optionService: OptionService,
              private route: ActivatedRoute,
              private router: Router,
              private profileService: ProfileService,
              private bsModalService: BsModalService) {

    this.translateService.addLangs(['en', 'fr']);
    this.translateService.setDefaultLang('en');

    this.profileStore.getAuthenticatedProfile()
      .pipe(
        tap(profile => translateService.use(profile.lang)),
        concatMap(profile => this.optionService.checkLangHash(profile.lang)),
        tap(() => this.profileLoaded = true),
        take(1)
      )
      .subscribe(() => this.cd.markForCheck());
  }

  public ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => interval(100).subscribe(() => this.checkSize()));

   const modalSubscription = this.route.queryParamMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('modal')) {
        const modalName = paramMap.get('modal');

        switch (modalName) {
          case 'sign-in':
            modalSubscription.unsubscribe();
            this.bsModalService.show(LoginComponent, <ModalOptions>{'class': 'modal-sm'});
            this.router.navigate(['.'], {relativeTo: this.route});
            break;
          case 'sign-up':
            modalSubscription.unsubscribe();
            this.profileService.registration();
            this.router.navigate(['.'], {relativeTo: this.route});
            break;
          default:
            break;
        }
      }
    })
  }

  private checkSize(): void {
    const $header = $('header');
    const $footer = $('footer');

    if (($footer && this.footerHeight !== $footer.height())
      || ($header && this.headerHeight !== $header.height())
    ) {
      this.footerHeight = $footer.height() || 0;
      this.headerHeight = $header.height() || 0;
      this.onSizeChanged();
    }
  }

  private onSizeChanged(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'padding-top', this.headerHeight + 'px');
    this.renderer.setStyle(this.elementRef.nativeElement, 'padding-bottom', this.footerHeight + 'px');
  }

}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PaymentMethod } from '../../models/authenticated-profile/payment-method.enum';

@Component({
  selector: 'iv-payment-method',
  templateUrl: './payment-method.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PaymentMethodComponent),
    multi: true
  }]
})
export class PaymentMethodComponent implements ControlValueAccessor, AfterViewInit {

  @Input()
  public isEuCountry: boolean = false;

  public enablePayline: boolean = GlobalConfig.ENABLE_PAYLINE;

  public PaymentMethod = PaymentMethod;

  public onChange = (_: number): void => {
  };

  public onTouched = (): void => {
  };

  @ViewChildren('input')
  private inputs: QueryList<ElementRef>;

  private _value: any;

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    if (this._value) {
      this.writeValue(this._value);
    }
  }

  writeValue(value: number): void {
    if (!value) {
      return;
    }

    this._value = value;

    if (!this.inputs) {
      return;
    }

    let input = this.inputs.find(input => +input.nativeElement.value === +value);

    if (input) {
      this.renderer.setProperty(input.nativeElement, 'checked', true);
    }
  }

  registerOnChange(fn: (_: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.inputs.forEach(input => this.renderer.setProperty(input.nativeElement, 'disabled', isDisabled));
  }

}

import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActiveAuthGuard } from '@shared/guards/can-active-auth.guard';
import { ConnectorGuard } from '@shared/guards/connector.guard';
import { EntrepreneurGuard } from '@shared/guards/entrepreneur.guard';
import { NotNewAccountGuard } from '@shared/guards/not-new-account.guard';
import { NotSponsorGuard } from '@shared/guards/not-sponsor.guard';
import { PartnerGuard } from '@shared/guards/partner.guard';
import { ProfileCompleteGuard } from '@shared/guards/profile-complete.guard';
import { SponsorGuard } from '@shared/guards/sponsor.guard';
import { UserGuard } from '@shared/guards/user.guard';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { EmailConfirmedRedirectComponent } from './email-confirmed-redirect/email-confirmed-redirect.component';
import { FriendInviteComponent } from './friend-invite/friend-invite.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { UserLandingComponent } from './main-layout/user-landing/user-landing.component';
import { PageComponent } from './page/page.component';
import { ReportAbusePageComponent } from './report-abuse-page/report-abuse-page.component';
import { SignUpRedirectComponent } from './sign-up-redirect/sign-up-redirect.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {PaymentComponent} from './payment/payment.component';
import {NfResolver} from './not-found/not-found.resolver';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {
        path: 'home', loadChildren: 'app/home/home.module#HomeModule',
        canLoad: [
          NotSponsorGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'sign-up',
        component: SignUpRedirectComponent,
      },
      { // TODO: After adding 404 page, 404 error when confirm via email.  needs to be fixed.
        path: 'confirm-email/:type/:verify',
        component: EmailConfirmedRedirectComponent
      },
      { // TODO: After adding 404 page, 404 error when confirm via email.  needs to be fixed.
        path: 'email-confirmed/:type',
        component: EmailConfirmedRedirectComponent
      },
      {
        path: 'invite-friend',
        component: FriendInviteComponent,
        canActivate: [
          CanActiveAuthGuard,
          UserGuard
        ]
      },
      {
        path: 'contact-us',
        component: ContactUsPageComponent
      },
      {
        path: 'page/:slug',
        component: PageComponent
      },
      {
        path: 'report-abuse',
        component: ReportAbusePageComponent
      },
      {
        path: 'welcome-south-african',
        loadChildren: 'app/welcome-south-african/welcome-south-african.module#WelcomeSouthAfricanModule'
      },
      {
        path: 'welcome-from-wirate',
        loadChildren: 'app/welcome-from-wirate/welcome-from-wirate.module#WelcomeFromWirateModule'
      },
      {
        path: 'profile',
        loadChildren: 'app/profile/profile.module#ProfileModule',
        canLoad: [
          CanActiveAuthGuard,
          UserGuard
        ],
      },
      {
        path: 'premium',
        loadChildren: 'app/premium/premium.module#PremiumModule',
        canLoad: [
          CanActiveAuthGuard,
          UserGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'chats',
        loadChildren: 'app/chats/chats.module#ChatsModule',
        canLoad: [
          CanActiveAuthGuard,
          NotSponsorGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'search',
        loadChildren: 'app/search/search.module#SearchModule',
        canLoad: [
          CanActiveAuthGuard,
          NotSponsorGuard,
          ProfileCompleteGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'projects',
        loadChildren: 'app/projects/projects.module#ProjectsModule',
        canLoad: [
          CanActiveAuthGuard,
          EntrepreneurGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'network',
        loadChildren: 'app/network/network.module#NetworkModule',
        canLoad: [
          CanActiveAuthGuard,
          NotSponsorGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'requests',
        loadChildren: 'app/requests/requests.module#RequestsModule',
        canLoad: [
          CanActiveAuthGuard,
          NotSponsorGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'wishes',
        loadChildren: 'app/partner/partner.module#PartnerModule',
        canLoad: [
          CanActiveAuthGuard,
          PartnerGuard,
          NotNewAccountGuard
        ]
      },
      {
        path: 'partners',
        loadChildren: 'app/partnerships/partnerships.module#PartnershipsModule',
      },
/*      {
        path: 'pub/list',
        loadChildren: 'app/list/list.module#ListModule',
      },*/
      {
        path: 'pub',
        loadChildren: 'app/pub/pub.module#PubModule',
      },
      {
        path: 'connector',
        loadChildren: 'app/connector/connector.module#ConnectorModule',
        canLoad: [
          CanActiveAuthGuard,
          ConnectorGuard
        ]
      },
      {
        path: 'sponsor',
        loadChildren: 'app/sponsor/sponsor.module#SponsorModule',
        canLoad: [
          CanActiveAuthGuard,
          SponsorGuard
        ]
      },
      {
        path: 'settings',
        loadChildren: 'app/account-settings/account-settings.module#AccountSettingsModule',
        canLoad: [CanActiveAuthGuard]
      }
    ],
  },
  {
    path: 'payment/pay-done/:method',
    component: PaymentComponent,
    canLoad: [
      CanActiveAuthGuard,
      UserGuard,
      NotNewAccountGuard
    ]
  },
  {
    path: 'payment/pay-done/:method/:any',
    component: PaymentComponent,
    canLoad: [
      CanActiveAuthGuard,
      UserGuard,
      NotNewAccountGuard
    ]
  },
  {
    path: 'entrepreneur',
    component: UserLandingComponent,
    data: {
      userTypeText: 'entrepreneur'
    }
  },
  {
    path: 'cofounder',
    component: UserLandingComponent,
    data: {
      userTypeText: 'partner'
    }
  },
  {
    path: '**',
    component: NotFoundComponent,
    resolve: { NfResolver }
  }
];

export const AppRouting: ModuleWithProviders = RouterModule.forRoot(routes);

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ApiHttpRequestOptions {
  isOld?: boolean;
  otherUrlPart?: string;
  body?: any;
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export abstract class BaseApiService {
  private readonly modelUrlPart: string;

  private baseApiUrlPart = 'api';

  protected constructor(protected http: HttpClient, modelUrlPart = '') {
    this.modelUrlPart = modelUrlPart.replace(/((^\/)|(\/$))/g, '');
  }

  getApiUrl(url: string, options?: { isOld?: boolean, otherUrlPart?: string }) {
    options = options || {};
    const urlParts = [];

    if (!options.isOld) {
      urlParts.push(this.baseApiUrlPart);
    }

    urlParts.push(options.otherUrlPart ? options.otherUrlPart.replace(/((^\/)|(\/$))/g, '') : this.modelUrlPart);

    if (url) {
      urlParts.push(url.replace(/^\//g, ''));
    }

    return '/' + urlParts.join('/');
  }

  get<R>(uri: string, options?: ApiHttpRequestOptions): Observable<R> {
    return this.request<R>('GET', uri, options);
  }

  post<R>(uri: string, body: any | null, options?: ApiHttpRequestOptions): Observable<R> {
    options = options || {};
    return this.request<R>('POST', uri, Object.assign(options, {body: body}));
  }

  put<R>(uri: string, body: any | null, options?: ApiHttpRequestOptions): Observable<R> {
    options = options || {};
    return this.request<R>('PUT', uri, Object.assign(options, {body: body}));
  }

  del<R>(uri: string, options?: ApiHttpRequestOptions): Observable<R> {
    return this.request<R>('DELETE', uri, options);
  }

  request<R>(method: string, uri: string, options?: ApiHttpRequestOptions): Observable<R> {
    options = options || {};
    const url = this.getApiUrl(uri, options);
    return this.http.request<R>(method, url, options);
  }
}

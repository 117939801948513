import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProfileStore } from '../../profile/profile.store';
import { CookieService } from 'ngx-cookie';


@Component({
  selector: 'iv-change-lang-dropdown',
  templateUrl: './change-lang-dropdown.component.html',
  styleUrls: ['./change-lang-dropdown.component.scss']
})
export class ChangeLangDropdownComponent implements OnInit, OnDestroy {

  private readonly ICON_PATH = '/images/landing';

  public lang: string = 'en';

  private subscription: Subscription;

  public get icon(): string {
    return `${this.ICON_PATH}/${this.lang}.svg`;
  }

  constructor(
    private profileStore: ProfileStore,
    private cookieService: CookieService,) {
  }

  public ngOnInit(): void {
    this.subscription = this.profileStore.authenticatedProfile$.subscribe(profile => {
      this.lang = profile.lang;
    });

  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setLang(lang: string): void {
    this.cookieService.put('lang', lang);
    if (window.location.href.includes('home')) {
      window.location.href = `/${lang}`;
    } else {
      window.location.href = window.location.href.replace(/\/\w{2}\//, `/${lang}/`);
    }
  }

}

import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[ivInnerHtml]'
})
export class InnerHtmlDirective implements OnChanges {
  @Input() ivInnerHtml: string;

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): any {
    if ('ivInnerHtml' in changes) {
      this.elementRef.nativeElement.innerHTML = this.ivInnerHtml;
    }
  }
}

import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FlashService } from '@shared/services/flash.service';
import { FormatValidators } from '@shared/validators/format-validators';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AbstractFormComponent } from '../abstract-form.component';

@Component({
  selector: 'iv-feedback-form',
  templateUrl: './feedback-form.component.html',
  styles: [':host {display: block}']
})
export class FeedbackFormComponent extends AbstractFormComponent<any> implements OnInit {

  private readonly MAX_FIELD_LENGTH: number = 255;

  @Input()
  public title: string = '';

  @Input()
  public isLogin: boolean = false;

  @Input()
  public reCaptchaKey: string;

  @ViewChild('captchaRef')
  private captchaRef: RecaptchaComponent;

  constructor(protected cd: ChangeDetectorRef,
              protected fb: FormBuilder,
              protected flash: FlashService) {
    super(flash, cd, fb);
  }

  public ngOnInit(): void {
    if (!this.isLogin) {
      this.form.addControl('firstName', this.fb.control(
        '', [
          Validators.required,
          Validators.maxLength(this.MAX_FIELD_LENGTH),
          FormatValidators.nameFormat
        ]));
      this.form.addControl('lastName', this.fb.control('', [
        Validators.required,
        Validators.maxLength(this.MAX_FIELD_LENGTH),
        FormatValidators.nameFormat
      ]));
      this.form.addControl('email', this.fb.control('', [
        Validators.required,
        Validators.maxLength(this.MAX_FIELD_LENGTH),
        Validators.email
      ]));
      this.form.addControl('reCaptchaResponse', this.fb.control('', [Validators.required]));
    }
  }

  public onValidNumber(phone: string): void {
    this.form.get('phone')!.setErrors(null);
    this.form.get('phone')!.setValue(phone);
  }

  public onInvalidNumber(): void {
    this.form.get('phone')!.setErrors({invalid: true});
  }

  protected createForm(): FormGroup {
    return this.fb.group({
      phone: this.fb.control(''),
      subject: this.fb.control(''),
      message: this.fb.control('')
    });
  }

  protected getFormData(): any {
    return this.form.getRawValue();
  }

  protected onFinalize(): void {
    this.form.reset();
    this.captchaRef && this.captchaRef.reset();
    this.loading = false;
    this.cd.markForCheck();
  }
}

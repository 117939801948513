import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { CommentFormComponent } from './comment-form/comment-form.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { FeedbackFormComponent } from './feedback-form/feedback-form.component';
import { PremiumBuyComponent } from './premium-buy/premium-buy.component';
import { PremiumPaymentInfoComponent } from './premium-buy/premium-payment-info/premium-payment-info.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,

    CommentFormComponent,
    CustomerFormComponent,
    FeedbackFormComponent,
    PremiumBuyComponent
  ],
  declarations: [
    CommentFormComponent,
    CustomerFormComponent,
    FeedbackFormComponent,

    PremiumPaymentInfoComponent,
    PremiumBuyComponent
  ]
})
export class CommonFormsModule {
}

import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ICheckboxListItem } from '@shared/components/checkboxes-list/checkbox-list-item.interface';

@Component({
  selector: 'iv-checkboxes-list',
  templateUrl: './checkboxes-list.component.html',
  styles: [':host {display: block}', 'label {display: block; font-weight: normal; cursor: pointer;}'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxesListComponent),
      multi: true
    }
  ]
})
export class CheckboxesListComponent implements ControlValueAccessor {

  @Input()
  public checkboxes: ICheckboxListItem[];

  public list: any[] = [];

  public disabled: boolean = false;

  public onChange = (_: any[]) => {
  };

  public onTouched = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any[]): void {
    this.list = obj || [];
  }

  public isActive(value: any): boolean {
    return !!~this.list.indexOf(value);
  }

  public onCheckboxChange(checked: boolean, value: any): void {
    checked ? this.list.push(value) : this.list.splice(this.list.indexOf(value), 1);
    this.onChange(this.list);
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { ILanguage } from '@shared/models/language.interface';
import { Observable, of } from 'rxjs';
import { concatMap, map, shareReplay } from 'rxjs/operators';
import { IOption } from '../models/option.interface';
import { ApiHttpRequestOptions, BaseApiService } from './base-api-service';

export interface IVideoResponse {
  video: string;
}

export interface ILangListResponse {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class OptionService extends BaseApiService {

  constructor(protected http: HttpClient, private localStorage: LocalStorage) {
    super(http, '/options');
  }

  public getFunctions(): Observable<IOption[]> {
    return this.getOption<IOption[]>('functions');
  }

  public getOfferType(): Observable<IOption[]> {
    return this.getOption<IOption[]>('offer_type');
  }

  public getForecast(): Observable<{ [param: string]: IOption[] }> {
    return this.getOption<{ [param: string]: IOption[] }>('forecast');
  }

  public getSponsorCategories(): Observable<IOption[]> {
    return this.getOption<IOption[]>('partnership_sponsor_category');
  }

  public getIndustrySector(): Observable<IOption[]> {
    return this.getOption<IOption[]>('industry_sector');
  }

  public getSpecificActivity(): Observable<IOption[]> {
    return this.getOption<IOption[]>('specific_activity');
  }

  public getMarketType(): Observable<IOption[]> {
    return this.getOption<IOption[]>('market_type');
  }

  public getMarketLocation(): Observable<IOption[]> {
    return this.getOption<IOption[]>('market_location');
  }

  public getProgressStatus(): Observable<IOption[]> {
    return this.getOption<IOption[]>('progress_status');
  }

  public getParticipation(): Observable<IOption[]> {
    return this.getOption<IOption[]>('participation');
  }

  public getInvestment(): Observable<IOption[]> {
    return this.getOption<IOption[]>('investment');
  }

  public getWantPartnerFor(): Observable<IOption[]> {
    return this.getOption<IOption[]>('want_partner_for');
  }

  public getContribution(): Observable<{ [param: number]: IOption[] }> {
    return this.getOption<{ [param: number]: IOption[] }>('contribution');
  }

  public videoWelcome(): Observable<string> {
    return this.get<IVideoResponse>('/video-welcome')
      .pipe(map(res => res.video));
  }

  public langList(): Observable<ILangListResponse[]> {
    return this.getOption<ILangListResponse[]>('lang-list', {otherUrlPart: '/'});
  }

  public getEducationDegrees(): Observable<IOption[]> {
    return this.getOption<IOption[]>('degree_education');
  }

  public getExperience(): Observable<IOption[]> {
    return this.getOption<IOption[]>('experience');
  }

  public getAbilities(): Observable<IOption[]> {
    return this.getOption<IOption[]>('ability');
  }

  public getLanguageLevels(): Observable<IOption[]> {
    return this.getOption<IOption[]>('language_level');
  }

  public getSkills(term?: string): Observable<IOption[]> {
    if (term) {
      return this.get<IOption[]>(`/skill/find`, {params: {name: term}});
    } else {
      return this.getOption<IOption[]>('skill');
    }
  }

  public getSkillsByIds(ids: number[]): Observable<IOption[]> {
    if (ids.length === 0) {
      return of([]);
    }

    return this.get<IOption[]>(`/skill/get-by-ids?ids=[${ids.join(',')}]`).pipe(shareReplay());
  }

  public createSkill(name: string): Observable<IOption> {
    return this.post<IOption>('/skill/create', {name, type: 'skill'});
  }

  public getStatus(): Observable<IOption[]> {
    return this.getOption<IOption[]>('status');
  }

  public getLanguages(): Observable<ILanguage[]> {
    return this.getOption<ILanguage[]>('languages', {otherUrlPart: '/'})
  }

  public getContactReviewSteps(): Observable<IOption[]> {
    return this.getOption<IOption[]>('contact_review_step');
  }

  public getConnectorCategories(): Observable<IOption[]> {
    return this.getOption<IOption[]>('partnership_connector_category');
  }

  public checkLangHash(hashKey: string): Observable<boolean> {
    const key = 'version';

    return this.localStorage.getItem(key).pipe(
      concatMap(version => {
        if (version && version === hashKey + GlobalConfig.OPTIONS_HASH) {
          return of(false);
        } else {
          return this.localStorage.clear().pipe(
            concatMap(() => this.localStorage.setItem(key, hashKey + GlobalConfig.OPTIONS_HASH))
          );
        }
      })
    );
  }

  private getOption<T>(optionsKey: string, requestOptions?: ApiHttpRequestOptions): Observable<T> {
    return this.localStorage.getItem(optionsKey).pipe(
      concatMap(data => data ?
        of(data) :
        this.get<T>(`/${optionsKey}`, requestOptions)
          .pipe(
            concatMap(data => this.localStorage.setItem(optionsKey, data).pipe(map(() => data))),
            shareReplay(),
          )
      ));
  }

}

import { Subscription } from 'rxjs';
import { Directive, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AbstractFocusableDirective } from '@shared/directives/focusable-form-control/abstract-focusable.directive';

@Directive({
  selector: 'ng-select[ivFocusable]'
})
export class NgSelectFocusableDirective extends AbstractFocusableDirective implements OnInit, OnDestroy {

  private subscription: Subscription = new Subscription();

  constructor(protected renderer: Renderer2,
              private ngSelect: NgSelectComponent) {
    super(renderer);
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.ngSelect.focusEvent
        .subscribe(() => this.addClass())
    );

    this.subscription.add(
      this.ngSelect.blurEvent
        .subscribe(() => this.removeClass())
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
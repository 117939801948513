import { Component, Input, OnInit } from '@angular/core';
import { IInfoPremiumResponse } from '@shared/api/profile-http.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CouponType } from '../../../premium/become-premium/become-premium-select-plan/promo-block/coupon-type.enum';
import { ICoupon } from '../../../premium/become-premium/become-premium-select-plan/promo-block/coupon.interface';


@Component({
  selector: 'iv-premium-payment-info',
  templateUrl: './premium-payment-info.component.html',
  styles: [':host {display: block}']
})
export class PremiumPaymentInfoComponent implements OnInit {

  public NUMBER_FORMAT: string = '1.2-2';

  @Input()
  public infoPremium: IInfoPremiumResponse;

  @Input()
  public price: number;

  @Input()
  public currencyName: string;

  @Input()
  public coupon: ICoupon;

  @Input()
  public variantMonth: number;

  @Input()
  public isPremiumProfile: boolean = false;

  @Input()
  public discount: number;

  @Input()
  public loading: boolean = false;

  public startDate: Date;

  public endDate: Date;

  public get isPremiumCoupon(): boolean {
    return !!this.coupon && this.coupon.type === CouponType.PREMIUM;
  }

  public get isDiscountCoupon(): boolean {
    return !!this.coupon && this.coupon.type === CouponType.DISCOUNT;
  }

  public get freeMonthText(): string {
    const month = '_premium.coupon_free_month';

    return this.coupon.options.months === 1 ? month : `${month}s`;
  }

  public ngOnInit(): void {
    this.setDates();

    if (this.isPremiumCoupon && this.coupon.options.months) {
      this.endDate = moment(this.endDate)
        .add(this.coupon.options.months, 'months')
        .startOf('day')
        .toDate();
    }
  }

  private setDates(): void {
    let startDate: Moment;

    if (this.isPremiumProfile && this.infoPremium.premium && this.infoPremium.last_period) {
      startDate = moment(this.infoPremium.last_period.end).startOf('day');
    } else {
      startDate = moment().startOf('day');
    }

    let endDate = moment(startDate).add(this.variantMonth, 'months').startOf('day');

    this.startDate = startDate.toDate();
    this.endDate = endDate.toDate();
  }

}

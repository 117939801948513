import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { OptionService } from '../../api/option.service';

@Component({
  selector: 'iv-progress-status',
  templateUrl: './progress-status.component.html',
  styles: []
})
export class ProgressStatusComponent implements OnInit {
  @Input() statusIds: number[];

  progresses: { id: number; name: string; }[];

  constructor(private optionSrv: OptionService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.load();
  }


  load() {
    this.optionSrv.getProgressStatus()
      .subscribe(options => {
        this.progresses = options.map(item => ({
          id: item.id,
          name: item.name.replace(/^[0-9]*\s*-?\s*(.*)$/, '$1')
        }));

        this.cd.markForCheck();
      });
  }

  existStatus(id: number): boolean {
    return this.statusIds.indexOf(id) !== -1;
  }
}

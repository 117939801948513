import { ChangeDetectorRef, EventEmitter, Optional, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { FormHelper } from '@shared/helpers/form.helper';
import { FlashService } from '@shared/services/flash.service';
import { ScrollableFormControlService } from '@shared/services/scrollable-form-control.service';

export abstract class AbstractFormComponent<T> {

  protected readonly REQUIRED_ERROR_MESSAGE: string = 'error_save_required';

  @Output()
  public formSubmit: EventEmitter<Observable<T>> = new EventEmitter<Observable<T>>();

  public form: FormGroup;

  public loading: boolean = false;

  private _data$: Subject<T> = new Subject();

  private data$: Observable<T> = this._data$.pipe(finalize(() => this.onFinalize()));

  protected constructor(protected flash: FlashService,
                        protected cd: ChangeDetectorRef,
                        protected fb: FormBuilder,
                        @Optional()
                        protected scrollService: ScrollableFormControlService | undefined = undefined) {
    this.form = this.createForm();
  }

  protected abstract createForm(): FormGroup;

  public isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);

    return !!control && control.invalid && control.dirty;
  }

  public onSubmit(): void {
    if (this.form.valid) {
      this.loading = true;
      this.formSubmit.emit(this.data$);
      this._data$.next(this.getFormData());
    } else {
      this.onInvalidSubmit();
    }
  }

  protected getFormData(): T {
    return this.form.value;
  }

  protected onFinalize(): void {
    this.loading = false;
    this.cd.markForCheck();
  }

  protected onInvalidSubmit(): void {
    FormHelper.MarkFormAsDirtyAndTouched(this.form);

    const invalidControl: FormControl | null = FormHelper.GetFirstInvalidControl(this.form);

    if (invalidControl) {
      this.flash.danger(this.getErrorMessage(invalidControl));
      this.handleInvalidControl(invalidControl);
    }
  }

  protected handleInvalidControl(control: FormControl): void {
    this.scrollToFirstErrorControl(control);
  }

  protected getErrorMessage(_controlName: FormControl): string {
    return this.REQUIRED_ERROR_MESSAGE;
  }

  protected scrollToFirstErrorControl(control: FormControl): void {
    if (!this.scrollService) {
      return;
    }

    this.scrollService.scrollToControl(control);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '@shared/api/base-api-service';
import { ProjectsService } from '@shared/api/projects.service';
import { IRemoveReason } from '@shared/modals/confirm-reason/remove-reason.interface';
import { IResponseOk } from '@shared/models/api-response/response-ok.interface';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { concatMap, map, shareReplay, tap } from 'rxjs/operators';
import { ProfileStore } from '../../profile/profile.store';
import { INeed } from './need.interface';
import { Need } from './need.model';

@Injectable({
  providedIn: 'root'
})
export class NeedsService extends BaseApiService {

  private _needs$: BehaviorSubject<Map<number, Need>> = new BehaviorSubject<Map<number, Need>>(new Map());

  constructor(protected http: HttpClient,
              private projectStore: ProjectsService,
              private profileStore: ProfileStore) {
    super(http, '/need');
  }

  public getNeed(needId: number): Observable<Need> {
    if (this._needs$.value.has(needId)) {
      return of(this._needs$.value.get(needId)!);
    }

    return this.get<INeed>(`/${needId}`)
      .pipe(
        shareReplay(),
        map(need => {
          this._needs$.value.set(needId, new Need(need));
          this._needs$.next(this._needs$.value);

          return this._needs$.value.get(needId)!;
        })
      );
  }

  public save(need: INeed): Observable<INeed> {
    return this.post<INeed>('', need)
      .pipe(
        concatMap(need => this._needs$.value.size === 0 ?
          this.profileStore.loadProfile().pipe(map(() => need)) :
          of(need)
        ),
        tap(need => this._needs$.value.set(need.id, new Need(need))),
        concatMap(need => this.projectStore.getProject(need.id_project, false, true).pipe(map(() => need))),
      );
  }

  public remove(needId: number, removeReason: IRemoveReason): Observable<IResponseOk> {
    let params: any = {reason: removeReason.reason.toString()};

    if (removeReason.reasonDescription) {
      params.reason_desc = removeReason.reasonDescription;
    }

    return this.del<IResponseOk>(`/${needId}`, {params})
      .pipe(tap(() => this._needs$.value.delete(needId)));
  }

  public isCanRemove(needId: number): Observable<IResponseOk> {
    return this.get<IResponseOk>(`/${needId}/can-remove`);
  }

}
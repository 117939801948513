import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Patterns } from '@shared/helpers/patterns';

export class FormatValidators {

  public static passwordFormat(control: AbstractControl): ValidationErrors | null {
    return FormatValidators.includes(Patterns.PASSWORD, 'passwordFormat')(control);
  }

  public static nameFormat(control: AbstractControl): ValidationErrors | null {
    return FormatValidators.includes(Patterns.USERNAME, 'nameFormat')(control);
  }

  public static projectTitleFormat(control: AbstractControl): ValidationErrors | null {
    return FormatValidators.includes(Patterns.PROJECT_TITLE, 'projectTitleFormat')(control);
  }

  public static includes(regExp: RegExp, errorName: string = 'includes'): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      regExp.lastIndex = 0;

      const value = control.value;
      const invalid = !!value && regExp.test(value);

      return invalid ? {[errorName]: {value}} : null;
    };
  }

}
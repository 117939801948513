import { Injectable } from '@angular/core';
import { FlashMessagesService } from 'ngx-flash-messages';

@Injectable({
  providedIn: 'root'
})
export class FlashService {
  private classes: string[] = ['alert-flash', 'text-center'];

  private timeout: number = 1000 * 5;

  private typeClasses = {
    success: 'alert-success',
    warning: 'alert-warning',
    danger: 'alert-danger',
    info: 'alert-info',
  };

  constructor(private flash: FlashMessagesService) {
  }

  danger(text: string, timeout: number = this.timeout) {
    this.show(text, 'danger', timeout);
  }

  warning(text: string, timeout: number = this.timeout) {
    this.show(text, 'warning', timeout);
  }

  success(text: string, timeout: number = this.timeout) {
    this.show(text, 'success', timeout);
  }

  info(text: string, timeout: number = this.timeout) {
    this.show(text, 'info', timeout);
  }

  getTypeClass(type = 'info') {
    return this.typeClasses[type] || this.typeClasses['info'];
  }

  show(text: string, type: string = 'info', timeout: number = this.timeout, classes: string[] = [...this.classes, this.getTypeClass(type)]) {
    this.flash.show(text, {classes, timeout});
  }

}
